import React, { useId } from "react";
import ToolbarComponentBuilder from "../common/ToolbarComponentBuilder";
import { HBox, Icon, Label, Button } from "@/components";
import { isObject } from "@bsgp/lib-core";
import { Popover } from "@ui5/webcomponents-react";
import { useCodeEditorContext } from "@/contexts/codeEditorContext";
const OptionCustomBuilder = (props) => {
  const { ceId } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    CustomOptions,
    selectedOptions,
    handleSelectOpt
  } = useCodeEditorContext();
  const openerId = useId();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Button, { id: openerId, onClick: () => setIsOpen(!isOpen) }, /* @__PURE__ */ React.createElement(HBox, { style: { gap: "0.375rem" } }, "\uC5D0\uB514\uD130 \uCEE4\uC2A4\uD140 \uC635\uC158", /* @__PURE__ */ React.createElement(Icon, { name: "sap-icon://navigation-down-arrow" }))), /* @__PURE__ */ React.createElement(
    Popover,
    {
      opener: openerId,
      open: isOpen,
      placementType: "Bottom",
      hideArrow: true,
      onAfterClose: () => setIsOpen(false)
    },
    CustomOptions.map((btn, index) => {
      const { name, label, options } = btn;
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          key: `${ceId}_btn_${name}`,
          style: {
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            gap: "0.625rem"
          }
        },
        /* @__PURE__ */ React.createElement(Label, { key: `${ceId}_label_${name}` }, label),
        /* @__PURE__ */ React.createElement(
          ToolbarComponentBuilder,
          {
            type: "Select",
            key: `${ceId}_btn_${name}`,
            value: selectedOptions[name],
            properties: {
              onChange: (event) => {
                const value = event.target.value;
                handleSelectOpt({ name, value });
              }
            },
            items: {
              list: options.map((opt) => {
                if (isObject(opt)) {
                  return { key: opt.value, text: opt.label };
                } else {
                  return { key: opt, text: opt };
                }
              })
            },
            refineEventHookName: "none"
          }
        )
      );
    })
  ));
};
export default OptionCustomBuilder;
