var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { useContext, useEffect, useCallback, useRef } from "react";
import { NodeEditorContext } from "@/contexts/nodeEditorContext";
import { propsRef } from "@/ref";
import {
  addEdge,
  applyNodeChanges,
  useEdgesState,
  reconnectEdge
} from "reactflow";
export const useInitializeNodeEditor = (props) => {
  const { initializeNodeEditor } = useContext(NodeEditorContext);
  const fn = propsRef.current.fn;
  useEffect(() => {
    if (!fn) return;
    initializeNodeEditor(props);
  }, [fn]);
};
export const useNodeEditorInfo = () => useContext(NodeEditorContext);
export const useNodes = ({
  onChange,
  onNodeDoubleClick: onNodeDblClick
}) => {
  const { nodeInfo, edgeInfo, rawInfo } = useNodeEditorInfo();
  const onNodesChange = useCallback(
    (changes) => {
      const realChanges = changes.filter(
        (change) => change.type !== "dimensions" && change.type !== "select"
      );
      if (realChanges.length === 0) return;
      const newNodes = applyNodeChanges(realChanges, nodeInfo);
      onChange({
        value: convertNodeEditorToJSON({
          nodeInfo: newNodes,
          edgeInfo,
          initialNode: rawInfo
        })
      });
    },
    [edgeInfo, nodeInfo, onChange, rawInfo]
  );
  const onNodeDoubleClick = useCallback(onNodeDblClick || (() => {
  }), [
    onNodeDblClick
  ]);
  return { nodes: nodeInfo, onNodesChange, onNodeDoubleClick };
};
export const useEdges = ({ onChange }) => {
  const { nodeInfo, edgeInfo, rawInfo } = useNodeEditorInfo();
  const [edges, setEdges, onEdgesChange] = useEdgesState(edgeInfo);
  const reconnectDone = useRef(true);
  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    []
  );
  useEffect(() => {
    onChange({
      value: convertNodeEditorToJSON({
        nodeInfo,
        edgeInfo: edges,
        initialNode: rawInfo
      })
    });
  }, [edges]);
  const onReconnectStart = useCallback(() => {
    reconnectDone.current = false;
  }, []);
  const onReconnect = useCallback((oldEdge, newConnection) => {
    reconnectDone.current = true;
    setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
  }, []);
  const onReconnectEnd = useCallback((some, edge) => {
    if (!reconnectDone.current) {
      setEdges((eds) => eds.filter((ed) => ed.id !== edge.id));
    }
    reconnectDone.current = true;
  }, []);
  return {
    edges,
    onConnect,
    onEdgesChange,
    onReconnect,
    onReconnectStart,
    onReconnectEnd
  };
};
const convertNodeEditorToJSON = (params) => {
  const { nodeInfo, edgeInfo, initialNode } = params;
  const newJson = __spreadValues({}, initialNode);
  nodeInfo.forEach((node) => {
    const key = node.id;
    const value = {
      id: node.data.id,
      name: node.data.name,
      position: [node.position.x, node.position.y]
    };
    const nextEdge = edgeInfo.find((edge) => edge.source === key);
    if (nextEdge) {
      value.next = nextEdge.target;
    }
    newJson[key] = value;
  });
  return newJson;
};
