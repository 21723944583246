import React from "react";
import { Breadcrumbs } from "@/components";
import path from "path";
const HistoryBuilder = (props) => {
  const { renderData, history, title } = props;
  const lastIndex = renderData.length - 1;
  const presentLink = history.location.pathname;
  const links = renderData.filter((each, idx) => {
    let href = each.url || each.href || "";
    href = path.resolve(presentLink, href);
    return !(lastIndex === idx && presentLink.toLowerCase() === href.toLowerCase());
  }).map((hLink, idx) => {
    let href = hLink.url || hLink.href || "";
    if (href) {
      href = path.resolve(presentLink, href);
      href += history.location.search;
    }
    return {
      text: hLink.text,
      href
    };
  }).concat([{ text: title, href: presentLink }]);
  return /* @__PURE__ */ React.createElement(
    Breadcrumbs,
    {
      items: links,
      onItemClick: (event) => {
        event.preventDefault();
        history.push(event.detail.item.href);
      }
    }
  );
};
export default HistoryBuilder;
