var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var functions_exports = {};
__export(functions_exports, {
  confirmWithDialog: () => confirmWithDialog,
  convertToTreeData: () => convertToTreeData,
  extractArgs: () => extractArgs,
  getChildIdMapping: () => getChildIdMapping,
  getDataFromSPath: () => getDataFromSPath,
  getParentDialog: () => getParentDialog,
  getParentItem: () => getParentItem,
  getParentTable: () => getParentTable,
  getValue: () => getValue,
  isBound: () => isBound,
  refineProps: () => refineProps,
  removeUndefinedKeys: () => removeUndefinedKeys,
  updateProperty: () => updateProperty
});
module.exports = __toCommonJS(functions_exports);
var import_lib_core = require("@bsgp/lib-core");
function getParentComponent(oComp, className) {
  const maxRetry = 5;
  let retryCount = 0;
  let oDialog = oComp;
  while (retryCount < maxRetry) {
    if (oDialog.getMetadata().getName() === className) {
      return oDialog;
    }
    retryCount += 1;
    try {
      oDialog = oDialog.getParent();
    } catch (ex) {
      return null;
    }
    if (oDialog === null) {
      return null;
    }
  }
}
const getParentDialog = (oComp) => {
  return getParentComponent(oComp, "sap.m.Dialog");
};
const getParentTable = (oComp) => {
  return getParentComponent(oComp, "sap.m.Table");
};
const getParentItem = (oComp) => {
  const oTable = getParentTable(oComp);
  if (oTable) {
    const itemClassName = oTable.mBindingInfos.items.template.getMetadata().getName();
    return getParentComponent(oComp, itemClassName);
  }
  return null;
};
const getDataFromSPath = (data, path) => {
  const convertedPath = path.split("/").filter((path2) => path2 !== "");
  let res = convertedPath.reduce(
    (pre, cur) => isNaN(parseInt(cur)) ? pre[`${cur}`] : pre[cur],
    data
  );
  if (res.$sub$) {
    res = structuredClone(res);
    delete res.$sub$;
  }
  return res;
};
const getEventValueInReact = (oEvent) => {
  if (oEvent.componentCtg) {
    return oEvent.value;
  }
  const target = oEvent.currentTarget || oEvent.target;
  if (!target) return oEvent.value;
  switch (target.tagName) {
    case "UI5-MENU":
      return oEvent.detail.text;
    case "UI5-CHECKBOX":
      return target.checked;
    case "UI5-DATERANGE-PICKER":
      const startDateValue = target.startDateValue;
      const endDateValue = target.endDateValue;
      const startDate = new Date(startDateValue);
      const endDate = new Date(endDateValue);
      endDate.setHours(23, 59, 59, 999);
      return [startDate, endDate];
    case "UI5-FILE-UPLOADER":
      const files = oEvent.detail.files;
      if (files.length === 1) {
        return files[0];
      } else {
        return files;
      }
    case "UI5-MULTI-COMBOBOX":
      return oEvent.detail.items.map((item) => item.text);
    default:
      return target.value;
  }
};
const getEventIndexInReact = (oEvent) => {
  var _a;
  if (!!oEvent.rowData) {
    return oEvent.rowData.index;
  }
  const target = oEvent.currentTarget || oEvent.target;
  if (!target) {
    return void 0;
  }
  if (target.tagName === "UI5-MENU") {
    return oEvent.detail.item.dataset.idx;
  }
  return (_a = target.dataset) == null ? void 0 : _a.idx;
};
const getRowInReact = (oEvent) => {
  var _a;
  if (!!oEvent.rowData) {
    return oEvent.rowData;
  }
  const target = oEvent.currentTarget || oEvent.target;
  if (!target) {
    return void 0;
  }
  return ((_a = target.dataset) == null ? void 0 : _a.row) ? JSON.parse(target.dataset.row) : void 0;
};
const getValueIfReact = (oEvent) => {
  var _a, _b;
  if ("selectedIndices" in oEvent) {
    return {
      index: oEvent.selectedIndices,
      row: oEvent.selectedRows,
      selected: oEvent.selectedRows.length > 0
    };
  }
  if (["oEvent", "columns", "tableData"].every((key) => key in oEvent)) {
    return oEvent;
  }
  return __spreadProps(__spreadValues({}, oEvent), {
    value: getEventValueInReact(oEvent),
    index: getEventIndexInReact(oEvent),
    row: getRowInReact(oEvent),
    hiddenData: (_a = oEvent.currentTarget || oEvent.target) == null ? void 0 : _a.hiddenData,
    selected: (_b = oEvent.currentTarget || oEvent.target) == null ? void 0 : _b.selected
  });
};
const getValue = (oEvent) => {
  if (oEvent.renderAsReact) {
    return getValueIfReact(oEvent);
  }
  let newEvent;
  try {
    newEvent = oEvent.oEvent || oEvent;
    newEvent.getSource();
  } catch (ex) {
    console.log("oEvent:", oEvent);
    throw ex;
  }
  const oComp = newEvent.getSource();
  const className = oComp.getMetadata().getName();
  const params = newEvent.getParameters();
  let hiddenData = void 0;
  const oHiddenData = (0, import_lib_core.tryit)(() => oComp.getCustomData(), []).find((data) => {
    return data.getKey() === "hiddenData";
  });
  if (oHiddenData) {
    hiddenData = oHiddenData.getValue();
  }
  let value = void 0;
  let selected = void 0;
  switch (className) {
    case "sap.m.MultiInput": {
      const eventId = newEvent.getId();
      const oldValue = oComp.getTokens().map((oToken) => oToken.getKey());
      value = [...oldValue];
      if (eventId === "submit") {
        if (!value.includes(params.value)) {
          value.push(params.value);
        }
      } else {
        value = value.filter(
          (key) => params.removedTokens.length === 0 || !params.removedTokens.map((token) => token.getKey()).includes(key)
        );
        value.push(...params.addedTokens.map((token) => token.getKey()));
      }
      break;
    }
    case "sap.m.MultiComboBox": {
      const oItems = oComp.getSelectedItems();
      value = oItems.map((oItem) => oItem.getKey());
      selected = params.selected;
      break;
    }
    case "sap.ui.unified.FileUploader":
      const multipleAllowed = oComp.getMultiple();
      if (multipleAllowed) {
        value = params.files;
      } else {
        value = params.files[0];
      }
      break;
    case "sap.m.ComboBox": {
      const oItem = oComp.getSelectedItem();
      if (oItem) {
        value = oItem.getKey();
      } else {
        value = params.value;
      }
      selected = params.selected;
      break;
    }
    case "sap.m.Image":
      value = oComp.getSrc();
      break;
    case "sap.m.Link":
      value = oComp.getText();
      break;
    case "sap.m.DateRangeSelection": {
      value = [params.from, params.to];
      break;
    }
    case "sap.m.SegmentedButton":
      value = params.item.getKey();
      break;
    case "sap.m.Select":
      value = params.selectedItem.getKey();
      break;
    case "sap.m.CheckBox":
      value = params.selected;
      break;
    case "sap.ui.core.dnd.DragDropInfo": {
      const { draggedControl, droppedControl, dropPosition } = params;
      const oData = draggedControl.getBindingContext().oModel.oData;
      const fromData = getDataFromSPath(
        oData,
        draggedControl.getBindingContext().sPath
      );
      const toData = getDataFromSPath(
        oData,
        droppedControl.getBindingContext().sPath
      );
      value = { fromData, toData, position: dropPosition };
      break;
    }
    case "sap.m.RadioButtonGroup": {
      const selectedButton = oComp.getSelectedButton();
      const oCustomData = selectedButton.getCustomData().find((data) => {
        return data.getKey() === "key";
      });
      if (oCustomData) {
        value = oCustomData.getValue();
      } else {
        value = void 0;
      }
      break;
    }
    case "sap.m.Switch": {
      value = params.state;
      break;
    }
    default:
      value = params.value;
      break;
  }
  const oTable = getParentTable(oComp);
  const oTreeTable = oComp.getBindingContext();
  let index, row;
  if (oTable) {
    const oItem = getParentItem(oComp);
    index = oTable.getItems().filter((oItem2) => oItem2.getCells).findIndex((item) => oItem === item);
    if (index < 0) {
      index = void 0;
    } else {
      row = oItem.getBindingContext().getObject();
    }
  } else if (oTreeTable) {
    const { oModel, sPath } = oTreeTable;
    row = getDataFromSPath(oModel.oData, sPath);
  }
  return { value, index, row, hiddenData, selected };
};
function updateProperty(draft, name, value, index) {
  const properties = name.split(".");
  let acc = draft;
  properties.forEach((prop, idx) => {
    let newProp = prop;
    if (/\[\]$/.test(prop)) {
      newProp = prop.replace(/\[\]$/, "");
      if (acc[newProp].length === index) {
        const newObj = {};
        acc[newProp].push(newObj);
        acc = newObj;
      } else {
        acc = acc[newProp][index];
      }
    } else {
      if (idx === properties.length - 1) {
        acc[newProp] = value;
      } else {
        acc = acc[newProp];
      }
    }
  });
  return draft;
}
function confirmWithDialog(msg, onOk) {
  window.sap.ui.require(["sap/m/MessageBox"], function(MessageBox) {
    MessageBox.confirm(msg, {
      actions: [MessageBox.Action.OK, MessageBox.Action.CANCEL],
      emphasizedAction: MessageBox.Action.OK,
      onClose: function(sAction) {
        if (onOk) {
          if (sAction === MessageBox.Action.OK) {
            onOk();
          }
        }
      }
    });
  });
}
function getChildIdMapping(listData, childName) {
  return listData.reduce((acc, el, idx) => {
    if (acc[el[childName]] === void 0) {
      acc[el[childName]] = [];
    }
    acc[el[childName]].push(idx);
    return acc;
  }, {});
}
function list_to_tree(listData, options = {}) {
  const { parentName = "parentId", childName = "id" } = options;
  const roots = [];
  const childIdMapping = getChildIdMapping(listData, childName);
  listData.forEach((el) => {
    const parentValue = (0, import_lib_core.isArray)(el[parentName]) ? el[parentName].find((paId) => childIdMapping[paId] !== void 0) : el[parentName];
    if (parentValue === void 0 || parentValue === "") {
      roots.push(el);
      return;
    }
    if (childIdMapping[parentValue] === void 0) {
      roots.push(el);
      return;
    }
    childIdMapping[parentValue].forEach((idx) => {
      const parentEl = listData[idx];
      el._parent = parentValue;
      parentEl.$sub$ = [...parentEl.$sub$ || [], el];
    });
  });
  return { $sub$: roots };
}
function convertToTreeData(arrayData = [], options = {}) {
  const { parentName, childName } = options;
  const result = list_to_tree((0, import_lib_core.clone)(arrayData), {
    parentName,
    childName
  });
  return result;
}
const extractArgs = (args) => {
  const key = (0, import_lib_core.isString)(args[0]) ? args[0] : (0, import_lib_core.isFunction)(args[0]) ? args[0]() : void 0;
  const options = (0, import_lib_core.isArray)(args[0]) || (0, import_lib_core.isObject)(args[0]) ? args[0] : (0, import_lib_core.isArray)(args[1]) || (0, import_lib_core.isObject)(args[1]) ? args[1] : {};
  return [key, options];
};
function removeUndefinedKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== void 0) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
function refineProps(props, fn) {
  const properties = (0, import_lib_core.defined)(
    (0, import_lib_core.tryit)(() => props),
    {}
  );
  return removeUndefinedKeys(
    Object.keys(properties).reduce((acc, key) => {
      acc[key] = properties[key];
      if ((0, import_lib_core.isFunction)(properties[key])) {
        acc[key] = properties[key](fn);
      }
      return acc;
    }, {})
  );
}
function isBound(value) {
  return (0, import_lib_core.isString)(value) ? /^\{{1}(= ){0,1}.{1,}\}$/.test(value) : false;
}
