var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import {
  Form
} from "@ui5/webcomponents-react";
import { formRef } from "@/ref";
const defaultLayout = {
  columnsXL: 4,
  columnsL: 3,
  columnsM: 2
};
const FtForm = (props) => {
  const _a = props, {
    className,
    containers,
    style,
    namedForm,
    sizeV2,
    tightContainers,
    compactSize,
    staticContext
  } = _a, restProps = __objRest(_a, [
    "className",
    "containers",
    "style",
    "namedForm",
    "sizeV2",
    "tightContainers",
    "compactSize",
    "staticContext"
  ]);
  return /* @__PURE__ */ React.createElement(
    Form,
    __spreadValues(__spreadValues({
      ref: formRef,
      "data-cy": `form-${props.name}`,
      style: __spreadValues({
        padding: "0.5rem 0",
        overflowX: "auto"
      }, style),
      className,
      onSubmit: (event) => {
        event.preventDefault();
      }
    }, defaultLayout), restProps)
  );
};
export default FtForm;
