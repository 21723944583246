var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Button, ButtonDesign } from "@ui5/webcomponents-react";
import BarcodeScannerButton from "./BarcodeScannerButton";
import { ButtonTypeToDesignMap } from "@/maps/legacyMap";
const FtButton = function FtButton2(props) {
  const _a = props, { properties = {}, style, isScannerButton = false } = _a, rest = __objRest(_a, ["properties", "style", "isScannerButton"]);
  const _b = properties, { text, type } = _b, restProperties = __objRest(_b, ["text", "type"]);
  const onClick = properties.onClick || props.onClick;
  const buttonText = text === void 0 ? props.value || props.children : text;
  return isScannerButton ? /* @__PURE__ */ React.createElement(BarcodeScannerButton, __spreadValues({}, props)) : /* @__PURE__ */ React.createElement(
    Button,
    __spreadProps(__spreadValues(__spreadProps(__spreadValues({}, rest), {
      design: ButtonTypeToDesignMap[type] || ButtonDesign.Default
    }), restProperties), {
      onClick,
      style
    }),
    buttonText
  );
};
export default FtButton;
