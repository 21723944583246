var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import {
  RadioButton
} from "@ui5/webcomponents-react";
const FtRadioButton = (props) => {
  const _a = props, { properties, list, style, id, name } = _a, rest = __objRest(_a, ["properties", "list", "style", "id", "name"]);
  const radioName = id || name;
  if (!list || !Array.isArray(list) || !radioName) return null;
  return /* @__PURE__ */ React.createElement("div", { style: { display: "flex", flexWrap: "wrap" } }, list.map(({ key, text }, idx) => {
    const onChange = properties.onChange || properties.onSelect;
    return /* @__PURE__ */ React.createElement(
      RadioButton,
      __spreadProps(__spreadValues(__spreadValues({}, rest), properties), {
        onChange,
        key: `${key}_${idx}`,
        name: radioName,
        text,
        value: key
      })
    );
  }));
};
export default FtRadioButton;
