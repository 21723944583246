var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { Select, Option } from "@ui5/webcomponents-react";
function FtSelect(props) {
  const {
    properties,
    rowData = {},
    items: { list },
    value
  } = props;
  const onChange = properties.change || properties.onChange;
  return /* @__PURE__ */ React.createElement(
    Select,
    __spreadProps(__spreadValues({}, props), {
      onChange: function(event) {
        return onChange && onChange(event, { rowData });
      }
    }),
    list.map((opt, index) => {
      return /* @__PURE__ */ React.createElement(
        Option,
        {
          key: `${opt.key}_${index}`,
          value: opt.key,
          selected: opt.key === value
        },
        opt.text
      );
    })
  );
}
export default FtSelect;
