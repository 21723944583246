var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useState } from "react";
import { Button } from "@ui5/webcomponents-react";
import { BarcodeScannerDialog } from "@ui5/webcomponents-react";
import { propsRef } from "@/ref";
function BarcodeScannerButton(props) {
  const _a = props, {
    properties: _b
  } = _a, _c = _b, { text } = _c, restProperties = __objRest(_c, ["text"]), {
    style,
    onError,
    onScan
  } = _a;
  const [isOpen, setIsOpen] = useState(false);
  const { fn } = propsRef.current;
  const buttonText = text === void 0 ? props.value || props.children : text;
  const openDialog = () => {
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    __spreadProps(__spreadValues({
      design: "Default"
    }, restProperties), {
      onClick: openDialog,
      style: __spreadValues({
        width: "100%"
      }, style)
    }),
    buttonText
  ), /* @__PURE__ */ React.createElement(
    BarcodeScannerDialog,
    {
      open: isOpen,
      onScanSuccess: (event) => {
        const barcode = event.detail.text;
        onScan(fn)(barcode);
        closeDialog();
      },
      onScanError: (event) => {
        const errorMsg = event.detail.message;
        onError(fn)(errorMsg);
        closeDialog();
      }
    }
  ));
}
export default BarcodeScannerButton;
