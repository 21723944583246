var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import TableBuilder from "./TableBuilder";
import BaseIterator from "../common/BaseIterator";
import { TabLayout } from "@ui5/webcomponents-react";
const TableIterator = ({
  doWrapTables,
  renderData
}) => {
  const hasIcon = getHasIcon(renderData);
  return /* @__PURE__ */ React.createElement(
    BaseIterator,
    {
      items: renderData,
      itemNameList: renderData.map((tableObject) => tableObject.name),
      wrapItems: doWrapTables,
      tabContainerProps: {
        className: "ft-selected-icon-tab noBottomLine",
        tabLayout: hasIcon ? TabLayout.Standard : TabLayout.Inline
      },
      tabProps: (tabObject) => ({
        additionalText: tabObject.count === void 0 ? void 0 : tabObject.count.toString(),
        icon: tabObject.icon
      })
    },
    (_a, key) => {
      var _b = _a, { title, icon } = _b, rest = __objRest(_b, ["title", "icon"]);
      return /* @__PURE__ */ React.createElement(TableBuilder, __spreadValues({ key }, rest));
    }
  );
};
function getHasIcon(tableDataList) {
  return tableDataList.reduce((acc, tableObject) => {
    if (acc) {
      return acc;
    }
    if (tableObject.icon) {
      return true;
    }
    return false;
  }, false);
}
export default TableIterator;
