var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var slice_exports = {};
__export(slice_exports, {
  default: () => slice_default,
  viewerActions: () => viewerActions
});
module.exports = __toCommonJS(slice_exports);
var import_toolkit = require("@reduxjs/toolkit");
var import_v2 = require("@bsgp/lib-date/v2");
const initialState = {
  list: []
};
const viewerSlice = (0, import_toolkit.createSlice)({
  name: "viewerSlice",
  initialState,
  reducers: {
    _updatedList: (state, action) => {
      const { editedList } = action.payload;
      state.list = editedList.map((item) => {
        return __spreadProps(__spreadValues({}, item), {
          updatedAt: (0, import_v2.DateTime)(item.updatedAt).formatted
        });
      });
    },
    _onSelect: (state, action) => {
      console.log("slice onSelect is working");
      return state;
    }
  }
});
const viewerActions = viewerSlice.actions;
var slice_default = viewerSlice;
