import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND
} from "lexical";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { Icon } from "@/components";
import FontSizeController from "../FontSizeController";
import { $patchStyleText } from "@lexical/selection";
const LowPriority = 1;
function Divider() {
  return /* @__PURE__ */ React.createElement("div", { className: "divider" });
}
function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [fontSize, setFontSize] = useState("16px");
  const classes = useStyles();
  const applyStyle = useCallback(
    (option, style) => {
      editor.update(() => {
        const selection = $getSelection();
        if (selection !== null) {
          $patchStyleText(selection, {
            [style]: option
          });
        }
      });
    },
    [editor]
  );
  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
    }
  }, []);
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, $updateToolbar]);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classes.toolbar,
      ref: toolbarRef,
      "data-cy": "rich-text-editor-toolbar"
    },
    /* @__PURE__ */ React.createElement(
      "button",
      {
        disabled: !canUndo,
        onClick: () => {
          editor.dispatchCommand(UNDO_COMMAND, void 0);
        },
        className: clsx("toolbar-item", "spaced"),
        "aria-label": "Undo",
        "data-cy": "rte-toolbar-undo"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "undo", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        disabled: !canRedo,
        onClick: () => {
          editor.dispatchCommand(REDO_COMMAND, void 0);
        },
        className: clsx("toolbar-item"),
        "aria-label": "Redo",
        "data-cy": "rte-toolbar-redo"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "redo", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(Divider, null),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
        },
        className: clsx("toolbar-item", "spaced", isBold ? "active" : ""),
        "aria-label": "Format Bold",
        "data-cy": "rte-toolbar-bold"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "bold-text", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
        },
        className: clsx("toolbar-item", "spaced", isItalic ? "active" : ""),
        "aria-label": "Format Italics",
        "data-cy": "rte-toolbar-italic"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "italic-text", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
        },
        className: clsx("toolbar-item", "spaced", isUnderline ? "active" : ""),
        "aria-label": "Format Underline",
        "data-cy": "rte-toolbar-underline"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "underline-text", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        },
        className: clsx(
          "toolbar-item",
          "spaced",
          isStrikethrough ? "active" : ""
        ),
        "aria-label": "Format Strikethrough",
        "data-cy": "rte-toolbar-strike"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "strikethrough", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(Divider, null),
    /* @__PURE__ */ React.createElement(
      FontSizeController,
      {
        fontSize,
        setFontSize,
        className: clsx("toolbar-item", "spaced"),
        applyStyle
      }
    ),
    /* @__PURE__ */ React.createElement(Divider, null),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        },
        className: clsx("toolbar-item", "spaced"),
        "aria-label": "Left Align",
        "data-cy": "rte-toolbar-left"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "text-align-left", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        },
        className: clsx("toolbar-item", "spaced"),
        "aria-label": "Center Align",
        "data-cy": "rte-toolbar-center"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "text-align-center", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        },
        className: clsx("toolbar-item", "spaced"),
        "aria-label": "Right Align",
        "data-cy": "rte-toolbar-right"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "text-align-right", className: clsx("format") })
    ),
    /* @__PURE__ */ React.createElement(
      "button",
      {
        onClick: () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        },
        className: clsx("toolbar-item"),
        "aria-label": "Justify Align",
        "data-cy": "rte-toolbar-justify"
      },
      /* @__PURE__ */ React.createElement(Icon, { name: "text-align-justified", className: clsx("format") })
    )
  );
}
const useStyles = createUseStyles({
  ltr: {
    textAlign: "left"
  },
  rtl: {
    textAlign: "right"
  },
  toolbar: {
    display: "flex",
    marginBottom: "1px",
    background: "#fff",
    padding: "4px",
    verticalAlign: "middle",
    widht: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    "& .toolbar-item": {
      border: "0",
      display: "flex",
      background: "none",
      borderRadius: "10px",
      padding: "8px",
      cursor: "pointer",
      height: "100%",
      verticalAlign: "middle",
      "&:disabled": {
        cursor: "not-allowed",
        "& .format": {
          opacity: "0.2"
        }
      },
      "&.spaced": {
        marginRight: "2px"
      },
      "& .format": {
        backgroundSize: "contain",
        display: "flex",
        height: "18px",
        width: "18px",
        marginTop: "2px",
        verticalAlign: "-0.25em",
        opacity: "0.6"
      },
      "&.active": {
        backgroundColor: "rgba(223, 232, 250, 0.3)",
        "& ui5-icon": {
          opacity: "1"
        }
      },
      "&:hover:not([disabled])": {
        backgroundColor: "#eee"
      },
      "& .text": {
        display: "flex",
        lineHeight: "20px",
        width: "70px",
        verticalAlign: "middle",
        fontSize: "14px",
        color: "#777",
        textOverflow: "ellipsis",
        overflow: "hidden",
        height: "20px",
        textAlign: "left"
      },
      "& .icon": {
        display: "flex",
        width: "20px",
        height: "20px",
        userSelect: "none",
        marginRight: "8px",
        lineHeight: "16px",
        backgroundSize: "contain"
      }
    },
    "& .divider": {
      width: "1px",
      backgroundColor: "#eee",
      margin: "0 4px"
    }
  }
});
export default ToolbarPlugin;
