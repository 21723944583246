var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  constructTableData,
  constructFormData,
  constructFooterData,
  constructNodeEditorData
} from "@bsgp/form-and-table";
import { isArray } from "@bsgp/lib-core";
import { propsRef } from "@/ref";
import { refineEvent } from "@/lib/refineEvent";
import { tryit } from "@bsgp/lib-core";
export const constructData = (data) => {
  if (!propsRef.current.fn) {
    return {};
  }
  const tableData = data.table ? constructTableData(data.table, propsRef.current.fn, "", {
    isReact: true,
    refineFunc: refineEvent
  }) : null;
  return __spreadProps(__spreadValues({}, data), {
    doWrapTables: tableData && tableData.length > 1 || tryit(() => data.wrapSingleTable),
    form: data.form ? constructFormData(data.form, propsRef.current.fn) : null,
    table: tableData,
    footer: data.footer ? constructFooterData(data.footer, {
      isReact: true
    }) : null,
    dialogV2: data.dialogV2 ? convertObjectDataToArray(data.dialogV2) : null,
    codeeditor: data.codeeditor ? convertObjectDataToArray(
      data.codeeditor
    ) : null,
    nodeeditor: data.nodeeditor ? constructNodeEditorData(data.nodeeditor) : null,
    historyLinks: data.historyLinks ? constructHistoryLink(data.historyLinks) : null,
    section: data.section ? convertObjectDataToArray(data.section) : null
  });
};
export const constructHistoryLink = (data) => {
  return isArray(data) ? data : [];
};
export const convertObjectDataToArray = (data) => {
  return data.hasOwnProperty("value") || data.hasOwnProperty("properties") ? [data] : Object.entries(data).map(([key, value]) => {
    return __spreadProps(__spreadValues({}, value), { name: key });
  });
};
export {
  constructTableData,
  constructFormData,
  constructFooterData,
  constructNodeEditorData
};
