var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import clsx from "clsx";
import NodeEditorBuilder from "./Builder";
import { NodeEditorContextProvider } from "@/contexts/nodeEditorContext";
import BaseIterator from "../common/BaseIterator";
function NodeEditorIterator(props) {
  const { renderData } = props;
  const nodeEditorNameList = renderData.map(
    (ne, index) => ne.name || `node-editor-${index}`
  );
  return /* @__PURE__ */ React.createElement(
    BaseIterator,
    {
      items: renderData,
      itemNameList: nodeEditorNameList,
      wrapItems: renderData.length > 1,
      contextProvider: NodeEditorContextProvider,
      tabContainerProps: {
        className: clsx("noBottomLine", "ft-height-100-for-codeeditor"),
        style: { height: "100%" }
      }
    },
    (item, key) => /* @__PURE__ */ React.createElement(NodeEditorBuilder, __spreadValues({ key }, item))
  );
}
export default NodeEditorIterator;
