var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var use_pagination_exports = {};
__export(use_pagination_exports, {
  applyFilterOnTable: () => applyFilterOnTable,
  compareSearchInput: () => compareSearchInput,
  default: () => use_pagination_default,
  getCustomObject: () => getCustomObject,
  getHeaderCompWithCustomKey: () => getHeaderCompWithCustomKey
});
module.exports = __toCommonJS(use_pagination_exports);
var import_lib_core = require("@bsgp/lib-core");
function getButtonSchemes(pageNum = 1, totalPage = 0, edge = 1) {
  const options = {
    count: totalPage,
    mapping: (idx) => idx + 1
  };
  const maxPageLength = edge * 2 + 1;
  if (totalPage > maxPageLength) {
    options.count = maxPageLength;
    const start = pageNum - edge;
    const end = pageNum + edge;
    if (start > 1 && end < totalPage) {
      options.mapping = (idx) => idx + start;
    } else if (end >= totalPage) {
      options.mapping = (idx) => idx + totalPage - maxPageLength + 1;
    }
  }
  const pageNumbers = new Array(options.count).fill().map((val, idx) => String(options.mapping(idx)));
  if (totalPage > maxPageLength) {
    pageNumbers.unshift("<<");
    pageNumbers.push(">>");
  }
  return { items: pageNumbers.map((text) => ({ text })) };
}
function getHeaderCompWithCustomKey(oTable, key) {
  const headerContents = oTable.getHeaderToolbar().getContent();
  return headerContents.find(
    (comp) => comp.getCustomData().find((item) => item.getKey() === key)
  );
}
function getCustomObject(oObj, key) {
  if (!oObj) {
    return null;
  }
  return oObj.getCustomData().find((item) => item.getKey() === key);
}
function getCustomObjectFromHeader(oTable, key) {
  const oSearch = getHeaderCompWithCustomKey(oTable, key);
  if (!oSearch) {
    return null;
  }
  return getCustomObject(oSearch, key);
}
function getCustomValueFromHeader(oTable, key = "search") {
  const oCustom = getCustomObjectFromHeader(oTable, key);
  if (!oCustom) {
    return "";
  }
  return oCustom.getValue();
}
function getTargetColumns(tableData) {
  const searchData = tableData.toolbar.content.find(
    ({ component }) => component.type === "SearchField"
  );
  if (searchData.targetColumns) {
    return searchData.targetColumns;
  }
  return tableData.columns.map(({ name }) => name);
}
function getPageNumberContainer(oTable) {
  return oTable.getParent().getItems().find((item) => item.getMetadata().getName() === "sap.m.Toolbar").getContent().find((item) => item.getMetadata().getName() !== "sap.m.ToolbarSpacer");
}
const compareSearchInput = (searchInput) => (value) => {
  if (value === "" || value === null || value === void 0) return false;
  return value.toString().toLowerCase().includes(searchInput.toLowerCase());
};
function getItemLengthAfterSearch(oTable, tableData) {
  const searchHandler = (0, import_lib_core.tryit)(
    () => tableData.toolbar.content.find(
      ({ component }) => component.type === "SearchField"
    )
  );
  if (!searchHandler || searchHandler.component.properties.search) {
    return tableData.items.list.length;
  }
  const targetColumns = getTargetColumns(tableData);
  const searchInput = getCustomValueFromHeader(oTable);
  return tableData.items.list.filter(
    (item) => targetColumns.map((name) => item[name]).filter(compareSearchInput(searchInput)).length
  ).length;
}
function getNewPage(pressedNumber, totalPage) {
  switch (pressedNumber) {
    case "<<":
      return 1;
    case ">>":
      return totalPage;
    default:
      return Number(pressedNumber) > totalPage ? 1 : Number(pressedNumber);
  }
}
function getParamsForPagination(oTable, tableData, newPage) {
  const { initial, itemsPerPage, edge } = tableData.usePagination;
  const filteredLength = getItemLengthAfterSearch(oTable, tableData);
  const totalPage = Math.ceil(filteredLength / itemsPerPage);
  const pageNum = getNewPage(newPage, totalPage);
  return { initial, edge, itemsPerPage, filteredLength, totalPage, pageNum };
}
function emphasizePageButton(buttonContainer, pageNum) {
  buttonContainer.getItems().forEach((button) => {
    if (Number(button.getText()) === pageNum) {
      button.setType(window.sap.m.ButtonType.Emphasized);
      return;
    }
    button.setType(window.sap.m.ButtonType.Transparent);
  });
}
function changePageIndexInfo(oTable, options = {}) {
  const { filteredLength = 0, itemsPerPage = 0, pageNum = 0 } = options;
  const oText = getHeaderCompWithCustomKey(oTable, "index");
  const total = filteredLength;
  const from = total === 0 ? 0 : itemsPerPage * pageNum - itemsPerPage + 1;
  const to = total < itemsPerPage * pageNum ? total : itemsPerPage * pageNum;
  oText.setText(`${total}\uAC1C \uC911 ${from}-${to}`);
}
function applyFilterOnTable(oTable, tableData, { itemsPerPage, pageNum }) {
  const start = itemsPerPage * (pageNum - 1);
  const end = itemsPerPage * pageNum;
  const oCustom = getCustomObject(oTable, "pageNum");
  if (oCustom) {
    oCustom.setValue(pageNum);
  }
  let count = -1;
  const oIndexFilter = new window.sap.ui.model.Filter({
    path: "",
    test: () => {
      count += 1;
      return count >= start && count < end;
    }
  });
  const searchHandler = (0, import_lib_core.tryit)(
    () => tableData.toolbar.content.find(
      ({ component }) => component.type === "SearchField"
    )
  );
  if (!searchHandler || searchHandler.component.properties.search) {
    oTable.getBinding("items").filter(oIndexFilter);
    return;
  }
  const searchInput = getCustomValueFromHeader(oTable);
  const targetColumns = getTargetColumns(tableData);
  const searchFilter = new window.sap.ui.model.Filter({
    filters: targetColumns.map(
      (name) => new window.sap.ui.model.Filter({
        path: name,
        test: compareSearchInput(searchInput)
      })
    ),
    and: false
  });
  oTable.getBinding("items").filter([searchFilter, oIndexFilter]);
}
function changePageNumbers(oComp, options = {}, setData) {
  const { totalPage, edge, pageNum } = options;
  const oContainer = ["sap.m.Table", "sap.m.List"].includes(
    oComp.getMetadata().getName()
  ) ? getPageNumberContainer(oComp) : oComp;
  const buttonSchemes = getButtonSchemes(pageNum, totalPage, edge);
  const oToolbar = oContainer.getParent();
  if (buttonSchemes.items.length === 0) {
    oToolbar && oToolbar.setVisible(false);
  } else {
    oToolbar && oToolbar.setVisible(true);
  }
  if (setData) {
    setData(buttonSchemes);
  } else {
    oContainer.getModel().setData(buttonSchemes);
  }
  emphasizePageButton(oContainer, pageNum);
}
function handlePagination(oTable, tableData, newPage = "1") {
  const params = __spreadValues({}, getParamsForPagination(oTable, tableData, newPage));
  changePageIndexInfo(oTable, params);
  const oToolbar = oTable.getHeaderToolbar();
  if (oToolbar.getVisible() === false) {
    oToolbar.setVisible(true);
  }
  return {
    onInitial(oPageNumberContainer, setData) {
      applyFilterOnTable(oTable, tableData, params);
      changePageNumbers(oPageNumberContainer, params, setData);
    },
    onPress() {
      applyFilterOnTable(oTable, tableData, params);
      changePageNumbers(oTable, params);
    },
    onSearch() {
      changePageNumbers(oTable, params);
    }
  };
}
var use_pagination_default = handlePagination;
