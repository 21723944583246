var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { ComboBox, ComboBoxItem } from "@ui5/webcomponents-react";
import { MultiComboBox } from "..";
const FtComboBox = (props) => {
  if (props.multiple) {
    return /* @__PURE__ */ React.createElement(MultiComboBox, __spreadValues({}, props));
  }
  const { items, properties, style, value } = props;
  return /* @__PURE__ */ React.createElement(ComboBox, __spreadProps(__spreadValues({}, properties), { style, value }), items.list.map((item) => /* @__PURE__ */ React.createElement(ComboBoxItem, { text: item.text, key: item.key })));
};
export default FtComboBox;
