var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { defined } from "@bsgp/lib-core";
import { FormGroup } from "@/components";
import FormItemBuilder from "./FormItemBuilder";
function FormGroupBuilder({
  formObject,
  formName,
  sizeV2
}) {
  return formObject.containers.map((ctn) => {
    const containerName = [formName, ctn.name].join("-");
    const properties = defined(ctn.properties, {});
    return /* @__PURE__ */ React.createElement(
      FormGroup,
      __spreadValues({
        name: containerName,
        key: containerName,
        title: ctn.title
      }, properties),
      ctn.elements.map((element) => {
        const elName = [containerName, element.name].join("-");
        return /* @__PURE__ */ React.createElement(
          FormItemBuilder,
          {
            key: elName,
            element,
            containerName,
            sizeV2
          }
        );
      }).filter(Boolean)
    );
  });
}
export default FormGroupBuilder;
