import React, { memo, useCallback } from "react";
import { useTableContext } from "@/contexts/tableContext";
import Input from "@/components/inputs/Input";
import { filterFn } from "@/lib/tableUtils";
const Filter = ({ column }) => {
  var _a;
  const { setFilteredData, tableData, setPage } = useTableContext();
  const handleChange = useCallback(
    (event) => {
      const accessor = column.id || column.value;
      const currentVal = event.target.value || void 0;
      column.setFilter(currentVal);
      const filteredData = filterFn(tableData, accessor, currentVal);
      if (filteredData.length === tableData.length) {
        setFilteredData([]);
      } else {
        setFilteredData(filteredData);
      }
      setPage(0);
    },
    [column, setFilteredData, tableData, setPage]
  );
  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      event.stopPropagation();
      if (event.stopImmediatePropagation) {
        event.stopImmediatePropagation();
      }
    }
  };
  return /* @__PURE__ */ React.createElement(
    Input,
    {
      value: (_a = column.filterValue) != null ? _a : "",
      onChange: handleChange,
      showClearIcon: true,
      onKeyDown: handleKeyDown
    }
  );
};
export default memo(Filter);
