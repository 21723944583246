import { Text } from "@/components";
import React from "react";
import { useTableContext } from "@/contexts/tableContext";
const DataCntInfo = () => {
  const { filteredData, itemsPerPage, page } = useTableContext();
  const total = filteredData.length;
  const displayingPage = page + 1;
  const from = total === 0 ? 0 : itemsPerPage * displayingPage - itemsPerPage + 1;
  const to = total < itemsPerPage * displayingPage ? total : itemsPerPage * displayingPage;
  const text = `${total}\uAC1C \uC911 ${from}-${to}`;
  return /* @__PURE__ */ React.createElement(Text, null, text);
};
export default DataCntInfo;
