var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import clsx from "clsx";
import CodeEditorBuilder from "./Builder";
import { CodeEditorContextProvider } from "@/contexts/codeEditorContext";
import BaseIterator from "../common/BaseIterator";
function CodeEditorIterator(props) {
  const { renderData } = props;
  const itemNameList = renderData.map(
    (data, index) => data.name || `code-editor-${index}`
  );
  return /* @__PURE__ */ React.createElement(
    BaseIterator,
    {
      items: renderData,
      itemNameList,
      wrapItems: itemNameList.length > 1,
      contextProvider: CodeEditorContextProvider,
      tabContainerProps: {
        className: clsx("noBottomLine", "ft-height-100-for-codeeditor"),
        style: { height: "100%" }
      }
    },
    (item, key) => /* @__PURE__ */ React.createElement(CodeEditorBuilder, __spreadProps(__spreadValues({}, item), { ceId: key, key }))
  );
}
export default CodeEditorIterator;
