var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { VBox, HBox, Grid, SplitterLayout } from "@/components";
import { MetaSorter } from "@/lib/metaSorter";
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import SectionItemBuilder, {
  SectionItem
} from "./SectionItemBuilder";
const useStyles = createUseStyles({
  container: {
    padding: ({ padding }) => padding === void 0 ? "1rem" : padding,
    gap: "1rem"
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold"
  }
});
const SectionBuilder = (props) => {
  const classes = useStyles(props);
  const _a = props, { title, components, name, sectionType } = _a, properties = __objRest(_a, ["title", "components", "name", "sectionType"]);
  const type = props.type === "Section" ? sectionType : props.type;
  const sorter = new MetaSorter(props);
  const orderedComponents = useMemo(
    () => sorter.getOrderedLayout(),
    [props]
  );
  return /* @__PURE__ */ React.createElement(VBox, { "data-cy": name, className: classes.container }, title && /* @__PURE__ */ React.createElement("div", { className: classes.title }, title), type === "VBox" ? /* @__PURE__ */ React.createElement(VBox, __spreadValues({}, properties), /* @__PURE__ */ React.createElement(
    SectionItemBuilder,
    {
      components: orderedComponents,
      sectionType: type
    }
  )) : type === "HBox" ? /* @__PURE__ */ React.createElement(HBox, __spreadValues({}, properties), /* @__PURE__ */ React.createElement(
    SectionItemBuilder,
    {
      components: orderedComponents,
      sectionType: type
    }
  )) : type === "Grid" ? /* @__PURE__ */ React.createElement(Grid, __spreadValues({}, properties), orderedComponents.map(({ data }) => {
    return data.map((comp, index) => {
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          "data-layout-span": comp.properties["data-layout-span"],
          "data-layout-indent": comp.properties["data-layout-indent"],
          key: `grid-${index}`,
          style: { width: "100%" }
        },
        /* @__PURE__ */ React.createElement(
          SectionItem,
          __spreadProps(__spreadValues({}, comp), {
            key: `grid-${comp.type}-${index}`,
            refineEventHookName: "useRefineEventForSection"
          })
        )
      );
    });
  })) : type === "Split" ? /* @__PURE__ */ React.createElement(SplitterLayout, __spreadValues({}, properties), /* @__PURE__ */ React.createElement(
    SectionItemBuilder,
    {
      components: orderedComponents,
      sectionType: type,
      resizable: properties.resizable
    }
  )) : /* @__PURE__ */ React.createElement(React.Fragment, null, "\uC9C0\uC815\uD558\uC9C0 \uC54A\uC740 \uD0C0\uC785\uC758 \uC139\uC158"));
};
export default SectionBuilder;
