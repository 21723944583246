var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import Editor from "@monaco-editor/react";
import { defined } from "@bsgp/lib-core";
const convertAceOptionToMonaco = (properties) => {
  const _a = properties, { editable, syntaxHints } = _a, restProperties = __objRest(_a, ["editable", "syntaxHints"]);
  return __spreadProps(__spreadValues({}, restProperties), {
    readOnly: editable === false,
    quickSuggestions: syntaxHints !== false,
    suggestOnTriggerCharacters: syntaxHints !== false
  });
};
const CodeEditor = (props) => {
  const { value, options = {}, properties = {} } = props;
  const _a = properties, { onChange = () => {
  } } = _a, restProperties = __objRest(_a, ["onChange"]);
  const convertedOptions = convertAceOptionToMonaco(restProperties);
  return /* @__PURE__ */ React.createElement(
    Editor,
    __spreadProps(__spreadValues({
      height: "100%",
      value,
      language: properties.type || "javascript",
      options: __spreadProps(__spreadValues(__spreadValues({
        wordWrap: convertedOptions.readOnly ? "on" : "off",
        scrollBeyondLastLine: false
      }, options), convertedOptions), {
        minimap: {
          enabled: defined(properties.minimapEnabled, options.minimapEnabled)
        }
      })
    }, restProperties), {
      onChange: (editorVal) => {
        onChange({
          value: editorVal
        });
      }
    })
  );
};
export default CodeEditor;
