var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import InitialOptions from "@/builders/codeEditor/InitialCustomOptions";
import React, { createContext, useState } from "react";
const CustomOptions = [
  {
    name: "theme",
    label: "\uD14C\uB9C8",
    options: ["vs", "vs-dark", "hc-black", "hc-light"]
  },
  {
    name: "fontSize",
    label: "\uAE00\uC790\uD06C\uAE30",
    options: [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30]
  },
  {
    name: "formatOnType",
    label: "\uC785\uB825 \uC2DC \uC790\uB3D9 \uD3EC\uB9F7\uD305",
    options: [
      { label: "on", value: true },
      { label: "off", value: false }
    ]
  },
  {
    name: "minimapEnabled",
    label: "\uBBF8\uB2C8\uB9F5",
    options: [
      { label: "on", value: true },
      { label: "off", value: false }
    ]
  },
  {
    name: "wordWrap",
    label: "\uC904\uBC14\uAFC8 \uC124\uC815",
    options: ["off", "on", "wordWrapColumn", "bounded"]
  }
];
const initialState = {
  selectedOptions: InitialOptions,
  CustomOptions,
  handleSelectOpt: () => {
  },
  isFullScreen: false,
  handleFullScreen: () => {
  }
};
export const CodeEditorContext = createContext(
  initialState
);
export const CodeEditorContextProvider = ({ children }) => {
  const [selectedOptions, setSelectedOptions] = useState(
    initialState.selectedOptions
  );
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  React.useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreen(document.fullscreenElement !== null);
    };
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);
  function handleFullScreen() {
    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }
  const handleSelectOpt = ({ name, value }) => {
    setSelectedOptions((options) => {
      return __spreadProps(__spreadValues({}, options), {
        [name]: value
      });
    });
  };
  return /* @__PURE__ */ React.createElement(
    CodeEditorContext.Provider,
    {
      value: {
        CustomOptions,
        selectedOptions,
        handleSelectOpt,
        isFullScreen,
        handleFullScreen
      }
    },
    children
  );
};
export const useCodeEditorContext = () => React.useContext(CodeEditorContext);
