var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
const rootLocateOrder = [
  "form",
  "table",
  "codeeditor",
  "nodeeditor",
  "section"
];
const sectionLocateOrder = ["components", "sections"];
export class MetaSorter {
  constructor(data) {
    this.nodes = /* @__PURE__ */ new Map();
    this.head = null;
    this.tail = null;
    this.wrapForms = false;
    this.doWrapTables = false;
    this.isRootMetaFlag = false;
    if (this.isRootMeta(data)) {
      this.isRootMetaFlag = true;
      this.wrapForms = data.wrapForms;
      this.doWrapTables = data.doWrapTables;
    }
    this.initializeNodes(data, this.isRootMetaFlag);
    this.processRelativePositioning(data, this.isRootMetaFlag);
  }
  initializeNodes(data, isRootMeta) {
    const locateOrder = isRootMeta ? rootLocateOrder : sectionLocateOrder;
    locateOrder.forEach((type) => {
      if (data[type]) {
        data[type].forEach((item) => {
          const node = {
            type,
            key: this.getTypeKey(type, item, this.isRootMetaFlag),
            data: item,
            prev: null,
            next: null
          };
          this.nodes.set(node.key, node);
          this.appendNode(node);
        });
      }
    });
  }
  appendNode(node) {
    if (!this.head) {
      this.head = node;
      this.tail = node;
    } else {
      node.prev = this.tail;
      this.tail.next = node;
      this.tail = node;
    }
  }
  processRelativePositioning(data, isRootMeta) {
    const key = isRootMeta ? "section" : "sections";
    if (data[key]) {
      data[key].forEach((section) => {
        if (section.locateTargetKey && section.locateWhere) {
          const targetNode = this.nodes.get(section.locateTargetKey);
          const currentNode = this.nodes.get(`[sections] ${section.name}`);
          if (targetNode && currentNode) {
            this.removeNode(currentNode);
            if (section.locateWhere === "Above") {
              this.insertBefore(targetNode, currentNode);
            } else if (section.locateWhere === "Below") {
              this.insertAfter(targetNode, currentNode);
            }
          }
        }
      });
    }
  }
  removeNode(node) {
    if (node.prev) {
      node.prev.next = node.next;
    } else {
      this.head = node.next;
    }
    if (node.next) {
      node.next.prev = node.prev;
    } else {
      this.tail = node.prev;
    }
    node.prev = null;
    node.next = null;
  }
  insertBefore(target, node) {
    node.prev = target.prev;
    node.next = target;
    if (target.prev) {
      target.prev.next = node;
    } else {
      this.head = node;
    }
    target.prev = node;
  }
  insertAfter(target, node) {
    node.prev = target;
    node.next = target.next;
    if (target.next) {
      target.next.prev = node;
    } else {
      this.tail = node;
    }
    target.next = node;
  }
  collectWrappedNodes(startNode, type) {
    const nodes = [startNode];
    const skippedNodes = [];
    let current = startNode;
    while (current.next) {
      if (current.next.type === type) {
        nodes.push(current.next);
        current = current.next;
      } else {
        skippedNodes.push(current.next);
        current = current.next;
      }
    }
    return {
      nodes,
      lastNode: current,
      skippedNodes
    };
  }
  getTypeKey(type, item, isRootMeta) {
    const typeKey = isRootMeta ? `${type}s` : type;
    return `[${typeKey}] ${item.name}`;
  }
  isRootMeta(data) {
    return "wrapForms" in data || "doWrapTables" in data;
  }
  getOrderedLayout() {
    const result = [];
    let current = this.head;
    const ordered = {};
    while (current) {
      if (ordered[current.key]) {
        current = current.next;
        continue;
      }
      if (current.type === "form" && this.wrapForms || current.type === "table" && this.doWrapTables || current.type === "codeeditor" || current.type === "nodeeditor") {
        const { nodes, lastNode, skippedNodes } = this.collectWrappedNodes(
          current,
          current.type
        );
        result.push({
          layoutType: current.type,
          data: nodes.map((node) => {
            const _a2 = node.data, { locateTargetKey, locateWhere } = _a2, cleanData = __objRest(_a2, ["locateTargetKey", "locateWhere"]);
            ordered[node.key] = true;
            return cleanData;
          })
        });
        current = skippedNodes[0] ? skippedNodes[0] : lastNode.next;
      } else {
        ordered[current.key] = true;
        const _a = current.data, { locateTargetKey, locateWhere } = _a, cleanData = __objRest(_a, ["locateTargetKey", "locateWhere"]);
        result.push({
          layoutType: current.type,
          data: [cleanData]
        });
        current = current.next;
      }
    }
    return result;
  }
}
