var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var fieldComponent_exports = {};
__export(fieldComponent_exports, {
  buildCodeEditor: () => buildCodeEditor,
  convertNumber: () => convertNumber,
  convertNumberToNew: () => convertNumberToNew,
  escapeValue: () => escapeValue,
  fieldComponent: () => fieldComponent,
  refineEventForDialog: () => refineEventForDialog
});
module.exports = __toCommonJS(fieldComponent_exports);
var import_lib_core = require("@bsgp/lib-core");
var import_lib_core2 = require("@bsgp/lib-core");
var import_lib_amount = require("@bsgp/lib-amount");
var import_lib_quantity = require("@bsgp/lib-quantity");
var import_functions = require("./lib/functions");
var import_use_pagination = require("./lib/use-pagination");
var Sentry = __toESM(require("@sentry/react"));
const fieldComponent = {};
function convertNumber(value, unit, options = {}) {
  const { isAmount, isQuantity, asA1 } = options;
  if (value === 0) {
    return value.toString();
  } else if (!value) {
    return value;
  }
  try {
    if (isAmount === true) {
      return (0, import_lib_amount.create)(value, unit, 1, { asA1 }).formattedNumber;
    } else if (isQuantity === true) {
      return (0, import_lib_quantity.create)(value, unit).formattedNumber;
    } else {
      return (0, import_lib_amount.numberWithCommas)(value);
    }
  } catch (ex) {
    console.error("convertNumber error", ex.message);
    return value;
  }
}
function convertNumberToNew(value, { isAmount, isQuantity, unit, asA1 }) {
  const valueIsBound = (0, import_functions.isBound)(value);
  let number = 0;
  if (valueIsBound) {
    const newValue = value.replace("{", "").replace("}", "");
    const newUnit = (0, import_lib_core2.defined)(unit, "").replace("{", "").replace("}", "");
    const parts = [{ path: newValue }];
    if (newUnit) {
      parts.push({ path: newUnit });
    }
    number = {
      parts,
      formatter: (sValue, sUnit) => {
        if (!sValue) {
          return sValue;
        }
        return convertNumber(sValue, (0, import_functions.isBound)(unit) ? sUnit : unit, {
          isAmount,
          isQuantity,
          asA1
        });
      }
    };
  } else {
    number = convertNumber(value, unit, {
      isAmount,
      isQuantity,
      asA1
    });
  }
  return number;
}
fieldComponent.ObjectNumber = (component, text) => ({
  name,
  value,
  properties
}) => {
  const number = convertNumberToNew(value, {
    isAmount: properties.isAmount,
    isQuantity: properties.isQuantity,
    unit: properties.unit,
    asA1: properties.asA1
  });
  const oComp = component.set(window.sap.m.ObjectNumber, __spreadValues({
    name,
    number
  }, properties));
  oComp.addStyleClass("ft_display_bottom_dash_on_table");
  return oComp;
};
fieldComponent.ObjectAttribute = (component) => ({
  name,
  value,
  properties,
  title,
  normalWeight
}) => {
  return component.set(window.sap.m.ObjectAttribute, __spreadValues({
    name,
    title,
    text: value
  }, properties));
};
fieldComponent.ObjectIdentifier = (component) => ({
  name,
  value,
  properties,
  text,
  normalWeight
}) => {
  return component.set(window.sap.m.ObjectIdentifier, __spreadProps(__spreadValues({
    name,
    title: value,
    text,
    emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On
  }, properties), {
    callback: (oComp) => {
      oComp.removeStyleClass("ft-normal-font-weight");
      if (normalWeight === true) {
        oComp.addStyleClass("ft-normal-font-weight");
      }
    }
  }));
};
fieldComponent.ObjectStatus = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.ObjectStatus, __spreadValues({
    name,
    text: value
  }, properties));
};
fieldComponent.ToolbarSpacer = (component) => ({ name, properties }) => {
  return component.set(window.sap.m.ToolbarSpacer, __spreadValues({
    name
  }, properties));
};
fieldComponent.HTML = (component) => ({ name, value, properties }) => {
  const createBody = (data) => new DOMParser().parseFromString(data, "text/html").getElementsByTagName("body")[0];
  let content;
  if (!/<\/?[a-z][\s\S]*>/i.test(value)) {
    content = `<div>${createBody(value).innerText}</div>`;
  } else content = `<div>${createBody(value).innerHTML}</div>`;
  return component.set(window.sap.ui.core.HTML, __spreadValues({
    name,
    content
  }, properties));
};
fieldComponent.PDFViewer = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.PDFViewer, __spreadValues({
    name,
    value
  }, properties));
};
fieldComponent.DateTime = (component) => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  return component.set(window.sap.m.DateTimePicker, __spreadValues(__spreadValues({
    name,
    value
  }, properties), format && {
    valueFormat: format,
    displayFormat: displayFormat || format
  }));
};
fieldComponent.Date = (component) => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  return component.set(window.sap.m.DatePicker, __spreadValues(__spreadValues({
    name,
    value
  }, properties), format && {
    valueFormat: format,
    displayFormat: displayFormat || format
  }));
};
fieldComponent.Time = (component) => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  return component.set(window.sap.m.TimePicker, __spreadValues(__spreadValues({
    name,
    value
  }, properties), format && {
    valueFormat: format,
    displayFormat: displayFormat || format
  }));
};
fieldComponent.DateRange = (component) => ({
  name,
  value,
  properties,
  format,
  displayFormat
}) => {
  const [dateValue, secondDateValue] = (0, import_lib_core2.isArray)(value) ? value : [value];
  const values = {};
  if (dateValue && (0, import_lib_core2.isString)(dateValue)) {
    if (secondDateValue) {
      values.value = [dateValue, secondDateValue].join(" - ");
    } else {
      values.value = [dateValue, dateValue].join(" - ");
    }
  } else {
    values.dateValue = dateValue;
    values.secondDateValue = secondDateValue || dateValue;
  }
  return component.set(window.sap.m.DateRangeSelection, __spreadValues(__spreadValues(__spreadProps(__spreadValues({
    name
  }, values), {
    showCurrentDateButton: true
  }), properties), format && {
    valueFormat: format,
    displayFormat: displayFormat || format
  }));
};
fieldComponent.Radio = (component, customData) => ({
  name,
  value,
  properties = {},
  list = []
}) => {
  const newList = list.map((each) => {
    if ((0, import_lib_core2.isString)(each)) {
      return { key: each, text: each };
    }
    return each;
  });
  const valueIsBound = (0, import_functions.isBound)(value);
  return component.set(window.sap.m.RadioButtonGroup, __spreadProps(__spreadValues(__spreadValues({
    name
  }, properties), properties.select && {
    select: (oEvent) => {
      const selectedButton = oEvent.getSource().getSelectedButton();
      const oCustomData = selectedButton.getCustomData().find((data) => {
        return data.getKey() === "key";
      });
      if (oCustomData) {
        properties.select(oEvent, oCustomData.getValue());
      } else {
        properties.select(oEvent, void 0);
      }
    }
  }), {
    selectedIndex: valueIsBound ? {
      parts: [{ path: value.replace("{", "").replace("}", "") }],
      formatter: (sKey) => {
        if (!sKey) {
          return 0;
        }
        return newList.findIndex((button) => button.key === sKey);
      }
    } : newList.findIndex((button) => button.key === value),
    columns: properties.columns || newList.length,
    buttons: newList.map((button) => {
      const encodedButtonKey = button.key.toString().split("").map((char, idx) => {
        if (char.search(/[a-zA-Z0-9_-]/) >= 0) {
          return char;
        }
        return char.charCodeAt(idx);
      }).join("");
      const btnName = [name, encodedButtonKey].join("-");
      return component.set(window.sap.m.RadioButton, {
        name: btnName,
        customData: customData.set({
          name: [btnName, encodedButtonKey].join("_"),
          key: "key",
          value: button.key
        }),
        text: button.text,
        callback: (comp, isInitial) => {
          if (button.class) {
            comp && comp.addStyleClass(button.class);
          }
        }
      });
    })
  }));
};
fieldComponent.SegmentedButton = (component, vBox) => ({
  name,
  value,
  label,
  properties = {},
  list = []
}) => {
  const oButtons = component.set(window.sap.m.SegmentedButton, __spreadProps(__spreadValues(__spreadValues({
    name
  }, properties), properties.selectionChange && {
    selectionChange: (oEvent) => {
      const selectedItem = oEvent.getParameters().item;
      const selectedKey = selectedItem.getKey();
      properties.selectionChange(oEvent, { selectedKey });
    }
  }), {
    selectedKey: value,
    items: list.map((button) => {
      const btnName = [name, button.key].join("-");
      return component.set(window.sap.m.SegmentedButtonItem, {
        name: btnName,
        key: button.key,
        text: button.text,
        icon: button.icon,
        width: button.width
      });
    })
  }));
  if (label) {
    return component.set(window.sap.ui.layout.VerticalLayout, {
      name: [name, "siWrapper"].join("_"),
      content: [
        component.set(window.sap.m.Label, __spreadValues({
          name: [name, "label"].join("_"),
          text: label,
          labelFor: oButtons.getIdForLabel()
        }, properties)),
        oButtons
      ]
    });
  }
  return oButtons;
};
function getListItemConfig(options = {}) {
  const { sorter = [], component, name, properties, valueIsBound } = options;
  return {
    path: valueIsBound === true ? "/".concat([name, "items"].join("-")) : "/items",
    templateShareable: true,
    sorter: sorter.map(
      (each) => new window.sap.ui.model.Sorter(each.key, false, function(oContext) {
        const data = oContext.getObject();
        return {
          key: data[each.key],
          text: data[each.text]
        };
      })
    ),
    template: component.set(window.sap.ui.core.ListItem, {
      name: [name, "items"].join("-"),
      settings: __spreadValues({}, properties)
    })
  };
}
fieldComponent.ComboBox = (component) => ({
  name,
  value,
  properties = {},
  sorter = [],
  itemProperties,
  itemData,
  multiple
}) => {
  const valueIsBound = (0, import_functions.isBound)(value);
  return component.set(
    multiple ? window.sap.m.MultiComboBox : window.sap.m.ComboBox,
    __spreadProps(__spreadValues(__spreadValues({
      name
    }, multiple ? { selectedKeys: valueIsBound ? value : "{/value}" } : {
      selectedKey: valueIsBound ? value : "{/value}"
    }), properties), {
      items: getListItemConfig({
        valueIsBound,
        sorter,
        component,
        name,
        length: itemData.length,
        properties: itemProperties
      }),
      callback: (comp, isInitial, setData) => {
        if (valueIsBound) {
        } else {
          setData(
            { items: itemData, value },
            { template: comp.getParent(), length: itemData.length }
          );
          if (multiple) {
          } else {
            if (itemData.findIndex((each) => each.key === value) < 0) {
              console.log(
                "setValue for combobox since the value is not existing in list",
                name,
                value
              );
              comp.setValue(value);
            }
          }
        }
      }
    })
  );
};
fieldComponent.Select = (select, component) => ({
  name,
  value,
  properties = {},
  // sorter = [],
  itemProperties,
  itemData
}) => {
  const valueIsBound = (0, import_functions.isBound)(value);
  return select.set(__spreadProps(__spreadValues({
    name,
    selectedKey: valueIsBound ? value : "{/value}"
  }, properties), {
    items: getListItemConfig({
      valueIsBound,
      component,
      name,
      properties: itemProperties
    }),
    // {
    //   path: valueIsBound ? "/".concat([name, "items"].join("-")) : "/items",
    //   // sorter: sorter.map(
    //   //   each =>
    //   //     new window.sap.ui.model.Sorter
    //   // (each.key, false, function(oContext) {
    //   //       const data = oContext.getObject();
    //   //       return {
    //   //         key: data[each.key],
    //   //         text: data[each.text]
    //   //       };
    //   //     })
    //   // ),
    //   templateShareable: true,
    //   template: component.set(window.sap.ui.core.ListItem, {
    //     name: [name, "items"].join("-"),
    //     settings: {
    //       key: "{key}",
    //       text: combine === true ? "{key} - {text}" : "{text}",
    //       enabled: "{enabled}"
    //     }
    //   })
    // },
    callback: (comp, isInitial, setData) => {
      const oParent = comp.getParent();
      if (valueIsBound) {
      } else {
        setData(
          {
            items: itemData,
            value
          },
          { template: oParent }
        );
        comp.setSelectedKey(value);
      }
    }
  }));
};
fieldComponent.GenericTile = (component, customData) => ({
  name,
  value,
  properties
}) => {
  const isNumberContent = (0, import_lib_core2.isNumber)(value);
  const useFeedNotNumeric = !!properties.contentText;
  const newValue = escapeValue(value);
  return component.set(
    window.sap.m.GenericTile,
    (0, import_functions.removeUndefinedKeys)(__spreadProps(__spreadValues(__spreadProps(__spreadValues({
      name
    }, properties), {
      icon: void 0
    }), isNumberContent ? {
      tileContent: component.set(window.sap.m.TileContent, __spreadProps(__spreadValues({
        name: `${name}_tc`
      }, properties), {
        content: useFeedNotNumeric ? component.set(window.sap.m.FeedContent, __spreadValues({
          name: `${name}_tc_fc`,
          value: properties.scale ? [newValue, properties.scale].join(" ") : newValue
        }, properties)) : component.set(window.sap.m.NumericContent, __spreadValues({
          name: `${name}_tc_nc`,
          value: newValue
        }, properties))
      }))
    } : {}), {
      callback: (oComp) => {
      }
    }))
  );
};
function selectOnClickText(event) {
  const that = event.target;
  let range, selection;
  if (window.getSelection && document.createRange) {
    selection = window.getSelection();
    if (selection.toString().length === 0) {
      range = document.createRange();
      range.selectNodeContents(that);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  } else if (document.selection && document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(that);
    range.select();
  }
}
fieldComponent.ExpandableText = (component, customData) => ({
  name,
  value,
  properties
}) => {
  const newValue = escapeValue(value);
  return component.set(window.sap.m.ExpandableText, __spreadProps(__spreadValues({
    name,
    text: newValue,
    renderWhitespace: true,
    emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    width: "auto"
  }, properties), {
    callback: (oComp) => {
      detachEvent(oComp, "click");
      attachEvent(customData, name, oComp, "click", selectOnClickText);
    }
  }));
};
fieldComponent.MessageStrip = (component, customData) => ({
  name,
  value,
  properties
}) => {
  const newValue = escapeValue(value);
  return component.set(window.sap.m.MessageStrip, __spreadProps(__spreadValues({
    name,
    text: newValue,
    showIcon: true
  }, properties), {
    callback: (oComp) => {
      detachEvent(oComp, "click");
      attachEvent(customData, name, oComp, "click", selectOnClickText);
    }
  }));
};
fieldComponent.FormattedText = (component) => ({ name, value, properties }) => {
  const newValue = escapeValue(value);
  return component.set(window.sap.m.FormattedText, __spreadValues({
    name,
    htmlText: newValue,
    // renderWhitespace: true,
    // emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    width: "auto"
  }, properties));
};
fieldComponent.Switch = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.Switch, __spreadProps(__spreadValues({
    name
  }, properties), {
    state: value
  }));
};
fieldComponent.Text = (text, customData) => ({
  name,
  value,
  properties,
  styleClasses
}) => {
  const newValue = escapeValue(value);
  return text.set(__spreadProps(__spreadValues({
    name,
    text: newValue,
    emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On,
    width: "auto",
    renderWhitespace: true
  }, properties), {
    callback: (oComp) => {
      oComp.removeStyleClass(styleClasses.join(" "));
      oComp.addStyleClass(styleClasses.join(" "));
      detachEvent(oComp, "click");
      attachEvent(customData, name, oComp, "click", selectOnClickText);
    }
  }));
};
function getTableFromList(oContents) {
  for (let idx = 0; idx < oContents.length; idx += 1) {
    const oComp = oContents[idx];
    const className = oComp.getMetadata().getName();
    if (["sap.ui.table.TreeTable", "sap.m.Table"].includes(className)) {
      return oComp;
    }
    if (className === "sap.m.VBox") {
      const oItems = oComp.getItems();
      return getTableFromList(oItems);
    }
    if (className === "sap.m.ScrollContainer") {
      const oContents2 = oComp.getContent();
      return getTableFromList(oContents2);
    }
  }
  return void 0;
}
function refineEventForDialog(oContent, callback = {}) {
  const onPressList = (0, import_lib_core2.tryit)(() => oContent.mEventRegistry.press) || [];
  if (onPressList.length > 0) {
    const prevHandler = onPressList[0].fFunction;
    const onPress = prevHandler.originalHandler || prevHandler;
    oContent.detachPress(prevHandler);
    const newHandler = (oEvent) => __async(this, null, function* () {
      const oSource = oEvent.getSource();
      const oHeader = oSource.getParent();
      const oDialog = oHeader.getParent();
      const oContents = oDialog.getContent();
      const oTable = getTableFromList(oContents);
      let tableData;
      if (oTable) {
        if (oTable.getMetadata().getName() === "sap.m.Table") {
          const oItems = oTable.getSelectedItems();
          const items = oItems.map(
            (item) => item.getBindingContext().getObject()
          );
          tableData = {
            oTable,
            oItems,
            items
          };
        } else {
          const indices = oTable.getSelectedIndices();
          const items = indices.map(
            (index) => oTable.getContextByIndex(index).getObject()
          );
          tableData = {
            oTable,
            // oItems,
            items
          };
        }
      }
      const newEvent = {
        getSource: () => oSource
      };
      const oCustomDataVhIndex = oDialog.getCustomData().find((data) => {
        return data.getKey() === "vhIndex";
      });
      const vhIndex = oCustomDataVhIndex && oCustomDataVhIndex.getValue();
      const buttonType = oContent.getType();
      const cbData = __spreadValues({
        oEvent: newEvent,
        oDialog,
        oHeader,
        index: vhIndex
      }, tableData);
      let toCloseDialog = true;
      if (buttonType === window.sap.m.ButtonType.Reject) {
        if (callback.onBeforeCancel) {
          toCloseDialog = (0, import_lib_core2.defined)(callback.onBeforeCancel(cbData), true);
        }
      } else {
        if (callback.onConfirm) {
          let result = (0, import_lib_core2.defined)(callback.onConfirm(cbData), true);
          if ((0, import_lib_core2.isPromise)(result)) {
            result = yield result;
          }
          toCloseDialog = result;
        }
      }
      newEvent.toCloseDialog = toCloseDialog;
      onPress(newEvent);
      if (buttonType === window.sap.m.ButtonType.Reject) {
        if (callback.onAfterCancel) {
          callback.onAfterCancel(cbData);
        }
      }
    });
    newHandler.originalHandler = onPress;
    oContent.attachPress(void 0, newHandler);
  }
}
fieldComponent.TextArea = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.TextArea, __spreadValues({
    name,
    value: (value != null ? value : "").toString()
  }, properties));
};
fieldComponent.CodeEditor = (component, button, vBox, customData, fn) => ({
  name,
  value,
  properties,
  getFieldWithFactory
}) => {
  return vBox.set({
    name: [name, "ceWrapper"].join("_"),
    items: buildCodeEditor({ [name]: { properties, value } }, fn, {
      customData,
      component,
      button,
      doNotRefine: true,
      pageKey: name,
      getFieldWithFactory
    }).filter(Boolean)
  });
};
function escapeValue(value) {
  const newValue = (0, import_lib_core2.isArray)(value) ? value : (0, import_lib_core2.defined)(value, "").toString();
  return newValue;
}
fieldComponent.Input = (input, component, dialog, customData) => ({
  name,
  value,
  properties,
  refineProps: refineProps2,
  fn,
  multiple,
  isAmount,
  isQuantity,
  unit,
  items
}) => {
  let newValue = escapeValue(value);
  const disabledStyleClass = "disabled";
  const newProperties = __spreadValues({}, properties);
  if (properties.layoutData) {
    const oLayoutData = component.set(window.sap.m.FlexItemData, __spreadValues({
      name: [name, "layout_fc_Input"].join("_")
    }, properties.layoutData));
    newProperties.layoutData = oLayoutData;
  }
  if (properties.mask) {
    return component.set(window.sap.m.MaskInput, __spreadProps(__spreadValues({
      name,
      value: newValue
    }, properties), {
      callback: (comp) => {
        if (properties.enabled === false) {
          comp.addStyleClass(disabledStyleClass);
        } else {
          comp.removeStyleClass(disabledStyleClass);
        }
      }
    }));
  } else {
    if (properties.valueHelpV2) {
      const {
        dialogId,
        onRequest,
        onConfirm,
        onBeforeCancel,
        onAfterCancel
      } = properties.valueHelpV2;
      const callback = refineProps2(
        {
          onRequest,
          onBeforeCancel,
          onAfterCancel,
          onConfirm
        },
        fn
      );
      properties.valueHelpRequest = (oEvent) => __async(void 0, null, function* () {
        const oInput = oEvent.getSource();
        const value2 = oInput.getValue();
        const oItem = (0, import_functions.getParentItem)(oInput);
        const oParentTable = oItem && (0, import_functions.getParentTable)(oItem);
        const vhIndex = oParentTable && oParentTable.getItems().findIndex((item) => item === oItem);
        const oDialog = dialog.get(`${dialogId}_dlgV2`);
        oDialog.getCustomHeader().getContent().forEach((oContent) => {
          refineEventForDialog(oContent, callback);
        });
        oDialog.addCustomData(
          customData.set({
            name: [dialogId, "vhIndex"].join("_"),
            key: "vhIndex",
            value: vhIndex
          })
        );
        const oContents = oDialog.getContent();
        const oTable = getTableFromList(oContents);
        if (oTable && oTable.getMetadata().getName() === "sap.m.Table") {
          const oText = (0, import_use_pagination.getHeaderCompWithCustomKey)(oTable, "index");
          if (oText) {
            if (oDialog.isOpen()) {
            } else {
              oDialog.open();
            }
          }
          const oModel = oTable.getModel();
          const data = oModel.getData();
          let newItems;
          if (callback.onRequest) {
            const result = callback.onRequest({
              value: value2,
              oInput,
              row: (0, import_lib_core2.tryit)(() => oItem.getBindingContext().getObject()),
              index: vhIndex
            });
            if ((0, import_lib_core2.isPromise)(result)) {
              newItems = yield result;
            } else {
              newItems = result;
            }
          }
          if ((0, import_lib_core2.isArray)(newItems)) {
            const transaction = Sentry.startInactiveSpan({
              name: "Use_onRequest_Returned",
              op: "onRequest",
              data: {
                result: newItems
              }
            });
            transaction.finish();
            data.items = newItems;
            console.warn(
              [
                "It will be deprecated",
                "that fetching new items directly from onRequest"
              ].join(" ")
            );
            oModel.setData(data);
          }
          if (oText) {
          } else {
          }
        } else {
          if (callback.onRequest) {
            callback.onRequest({ value: value2, oInput });
          }
        }
        if (oDialog.isOpen()) {
        } else {
          oDialog.open();
        }
      });
      newProperties.valueHelpRequest = properties.valueHelpRequest;
    }
    if (multiple) {
      const itemProperties = (0, import_lib_core2.tryit)(() => items.properties, {});
      const itemData = (0, import_lib_core2.tryit)(() => items.list, []);
      return component.set(window.sap.m.MultiInput, __spreadProps(__spreadValues({
        name,
        value: "",
        tokens: {
          path: "/value",
          template: component.set(window.sap.m.Token, __spreadProps(__spreadValues({}, itemProperties), {
            editable: "{editable}"
          }))
        }
      }, properties), {
        suggestionItems: getListItemConfig({
          component,
          name,
          properties: itemProperties
        }),
        callback: (comp, isInitial, setData) => {
          const newKeys = newValue.map((each) => {
            if ((0, import_lib_core2.isObject)(each)) {
              return each.key;
            }
            return each;
          });
          const itemList = itemData.map((each) => {
            if ((0, import_lib_core2.isObject)(each)) {
              return __spreadValues({}, each);
            }
            return { key: each, text: each };
          });
          const tokens = itemList.filter((each) => newKeys.includes(each.key));
          const excludedKeys = newKeys.filter(
            (key) => tokens.findIndex((token) => token.key === key) < 0
          );
          tokens.push(...excludedKeys.map((key) => ({ key, text: key })));
          const objectValues = newValue.filter((each) => (0, import_lib_core2.isObject)(each));
          tokens.forEach((token) => {
            const objValue = objectValues.find((each) => each.key === token.key);
            if (objValue) {
              Object.keys(objValue).forEach((objKey) => {
                token[objKey] = objValue[objKey];
              });
            }
          });
          setData(
            {
              items: itemList.filter((each) => !newKeys.includes(each.key)),
              value: tokens
            },
            { template: comp.getParent() }
          );
        }
      }));
    }
    if (isAmount === true || isQuantity === true) {
      try {
        newValue = convertNumberToNew(newValue, {
          isAmount,
          isQuantity,
          unit
        });
        if (newProperties.valueState === void 0) {
          if (input.get(name) === void 0) {
          } else {
            if (input.get(name).getValueState() !== window.sap.ui.core.ValueState.None) {
              input.get(name).setValueState(window.sap.ui.core.ValueState.None);
              input.get(name).setValueStateText("");
            }
          }
        }
      } catch (ex) {
        console.error("convertNumberToNew error", ex.message);
        newProperties.valueState = window.sap.ui.core.ValueState.Error;
        newProperties.valueStateText = ex.message;
      }
      if (newProperties.textAlign === void 0) {
        newProperties.textAlign = window.sap.ui.core.TextAlign.Right;
      }
      if (properties.change) {
        newProperties.change = (oEvent) => {
          oEvent.mParameters.value = (0, import_lib_amount.removeCommas)(oEvent.mParameters.value);
          properties.change(oEvent);
        };
      }
    }
    return input.set(__spreadProps(__spreadValues({
      name,
      value: newValue
    }, newProperties), {
      callback: (oComp) => {
        const oInput = oComp.input.comp;
        oInput.setValue(newValue);
        if (properties.focus) {
          setTimeout(() => oComp.input.comp.focus(), 400);
        } else {
          setTimeout(() => {
            const oTable = properties.parentTableId ? component.get(properties.parentTableId) : (0, import_functions.getParentTable)(oInput);
            if (oTable) {
              const tableClassName = oTable.getMetadata().getName();
              detachEvent(oInput, "focusin");
              attachEvent(
                customData,
                name,
                oInput,
                "focusin",
                focusinTableHandler({
                  tableName: oTable.getId(),
                  isTree: tableClassName === "sap.ui.table.TreeTable"
                })
              );
            } else {
              detachEvent(oInput, "focusin");
              attachEvent(
                customData,
                name,
                oInput,
                "focusin",
                focusinFormHandler
              );
            }
          });
        }
        if (properties.enabled === false) {
          oInput.addStyleClass(disabledStyleClass);
        } else {
          oInput.removeStyleClass(disabledStyleClass);
        }
      }
    }));
  }
};
const focusinFormHandler = (event) => {
  window.currentFocusInputName = void 0;
  let el = event.target;
  while (el) {
    const tag = el.tagName;
    if (tag === "DIV") {
      if (el.classList.contains("sapMInput")) {
        window.currentFocusInputName = el.id;
        break;
      }
    }
    el = el.parentElement;
  }
  setTimeout(function() {
    window.currentFocusInputName = void 0;
  }, 200);
};
const focusinTableHandler = ({ isTree = false, tableName }) => (event) => {
  window.currentFocusRowIndex = void 0;
  window.currentFocusColIndex = void 0;
  window.currentFocusTableName = tableName;
  let el = event.target;
  let stepToBreak = 2;
  while (el && stepToBreak > 0) {
    const tag = el.tagName;
    if (tag === "TD") {
      let colIndex = 0;
      let el2 = el;
      while (el2 != null) {
        el2 = el2.previousSibling;
        if (el2 === null) {
          break;
        }
        if (el2.classList.contains(isTree ? "sapUiTableCell" : "sapMListTblCell")) {
          colIndex += 1;
        }
      }
      window.currentFocusColIndex = colIndex;
      console.log("colIndex:", colIndex);
      stepToBreak -= 1;
    }
    if (tag === "TR") {
      let rowIndex = 0;
      let el2 = el;
      while (el2 != null) {
        el2 = el2.previousSibling;
        if (el2 === null) {
          break;
        }
        if (el2.classList.contains(isTree ? "sapUiTableRow" : "sapMListTblRow")) {
          rowIndex += 1;
        }
      }
      window.currentFocusRowIndex = rowIndex;
      console.log("rowIndex:", rowIndex);
      stepToBreak -= 1;
    }
    el = el.parentElement;
  }
  setTimeout(function() {
    window.currentFocusRowIndex = void 0;
    window.currentFocusColIndex = void 0;
  }, 200);
};
function attachEvent(customData, name, oComp, eventId, fHandler) {
  oComp.addCustomData(
    customData.set({
      name: [name, `${eventId}Handler`].join("_"),
      key: `${eventId}Handler`,
      value: fHandler
    })
  );
  oComp.attachBrowserEvent(eventId, fHandler);
}
function detachEvent(oComp, eventId) {
  const oCustomData = oComp.getCustomData().find((data) => {
    return data.getKey() === `${eventId}Handler`;
  });
  if (oCustomData) {
    const focusinHandler = oCustomData.getValue();
    oComp.detachBrowserEvent(eventId, focusinHandler);
  }
}
fieldComponent.Link = (link) => ({ name, value, properties }) => {
  return link.set(__spreadValues({
    name,
    text: properties.text || value
  }, properties));
};
fieldComponent.List = (component, fn) => ({
  name,
  properties,
  itemProperties,
  itemData
}) => {
  return component.set(window.sap.m.List, __spreadProps(__spreadValues({
    name
  }, properties), {
    items: {
      path: "/",
      template: component.set(window.sap.m.ActionListItem, __spreadValues({
        text: "{text}",
        tooltip: "{tooltip}"
      }, (0, import_functions.refineProps)(itemProperties, fn)))
    },
    callback: (comp, isInitial, setData) => {
      setData(itemData);
    }
  }));
};
function buildMenuItems(items, { name, getFieldWithFactory }) {
  if (!items) {
    return void 0;
  }
  return Object.keys(items).map((key) => {
    return getFieldWithFactory(`${name}_${key}`, {
      value: items[key].value,
      component: {
        type: "MenuItem",
        confirmMessage: items[key].confirmMessage,
        properties: items[key].properties
      },
      items: items[key].items
    });
  });
}
fieldComponent.Menu = (component, fn) => ({
  name,
  items,
  getFieldWithFactory
}) => {
  return component.set(window.sap.m.Menu, {
    name,
    items: buildMenuItems(items, { name, getFieldWithFactory })
  });
};
fieldComponent.MenuItem = (component, fn) => ({
  name,
  value,
  properties,
  confirmMessage,
  items,
  getFieldWithFactory
}) => {
  const needToConfirm = !!confirmMessage;
  let onPress;
  if (properties.press) {
    onPress = (oEvent) => {
      if (needToConfirm) {
        window.sap.ui.require(["sap/m/MessageBox"], function(MessageBox) {
          MessageBox.confirm(confirmMessage, {
            initialFocus: window.sap.m.MessageBox.Action.CANCEL,
            onClose: function(oAction) {
              if (oAction === window.sap.m.MessageBox.Action.OK) {
                properties.press(oEvent);
              }
            }
          });
        });
      } else {
        properties.press(oEvent);
      }
    };
  }
  return component.set(
    window.sap.m.MenuItem,
    (0, import_functions.removeUndefinedKeys)(__spreadProps(__spreadValues({
      name: `${name}_${(0, import_lib_core.makeid)(15)}`,
      text: value
    }, properties), {
      press: onPress,
      items: buildMenuItems(items, { name, getFieldWithFactory })
    }))
  );
};
fieldComponent.MenuButton = (component, fn) => ({
  name,
  value,
  items,
  properties,
  getFieldWithFactory
}) => {
  return component.set(window.sap.m.MenuButton, __spreadProps(__spreadValues({
    name,
    text: value,
    type: window.sap.m.ButtonType.Ghost
  }, properties), {
    menu: getFieldWithFactory(`${name}_menu`, {
      component: { type: "Menu" },
      items
    })
  }));
};
fieldComponent.SearchField = (component, fn) => ({
  name,
  value,
  properties
}) => {
  return component.set(window.sap.m.SearchField, __spreadValues({
    name,
    text: value
  }, properties));
};
fieldComponent.Button = (button, component) => ({
  name,
  value,
  number,
  confirmMessage,
  key,
  isScannerButton,
  onScan,
  onError,
  properties = {}
}) => {
  const { pageKey } = component;
  let customData;
  if (number !== void 0) {
    const strNum = number.toString();
    customData = component.set(window.sap.m.BadgeCustomData, {
      name: `${name}_badge`,
      key: `${name}_badge`,
      value: strNum
    });
  }
  const needToConfirm = !!confirmMessage;
  const scannerId = `${pageKey}_${key}_barcode_dialog`;
  let oScannerDialog;
  if (isScannerButton) {
    oScannerDialog = component.set(
      window.sap.ui.webc.fiori.BarcodeScannerDialog,
      {
        name: scannerId,
        scanError: (oEvent) => {
          const errorMsg = oEvent.getParameter("message");
          onError(errorMsg);
          component.get(scannerId).close();
        },
        scanSuccess: (oEvent) => {
          const barcode = oEvent.getParameter("text");
          onScan(barcode);
          component.get(scannerId).close();
        }
      }
    );
  }
  let onPress;
  if (isScannerButton) {
    onPress = () => {
      return component.get(scannerId).show();
    };
  } else if (properties.press) {
    onPress = (oEvent) => {
      if (needToConfirm) {
        window.sap.ui.require(["sap/m/MessageBox"], function(MessageBox) {
          MessageBox.confirm(confirmMessage, {
            initialFocus: window.sap.m.MessageBox.Action.CANCEL,
            onClose: function(oAction) {
              if (oAction === window.sap.m.MessageBox.Action.OK) {
                properties.press(oEvent);
              }
            }
          });
        });
      } else {
        properties.press(oEvent);
      }
    };
  }
  const oButton = button.set(
    (0, import_functions.removeUndefinedKeys)(__spreadProps(__spreadValues({
      name,
      text: value,
      customData
    }, properties), {
      press: onPress
    }))
  );
  return oScannerDialog ? [oButton, oScannerDialog] : oButton;
};
fieldComponent.Separator = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.ToolbarSeparator, __spreadValues({
    name
  }, properties));
};
fieldComponent.CheckBox = (checkbox, customData) => ({
  name,
  value,
  hiddenData,
  styleClasses,
  properties
}) => {
  return checkbox.set(__spreadProps(__spreadValues({
    name,
    selected: value,
    customData: customData.set({
      name: `${name}_hidden_data`,
      key: "hiddenData",
      value: hiddenData
    })
  }, properties), {
    callback: (oComp) => {
      oComp.removeStyleClass(styleClasses.join(" "));
      oComp.addStyleClass(styleClasses.join(" "));
    }
  }));
};
fieldComponent.FileUploader = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.ui.unified.FileUploader, __spreadValues({
    name
  }, properties));
};
fieldComponent.Image = (component, hBox, customData) => ({
  name,
  value,
  bindArray,
  hiddenData,
  properties
}) => {
  if (!bindArray) {
    return component.set(window.sap.m.Image, __spreadProps(__spreadValues({
      name,
      src: value,
      customData: customData.set({
        name: `${name}_hidden_data`,
        key: "hiddenData",
        value: hiddenData
      })
    }, properties), {
      callback: (oComp) => {
        oComp.addStyleClass("ft-image-max-width-100");
      }
    }));
  }
  return hBox.set(__spreadProps(__spreadValues({
    name
  }, properties), {
    items: {
      path: [value.replace("{", "").replace("}", ""), "/"].join(""),
      templateShareable: true,
      template: component.set(window.sap.m.Image, __spreadValues({
        name: [name, "items"].join("-"),
        src: "{url}",
        width: "2rem",
        height: "2rem",
        customData: customData.set({
          name: `${name}_hidden_data`,
          key: "hiddenData",
          value: hiddenData
        })
      }, properties))
    }
  }));
};
fieldComponent.Avatar = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.Avatar, __spreadValues({
    name,
    src: value
  }, properties));
};
fieldComponent.NotificationListItem = (component, vBox) => ({
  name,
  value,
  label,
  properties
}) => {
  const oNotiCont = vBox.set({
    name: `${name}_cont`,
    layoutData: component.set(
      window.sap.m.FlexItemData,
      (0, import_functions.removeUndefinedKeys)({
        name: "wrapLayout-" + name,
        // baseSize: "100%",
        growFactor: 1
        // maxWidth: col.maxWidth,
        // minWidth: col.minWidth
      })
    ),
    items: {
      path: [value.replace("{", "").replace("}", ""), "/"].join(""),
      templateShareable: true,
      template: component.set(window.sap.m.NotificationListItem, __spreadProps(__spreadValues({
        name: `${name}_item`,
        showCloseButton: false,
        description: "{text}",
        counter: 5,
        datetime: "{when}",
        authorName: "{who}",
        authorInitials: "{who}",
        authorAvatarColor: "Accent8"
      }, properties), {
        callback: (oComp) => {
          oComp.addStyleClass("noBottomLine");
          oComp.addStyleClass("noPointer");
        }
      }))
    }
  });
  if (label) {
    return vBox.set({
      name: [name, "siWrapper"].join("_"),
      items: [
        component.set(window.sap.m.Label, __spreadValues({
          name: [name, "label"].join("_"),
          text: label,
          labelFor: oNotiCont.getIdForLabel()
        }, properties)),
        oNotiCont
      ]
    });
  }
  return oNotiCont;
};
fieldComponent.StepInput = (component, vBox, customData) => ({
  name,
  value,
  label,
  hiddenData,
  properties
}) => {
  const oStepInput = component.set(window.sap.m.StepInput, __spreadProps(__spreadValues({
    name,
    value
  }, properties), {
    customData: customData.set({
      name: `${name}_hidden_data`,
      key: "hiddenData",
      value: hiddenData
    })
  }));
  if (label) {
    return vBox.set({
      name: [name, "siWrapper"].join("_"),
      items: [
        component.set(window.sap.m.Label, __spreadValues({
          name: [name, "label"].join("_"),
          text: label,
          labelFor: oStepInput.getIdForLabel()
        }, properties)),
        oStepInput
      ]
    });
  }
  return oStepInput;
};
fieldComponent.Label = (component) => ({ name, value, properties }) => {
  return component.set(window.sap.m.Label, __spreadValues({
    name,
    text: value
  }, properties));
};
function buildCodeEditor(codeeditor, fn, options = {}) {
  const {
    customData,
    component,
    button,
    doNotRefine = false,
    pageKey = "",
    getFieldWithFactory
  } = options;
  const ceKeys = Object.keys(codeeditor);
  const hasMultiCe = ceKeys.length > 1;
  const ceInstances = ceKeys.map((ceKey) => {
    const ceObject = codeeditor[ceKey];
    const editorProperties = doNotRefine ? ceObject.properties : (0, import_functions.refineProps)(ceObject.properties, fn);
    const ceId = [pageKey, ceKey].join("_");
    const buttons = (0, import_lib_core2.isArray)(ceObject.buttons) ? ceObject.buttons : (0, import_lib_core2.isObject)(ceObject.buttons) ? [ceObject.buttons] : [];
    const oToolbar = component.set(window.sap.m.Toolbar, {
      name: [ceId, "cedToolbar"].join("_"),
      content: [
        button.set({
          name: [ceId, "toggleFullscreen"].join("_"),
          icon: "sap-icon://full-screen",
          press: (oEvent) => {
            const oButton = oEvent.getSource();
            const jqObj = window.$(oButton.getDomRef());
            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
              oButton.setIcon("sap-icon://full-screen");
              if (document.exitFullscreen) {
                document.exitFullscreen();
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
              }
            } else {
              oButton.setIcon("sap-icon://exit-full-screen");
              const element = jqObj.parents(".sapMDialog").get(0) || jqObj.parents('[id$="_vbox_ceWrapper"]').get(0);
              if (element.requestFullscreen) {
                element.requestFullscreen();
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
              } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
              }
            }
          }
        })
      ].concat(
        buttons.map((btn) => {
          const isMultiple = (0, import_lib_core2.isArray)(btn) && btn.length > 1;
          if (isMultiple) {
            const ceToolbarContentKey2 = [
              ceId,
              btn[0].name || btn[0].component.name
            ].join("_");
            return getFieldWithFactory(ceToolbarContentKey2, {
              value: btn[0].text || btn[0].value,
              component: {
                type: "MenuButton",
                properties: __spreadProps(__spreadValues(__spreadValues({
                  icon: btn[0].icon
                }, btn[0].properties), btn[0].component.properties), {
                  useDefaultActionOnly: true,
                  buttonMode: window.sap.m.MenuButtonMode.Split,
                  defaultAction: btn[0].component.properties.press
                })
              },
              items: btn.filter((each, index) => index > 0).reduce((acc, each, index) => {
                acc[`${ceToolbarContentKey2}_menuitem_${index}`] = {
                  value: each.text || each.value,
                  properties: __spreadValues(__spreadValues({
                    icon: each.icon,
                    press: each.onPress
                  }, each.properties), each.component.properties)
                };
                return acc;
              }, {})
            });
          }
          const ceToolbarContentKey = [
            ceId,
            btn.name || btn.component.name
          ].join("_");
          return getFieldWithFactory(ceToolbarContentKey, btn);
        })
      ),
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("sapUiSizeCompact");
        }
      }
    });
    const oEditor = component.set(window.sap.ui.codeeditor.CodeEditor, __spreadProps(__spreadValues(__spreadValues({
      name: ceId,
      height: "100%"
    }, editorProperties), editorProperties.change && {
      change: (oEvent) => {
        oEvent.componentCtg = "codeeditors";
        return editorProperties.change(oEvent);
      }
    }), {
      // fieldGroupIds: ["toggleWidth", "!Phone:80vw"],
      // value: ceObject.value,
      callback: (comp, isInitial) => {
        comp.setValue(ceObject.value);
        comp.setLayoutData(
          component.set(window.sap.m.FlexItemData, {
            name: `${ceId}_layout`,
            growFactor: 1
          })
        );
        comp._oEditor.setOption("tabSize", 2);
        comp._oEditor.setOption("useSoftTabs", true);
        comp._oEditor.setKeyboardHandler("ace/keyboard/vscode");
      }
    }));
    return [oToolbar, oEditor];
  });
  if (hasMultiCe) {
    const ceWrapper = component.set(window.sap.m.IconTabBar, {
      name: [pageKey, "ceWrapper"].join("_"),
      select: (oEvent) => {
        const oItem = oEvent.getParameters().item;
        const customDataList = oItem.getCustomData();
        const oCustomData = customDataList.find(
          (each) => each.getKey() === "select_handler"
        );
        if (oCustomData) {
          const { selectHandler, tabKey } = oCustomData.getValue();
          if (selectHandler) {
            selectHandler({ tabKey });
          }
        }
      },
      height: "100%",
      expandable: false,
      tabDensityMode: window.sap.m.IconTabDensityMode.Compact,
      backgroundDesign: window.sap.m.BackgroundDesign.Transparent,
      items: ceKeys.map((ceKey, index) => {
        const ceObject = codeeditor[ceKey];
        const ceId = [pageKey, ceKey].join("_");
        const oCe = ceInstances[index];
        return component.set(window.sap.m.IconTabFilter, {
          name: `${ceId}_tab_filter`,
          key: ceId,
          text: ceObject.title,
          customData: customData.set({
            name: `${ceId}_select_handler`,
            key: "select_handler",
            value: { selectHandler: ceObject.onSelect, tabKey: ceId }
          }),
          content: oCe,
          callback: (comp) => {
            if (ceObject.selected === true) {
              const oTabBar = comp.getParent();
              oTabBar.setSelectedKey(ceId);
            }
          }
        });
      }),
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("noBottomLine");
          comp.addStyleClass("ft-height-100-for-codeeditor");
          comp.setLayoutData(
            component.set(window.sap.m.FlexItemData, {
              name: `${pageKey}_ce_layout`,
              growFactor: 1
            })
          );
        }
      }
    });
    return [ceWrapper];
  } else {
    return ceInstances[0];
  }
}
