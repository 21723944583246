var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { FlexBox } from "@/components";
import { isObject } from "@bsgp/lib-core";
import EditorToolbarBuilder from "./EditorToolbarBuilder";
import CodeEditor from "@/components/editors/CodeEditor";
import { useRefineEventForCodeEditor } from "@/hooks/refineProps";
import { useCodeEditorContext } from "@/contexts/codeEditorContext";
function CodeEditorBuilder(props) {
  const {
    buttons,
    value,
    ceId,
    properties,
    options = {},
    showCustomOption
  } = props;
  const {
    selectedOptions,
    isFullScreen,
    handleFullScreen
  } = useCodeEditorContext();
  const refinedProperties = useRefineEventForCodeEditor(
    properties,
    options.doNotRefine
  );
  const codeEditorButtons = Array.isArray(buttons) ? buttons : isObject(buttons) ? [buttons] : [];
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    EditorToolbarBuilder,
    {
      buttons: codeEditorButtons,
      ceId,
      isFullScreen,
      handleFullScreen,
      showCustomOption
    }
  ), /* @__PURE__ */ React.createElement(FlexBox, { fitContainer: true }, /* @__PURE__ */ React.createElement(
    CodeEditor,
    {
      properties: refinedProperties,
      value,
      options: __spreadValues({
        tabSize: 2,
        insertSpaces: true
      }, selectedOptions)
    }
  )));
}
export default CodeEditorBuilder;
