var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useMemo } from "react";
import { isFalsy, tryit } from "@bsgp/lib-core";
import FormIterator from "../form/FormIterator";
import TableIterator from "../table/TableIterator";
import CodeEditorIterator from "../codeEditor/Iterator";
import NodeEditorIterator from "../nodeEditor/Iterator";
import SectionIterator from "../section/Iterator";
import { MetaSorter } from "@/lib/metaSorter";
const MainContentBuilder = (props) => {
  const _a = props, { data } = _a, rest = __objRest(_a, ["data"]);
  const sorter = new MetaSorter(data);
  const locateInfo = useMemo(
    () => sorter.getOrderedLayout(),
    [data]
  );
  if (!data.form && !data.table && !data.codeeditor && !data.nodeeditor && !data.section) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, locateInfo.map((component) => {
    if (!component.data || isFalsy(component.data)) return null;
    const firstKey = component.data[0].name;
    switch (component.layoutType) {
      case "form":
        return /* @__PURE__ */ React.createElement(
          FormIterator,
          __spreadValues({
            key: `constructor-form-${firstKey}`,
            renderData: component.data,
            wrapForms: props.data.wrapForms
          }, props.data.formOptions)
        );
      case "table":
        return /* @__PURE__ */ React.createElement(
          TableIterator,
          __spreadProps(__spreadValues({}, rest), {
            key: `constructor-table-${firstKey}`,
            renderData: component.data,
            doWrapTables: tryit(() => props.data.doWrapTables)
          })
        );
      case "codeeditor":
        return /* @__PURE__ */ React.createElement(
          CodeEditorIterator,
          __spreadProps(__spreadValues({}, rest), {
            renderData: component.data,
            key: `constructor-codeeditor-${firstKey}`
          })
        );
      case "nodeeditor":
        return /* @__PURE__ */ React.createElement(
          NodeEditorIterator,
          __spreadProps(__spreadValues({}, rest), {
            renderData: component.data,
            key: `constructor-codeeditor-${firstKey}`
          })
        );
      case "section":
        return /* @__PURE__ */ React.createElement(
          SectionIterator,
          __spreadProps(__spreadValues({}, rest), {
            renderData: component.data,
            key: `constructor-section-${firstKey}`
          })
        );
      default:
        return null;
    }
  }));
};
export default MainContentBuilder;
