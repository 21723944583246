var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var controller_exports = {};
__export(controller_exports, {
  getAllList: () => getAllList,
  moveToBuilder: () => moveToBuilder,
  moveToBuilderWithPath: () => moveToBuilderWithPath,
  moveToRenderer: () => moveToRenderer,
  onSortTable: () => onSortTable
});
module.exports = __toCommonJS(controller_exports);
var import_lib_core = require("@bsgp/lib-core");
var import_form_and_table = require("@bsgp/form-and-table");
var import_slice = require("./slice");
var import_lib_api = require("@bsgp/lib-api");
const endPoint = "/g/lc_ui5";
const onSortTable = ({ getProps }) => ({ sorter }) => {
  const { _dispatch, _state } = getProps();
  const items = _state.viewer.list;
  items.sort(sorter);
  _dispatch(import_slice.viewerActions._updatedList({ editedList: items }));
};
const getAllList = ({ getProps }) => () => {
  const { _dispatch } = getProps();
  try {
    import_lib_api.apiHub.get(
      endPoint,
      { id: "*" },
      {
        afterSucceed: (res) => {
          const editedList = res.list;
          editedList.sort((0, import_lib_core.createSorter)(["updatedAt"], "desc"));
          _dispatch(import_slice.viewerActions._updatedList({ editedList }));
        },
        afterFailed: (err) => {
          console.log("\uC2E4\uD328", err);
        }
      }
    );
  } catch (err) {
    console.log("err", err);
  }
};
const moveToBuilder = ({ getProps }) => (metaState) => (oEvent) => __async(void 0, null, function* () {
  const { history, path } = getProps();
  history.push(`${path}/builder/create`);
});
const moveToBuilderWithPath = ({ getProps }) => () => (oEvent) => {
  const { history, path } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  history.push([`${path}/builder`, value].join("/"));
};
const moveToRenderer = ({ getProps }) => () => (oEvent) => {
  const { history, path } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  history.push(`${path}${value}`);
};
