var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import ExampleTheme from "./theme";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import React from "react";
import { createUseStyles } from "react-jss";
import { OnBlurPlugin } from "./plugins/OnBlurPlugin";
function RichTextEditor(props) {
  const { value, properties, namespace, style = {} } = props;
  const isEditable = properties.editable !== false;
  const handleChange = (editorState) => {
    const newValue = editorState.toJSON();
    const changeFunc = props.onChange || properties.onChange;
    changeFunc({ value: newValue });
  };
  const classes = useStyles();
  const initialState = typeof value === "object" ? JSON.stringify(value) : value;
  return /* @__PURE__ */ React.createElement(
    LexicalComposer,
    {
      initialConfig: __spreadProps(__spreadValues({}, editorConfig), {
        editable: isEditable,
        namespace,
        editorState: initialState
      })
    },
    /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classes["editor-container"],
        style,
        "data-cy": "rich-text-editor"
      },
      isEditable && /* @__PURE__ */ React.createElement(ToolbarPlugin, null),
      /* @__PURE__ */ React.createElement("div", { className: classes["editor-inner"] }, /* @__PURE__ */ React.createElement(
        RichTextPlugin,
        {
          contentEditable: /* @__PURE__ */ React.createElement(
            ContentEditable,
            {
              className: classes["editor-input"],
              "aria-placeholder": placeholder,
              "data-cy": "rich-text-editor-input"
            }
          ),
          placeholder: isEditable && /* @__PURE__ */ React.createElement("div", { className: classes["editor-placeholder"] }, placeholder),
          ErrorBoundary: LexicalErrorBoundary
        }
      ), /* @__PURE__ */ React.createElement(HistoryPlugin, null), isEditable && /* @__PURE__ */ React.createElement(OnBlurPlugin, { onBlur: handleChange }))
    )
  );
}
const useStyles = createUseStyles({
  "editor-container": {
    width: "100%",
    margin: "auto",
    color: "#000",
    position: "relative",
    lineHeight: "20px",
    fontWeight: "400",
    textAlign: "left",
    border: "1px solid rgb(137, 145, 154)",
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  "editor-inner": {
    background: "#fff",
    position: "relative",
    flex: "1",
    "& .editor-text-bold": {
      fontWeight: "bold"
    },
    "& .editor-text-italic": {
      fontStyle: "italic"
    },
    "& .editor-text-underline": {
      textDecoration: "underline"
    },
    "& .editor-text-strikethrough": {
      textDecoration: "line-through"
    },
    "& .editor-text-underlineStrikethrough": {
      textDecoration: "underline line-through"
    },
    "& .editor-paragraph": {
      margin: "0",
      marginBottom: "8px",
      position: "relative",
      "&:last-child": {
        marginBottom: "0"
      }
    },
    "& .editor-heading-h1": {
      fontSize: "24px",
      color: "rgb(5, 5, 5)",
      fontWeight: "400",
      margin: "0",
      marginBottom: "12px",
      padding: "0"
    },
    "& .editor-heading-h2": {
      fontSize: "15px",
      color: "rgb(101, 103, 107)",
      fontWeight: "700",
      margin: "0",
      marginTop: "10px",
      padding: "0",
      textTransform: "uppercase"
    },
    "& .editor-quote": {
      margin: "0",
      marginLeft: "20px",
      fontSize: "15px",
      color: "rgb(101, 103, 107)",
      borderLeftColor: "rgb(206, 208, 212)",
      borderLeftWidth: "4px",
      borderLeftStyle: "solid",
      paddingLeft: "16px"
    },
    "& .editor-list-ol": {
      padding: "0",
      margin: "0",
      marginLeft: "16px"
    },
    "& .editor-list-ul": {
      padding: "0",
      margin: "0",
      marginLeft: "16px"
    },
    "& .editor-listitem": {
      margin: "8px 32px 8px 32px"
    },
    "& .editor-nested-listitem": {
      listStyleType: "none"
    }
  },
  "editor-input": {
    height: "100%",
    resize: "none",
    fontSize: "15px",
    caretColor: "#444",
    position: "relative",
    tabSize: "1",
    outline: "0",
    padding: "15px 10px"
  },
  "editor-placeholder": {
    color: "#999",
    overflow: "hidden",
    position: "absolute",
    textOverflow: "ellipsis",
    top: "15px",
    left: "10px",
    fontSize: "15px",
    userSelect: "none",
    display: "inline-block",
    pointerEvents: "none"
  }
  // pre::-webkit-scrollbar: {
  //   background: "transparent",
  //   width: "10px"
  // },
  // pre::-webkit-scrollbar-thumb: {
  //   background: "#999"
  // },
});
const placeholder = "\uB0B4\uC6A9\uC744 \uC785\uB825\uD558\uC138\uC694.";
const editorConfig = {
  nodes: [],
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // The editor theme
  theme: ExampleTheme
};
export default RichTextEditor;
