var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { clone } from "@bsgp/lib-core";
import { isFunction } from "@bsgp/lib-core";
import { removeUndefinedKeys } from "@bsgp/form-and-table";
import {
  componentNameMap,
  dialogEventPropertyMap,
  eventPropertyMap
} from "@/maps/legacyMap";
const getLegacyFieldName = (type) => {
  return componentNameMap[type] || "sap.m.Input";
};
const getLegacyEventFields = (event) => {
  return {
    getSource: () => {
      return {
        getMetadata: () => {
          var _a, _b;
          if ((_b = (_a = event == null ? void 0 : event.target) == null ? void 0 : _a.dataset) == null ? void 0 : _b.type) {
            const { type } = event.target.dataset;
            return {
              getName: () => getLegacyFieldName(type)
            };
          }
        },
        getSelectedItem: () => {
          return { getKey: () => "key" };
        }
      };
    },
    getParameters: () => {
      const value = event.target.value;
      return {
        value,
        id: event.target.id,
        newValue: value
      };
    }
  };
};
const cloneEventAndAddLegacyFields = (event) => {
  if (!event) return null;
  const newEvent = {};
  for (const field in event) {
    newEvent[field] = clone(event[field]);
  }
  const legacyFields = getLegacyEventFields(event);
  for (const legacyField in legacyFields) {
    if (!newEvent[legacyField]) {
      newEvent[legacyField] = clone(legacyFields[legacyField]);
    }
  }
  Object.setPrototypeOf(newEvent, event);
  return newEvent;
};
const convertEventHandler = (props) => {
  const {
    shouldSkipLegacyFields,
    curEventHandler,
    shouldStoreInOEvent,
    extraParams
  } = props;
  return (event) => {
    const newEvent = shouldSkipLegacyFields ? event : cloneEventAndAddLegacyFields(event);
    return curEventHandler(
      shouldStoreInOEvent ? __spreadProps(__spreadValues({ oEvent: newEvent }, extraParams), { renderAsReact: true }) : __spreadProps(__spreadValues(__spreadValues({}, newEvent), extraParams), { renderAsReact: true })
    );
  };
};
const getMappedEventName = (shouldUseDialogEventMap, key) => {
  const eventMap = shouldUseDialogEventMap ? dialogEventPropertyMap : eventPropertyMap;
  return eventMap[key];
};
const refineEvent = (properties, fn, extraParams = {}, flags = {}) => {
  const {
    shouldSkipLegacyFields,
    shouldStoreInOEvent,
    shouldUseDialogEventMap
  } = flags;
  if (!properties) return properties;
  return removeUndefinedKeys(
    Object.keys(properties).reduce((acc, key) => {
      acc[key] = properties[key];
      if (isFunction(properties[key])) {
        const curEventHandler = properties[key](fn);
        const newEventHandler = convertEventHandler({
          shouldSkipLegacyFields,
          curEventHandler,
          shouldStoreInOEvent,
          extraParams
        });
        acc[key] = newEventHandler;
        const newKey = getMappedEventName(shouldUseDialogEventMap, key);
        if (newKey && !acc[newKey]) {
          acc[newKey] = newEventHandler;
        }
      }
      if (key === "valueHelpV2") {
        const refinedVal = refineEvent(properties[key], fn, extraParams, __spreadProps(__spreadValues({}, flags), {
          shouldUseDialogEventMap: true
        }));
        acc[key] = refinedVal;
      }
      return acc;
    }, {})
  );
};
function refineEventForDialog(properties = {}, fn, items = []) {
  return refineEvent(
    properties,
    fn,
    { items },
    {
      shouldUseDialogEventMap: true
    }
  );
}
export { refineEvent, refineEventForDialog };
