var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { DateRangePicker } from "@ui5/webcomponents-react";
import { isDate } from "@bsgp/lib-core";
const FtDateRangePicker = (props) => {
  const { value, properties, displayFormat, format, style } = props;
  const formatPattern = displayFormat || format || "yyyy-MM-dd";
  return /* @__PURE__ */ React.createElement(
    DateRangePicker,
    __spreadProps(__spreadValues({}, properties), {
      formatPattern,
      value: refineValue(value),
      style
    })
  );
};
function refineValue(value) {
  if (Array.isArray(value)) {
    if (value.filter(Boolean).length === 0) return "";
    return value.map((val) => {
      if (isDate(val)) {
        return isoToDateOnly(val);
      }
      return val;
    }).join("-");
  }
  return value;
}
function isoToDateOnly(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
export default FtDateRangePicker;
