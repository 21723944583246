var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import ToolbarComponentBuilder from "@/builders/common/ToolbarComponentBuilder";
import clsx from "clsx";
import { formRef } from "@/ref";
const FooterComponentsBuilder = (props) => {
  const { renderData, showToggleBtn } = props;
  const [isFormCollapsed, setIsFormCollapsed] = useState(false);
  return /* @__PURE__ */ React.createElement("div", { style: { display: "flex", gap: "0.5rem" } }, renderData.map((each) => {
    return /* @__PURE__ */ React.createElement(
      ToolbarComponentBuilder,
      __spreadValues(__spreadValues({
        name: each.name,
        key: each.name
      }, each.field), each.field.component)
    );
  }), showToggleBtn && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    ToolbarComponentBuilder,
    {
      type: "Separator",
      value: "",
      name: "toggle_forms_separator"
    }
  ), /* @__PURE__ */ React.createElement(
    ToolbarComponentBuilder,
    {
      type: "Button",
      value: "",
      name: "toggle_forms",
      properties: {
        className: isFormCollapsed ? "ft-notify-form-is-hidden" : "",
        icon: isFormCollapsed ? "sap-icon://expand-group" : "sap-icon://collapse-group",
        onClick: () => (oEvent) => {
          const oldClassName = formRef.current.className;
          if (isFormCollapsed) {
            const result = oldClassName.replace(
              "ft-hide-with-effect",
              ""
            );
            formRef.current.className = result;
            setIsFormCollapsed((now) => !now);
          } else {
            formRef.current.className = clsx(oldClassName, {
              "ft-hide-with-effect": true
            });
            setIsFormCollapsed((now) => !now);
          }
        }
      }
    }
  )));
};
export default FooterComponentsBuilder;
