import React, { createContext, useState, createRef, useContext } from "react";
export const DialogContext = createContext({
  openDialogIds: [],
  valueHelpV2Event: {},
  selectedRows: []
});
export const refSetterOpenDialogIds = createRef();
export const refSetterValueHelpV2Event = createRef();
export const refSetterSelectedRows = createRef();
export const DialogContextProvider = ({ children }) => {
  const [openDialogIds, setOpenDialogIds] = useState([]);
  const [valueHelpV2Event, setValueHelpV2Event] = useState(
    {}
  );
  const [selectedRows, setSelectedRows] = useState([]);
  refSetterOpenDialogIds.current = setOpenDialogIds;
  refSetterValueHelpV2Event.current = setValueHelpV2Event;
  refSetterSelectedRows.current = setSelectedRows;
  return /* @__PURE__ */ React.createElement(
    DialogContext.Provider,
    {
      value: {
        openDialogIds,
        valueHelpV2Event,
        selectedRows
      }
    },
    children
  );
};
export const useDialogContext = () => useContext(DialogContext);
