import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from "lexical";
export function OnBlurPlugin({ onBlur }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerCommand(
      BLUR_COMMAND,
      (payload, editor2) => {
        const editorState = editor2.getEditorState();
        onBlur(editorState);
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, onBlur]);
  return null;
}
