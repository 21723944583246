var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { TabContainer, Tab } from "@/components";
import { TabContainerBackgroundDesign } from "@ui5/webcomponents-react";
import clsx from "clsx";
function BaseIterator({
  items,
  itemNameList,
  wrapItems,
  contextProvider: ContextProvider,
  tabContainerProps = {
    className: ""
  },
  children,
  tabProps = () => ({})
}) {
  const _a = tabContainerProps, { className } = _a, tabContainerRest = __objRest(_a, ["className"]);
  const Wrapper = ContextProvider || React.Fragment;
  return wrapItems ? /* @__PURE__ */ React.createElement(
    TabContainer,
    __spreadValues({
      fixed: true,
      onTabSelect: (oEvent) => {
        const selectedIndex = oEvent.detail.tabIndex;
        const selectHandler = items[selectedIndex].onSelect || items[selectedIndex].onSelectTab;
        if (selectHandler) {
          const selectedKey = itemNameList[selectedIndex];
          selectHandler({ tabKey: selectedKey });
        }
      },
      contentBackgroundDesign: TabContainerBackgroundDesign.Transparent,
      className: clsx("noBottomLine", className)
    }, tabContainerRest),
    items.map((item, index) => {
      const key = itemNameList[index];
      return /* @__PURE__ */ React.createElement(
        Tab,
        __spreadValues({
          key,
          text: item.title,
          selected: item.selected
        }, tabProps(item)),
        /* @__PURE__ */ React.createElement(Wrapper, null, children(item, key))
      );
    })
  ) : /* @__PURE__ */ React.createElement(Wrapper, null, items.map((item, index) => children(item, itemNameList[index])));
}
export default BaseIterator;
