import React from "react";
import { Handle, Position } from "reactflow";
function CustomNode(props) {
  const { isConnectable, id } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "react-flow__node-default" }, /* @__PURE__ */ React.createElement(
    Handle,
    {
      type: "target",
      position: Position.Left,
      isConnectable
    }
  ), id, /* @__PURE__ */ React.createElement(
    Handle,
    {
      type: "source",
      position: Position.Right,
      isConnectable
    }
  ));
}
export default CustomNode;
