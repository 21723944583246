var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { MultiInput, Token } from "@ui5/webcomponents-react";
const FtMultiInput = (props) => {
  const { items } = props;
  const itemList = items.list || [];
  const tokens = getTokenItems(itemList);
  return /* @__PURE__ */ React.createElement(MultiInput, __spreadProps(__spreadValues({}, props), { tokens: /* @__PURE__ */ React.createElement(React.Fragment, null, tokens), showSuggestions: true }));
};
function getTokenItems(itemList) {
  return itemList.map((itemText, index) => {
    return /* @__PURE__ */ React.createElement(Token, { text: itemText, key: `${index}-${itemText}` });
  });
}
export default FtMultiInput;
