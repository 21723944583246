var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useState } from "react";
import Icon from "@/components/Icon";
import { useTableContext } from "@/contexts/tableContext";
import { handleFilter } from "@/lib/utils";
import Input from "@/components/inputs/Input";
const SearchField = (_a) => {
  var _b = _a, { name } = _b, rest = __objRest(_b, ["name"]);
  const [searchValue, setSearchValue] = useState("");
  const { tableData, setFilteredData, setPage } = useTableContext();
  const handleSearch = () => {
    const filteredTableData = handleFilter({
      targetArray: tableData,
      filteringValue: searchValue
    });
    setFilteredData(filteredTableData);
    setPage(0);
  };
  return /* @__PURE__ */ React.createElement(
    Input,
    __spreadProps(__spreadValues({}, rest), {
      name: `${name}_header_toolbar_content_searchHandler`,
      properties: {
        placeholder: "\uACB0\uACFC \uB0B4 \uAC80\uC0C9",
        icon: /* @__PURE__ */ React.createElement(Icon, { name: "search", interactive: true, onClick: handleSearch }),
        onInput: (event) => {
          const currentTarget = event.currentTarget;
          const searchValue2 = currentTarget.value;
          setSearchValue(searchValue2);
        }
      },
      style: {
        width: "10rem"
      },
      onSubmit: handleSearch
    })
  );
};
export default SearchField;
