var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { MessageStrip, MessageStripDesign } from "@ui5/webcomponents-react";
import { messageStripDesignMap } from "@/maps/legacyMap";
const FtMessageStrip = (props) => {
  const { value, properties = {} } = props;
  return /* @__PURE__ */ React.createElement(
    MessageStrip,
    __spreadValues({
      design: messageStripDesignMap[properties.type] || properties.type || MessageStripDesign.Information
    }, props),
    value
  );
};
export default FtMessageStrip;
