var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { TableCell } from "@/components";
import { getNewName } from "@bsgp/form-and-table";
import CellBuilder from "./CellBuilder";
function TableCellBuilder(props) {
  const { name, columnProperties, rowData } = props;
  const { columns } = columnProperties;
  return columns.map((col, idx) => {
    const value = rowData[col.name] || rowData[col.value];
    const cellName = getNewName([name, col.name].join("-"));
    const hAlign = col.properties.hAlign || "Left";
    return /* @__PURE__ */ React.createElement(
      TableCell,
      __spreadProps(__spreadValues({}, props), {
        name,
        key: `${cellName}-TableCell-${idx}`,
        style: {
          textAlign: hAlign.toLowerCase()
        }
      }),
      /* @__PURE__ */ React.createElement(
        CellBuilder,
        {
          column: col,
          value,
          cellName,
          rowData
        }
      )
    );
  });
}
export default TableCellBuilder;
