var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { memo } from "react";
import DialogV2Builder from "./DialogV2Builder";
import BaseIterator from "../common/BaseIterator";
const DialogV2BuildIterator = (props) => {
  const { renderData } = props;
  const dialogNameList = renderData.map((dialog) => dialog.name);
  return /* @__PURE__ */ React.createElement(
    BaseIterator,
    {
      items: renderData,
      itemNameList: dialogNameList,
      wrapItems: false
    },
    (item, key) => /* @__PURE__ */ React.createElement(DialogV2Builder, __spreadProps(__spreadValues({ key: `dialog-${key}` }, item), { dialogId: key }))
  );
};
export default memo(DialogV2BuildIterator);
