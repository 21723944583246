var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var builder_exports = {};
__export(builder_exports, {
  default: () => builder_default
});
module.exports = __toCommonJS(builder_exports);
var import_react = __toESM(require("react"));
var import_toolkit = require("@reduxjs/toolkit");
var import_react_router_dom = require("react-router-dom");
var import_form_and_table = require("@bsgp/form-and-table");
var controller = __toESM(require("./controller"));
var import_slice = require("./slice");
var import_lib_core = require("@bsgp/lib-core");
var import_modules = require("./modules");
var import_mods = require("../mods");
const Builder = (props) => {
  var _a;
  const { _state, _dispatch, match, isCreate } = props;
  const { meta } = _state;
  const {
    headers,
    forms,
    tables,
    dialogs,
    extra,
    functions,
    codeeditors,
    nodeeditors,
    sections
  } = meta;
  const { dialog } = extra;
  const builder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const dialogBuilder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const codeBuilder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const createVersionBuilder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const moveBuilder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const deployBuilder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const exportBuilder = (0, import_form_and_table.formTable)({ wrapSingleTable: true });
  const errorNotice = {
    valueState: "Error",
    valueStateText: "\uC22B\uC790\uC640 \uC601\uBB38 \uB300\uC18C\uBB38\uC790 \uBC0F _ . : - \uD2B9\uC218\uAE30\uD638\uB9CC \uC785\uB825 \uAC00\uB2A5\uD558\uBA70, \uCCAB \uAE00\uC790\uB294 \uC601\uBB38\uACFC _ \uAE30\uD638\uB9CC \uC785\uB825 \uAC00\uB2A5\uD569\uB2C8\uB2E4."
  };
  const requiredNotice = {
    valueState: "Information",
    valueStateText: "\uBC18\uB4DC\uC2DC \uC785\uB825\uD574\uC57C \uD558\uB294 \uD544\uC218\uAC12\uC785\uB2C8\uB2E4"
  };
  (0, import_react.useEffect)(() => {
    if (isCreate) {
    } else {
      _dispatch(
        import_slice.builderActions._updateMetaProperty({
          key: "id",
          value: match.params.id
        })
      );
    }
    return () => {
      _dispatch(import_slice.builderActions._initAll());
    };
  }, []);
  (0, import_react.useEffect)(() => {
    if (match.params.id) {
      controller.getMetaData({ getProps: () => props })({
        id: match.params.id,
        isCreate
      })();
    }
  }, [match.params.id]);
  const { metaObj: currentDialog } = (0, import_modules.getTargetObj)(meta);
  const currentDialogOptions = (0, import_modules.getOptionsOnDialog)(currentDialog);
  builder.addFooterButton("saveBtn", {
    text: "Save",
    isMain: true,
    properties: {
      press: (fn) => fn.postMetaData({ isCreate }),
      icon: "sap-icon://save"
    }
  }).addFooterButton("toList", {
    text: "List",
    confirmMessage: "Are you sure to move?",
    isMain: false,
    properties: {
      press: (fn) => fn.moveToList,
      icon: "sap-icon://list"
    }
  }).addFooterButton("refresh", {
    value: "",
    isMain: false,
    icon: "sap-icon://refresh",
    properties: {
      enabled: !isCreate
    },
    onPress: (fn) => fn.getMetaData({ id: match.params.id, isCreate })
  }).addFooterAction("addFooterMain", {
    text: "Add Header",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "headers" }],
      subKey: "header"
    }),
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://header"
  }).addFooterAction("addFooterForm", {
    text: "Add Form",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "forms" }],
      subKey: "form"
    }),
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://form"
  }).addFooterAction("addFooterTable", {
    text: "Add Table",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "tables" }],
      subKey: "table"
    }),
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://table-view"
  }).addFooterAction("addDialog", {
    text: "Add Dialog",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "dialogs" }],
      subKey: "dialog"
    }),
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://popup-window"
  }).addFooterAction("addCodeEditor", {
    text: "Add CodeEditor",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "codeeditors" }],
      subKey: "codeeditor"
    }),
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://source-code"
  }).addFooterAction("addNodeEditor", {
    text: "Add NodeEditor",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "nodeeditors" }],
      subKey: "nodeeditor"
    }),
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://tree"
  }).addFooterAction("addSection", {
    text: "Add Section",
    confirmMessage: "Are you sure to add?",
    onPress: (fn) => fn.addExtraObject({
      keys: [{ key: "sections" }],
      subKey: "section"
    }),
    icon: "sap-icon://course-book"
  }).addFooterAction("addFooterInit", {
    text: "Add Init Function",
    confirmMessage: "Are you sure to add?",
    properties: {
      enabled: !meta.functions.initialization.content && !isCreate
    },
    onPress: (fn) => fn.openCodeEditorDialog("initialization")
  }).addFooterAction("addFunctions", {
    text: "Add Functions",
    confirmMessage: "Are you sure to add?",
    properties: {
      enabled: !meta.functions.global.content && !isCreate
    },
    onPress: (fn) => fn.openCodeEditorDialog("global")
  });
  builder.addForm("builderForm").addContainer("builderContainer1").addField("id", {
    label: "ID",
    value: meta.id,
    component: import_form_and_table.ft.Input({
      onChange: (fn) => fn.updateMetaProperty("id"),
      properties: {
        editable: false,
        width: "10rem"
      }
    })
  }).addField("preview", {
    label: "",
    value: [meta.extra.selectedPath, "Preview"],
    component: [
      import_form_and_table.ft.Select("paths", {
        onChange: (fn) => fn.updateMetaProperty("selectedPath", true),
        list: meta.paths.map((path) => path.origin).concat(""),
        properties: {
          width: "12rem"
        }
      }),
      import_form_and_table.ft.Button("do", {
        properties: {
          icon: "sap-icon://begin",
          enabled: !isCreate,
          type: window.sap.m.ButtonType.Neutral,
          iconFirst: false
        },
        onPress: (fn) => fn.moveToPreviewFromForm
      })
    ]
  }).addField("editFunctions", {
    label: "",
    value: ["Edit Init. Func.", "Edit Global Func."],
    vertical: true,
    component: [
      import_form_and_table.ft.Button("initialization", {
        onPress: (fn) => fn.openCodeEditorDialog("initialization"),
        properties: {
          icon: "sap-icon://edit",
          enabled: !!meta.functions.initialization.content
        }
      }),
      import_form_and_table.ft.Button("global", {
        onPress: (fn) => fn.openCodeEditorDialog("global"),
        properties: {
          icon: "sap-icon://edit",
          enabled: !!meta.functions.global.content
        }
      })
    ]
  }).addContainer("builderContainer2").addField("description", {
    label: "Description",
    value: meta.description,
    component: import_form_and_table.ft.Input({
      onChange: (fn) => fn.updateMetaProperty("description"),
      properties: {
        editable: true,
        width: "10rem",
        placeholder: "description"
      }
    })
  }).addField("wrapForms", {
    label: "Wrap Forms",
    value: meta.wrapForms === false ? false : true,
    component: import_form_and_table.ft.CheckBox({
      onChange: (fn) => fn.updateMetaProperty("wrapForms"),
      properties: {
        editable: true
      }
    })
  }).addField("renderAsReact", {
    label: "Render as React",
    value: meta.renderAsReact === false ? false : true,
    component: import_form_and_table.ft.CheckBox({
      onChange: (fn) => fn.updateMetaProperty("renderAsReact"),
      properties: {
        editable: true
      }
    })
  }).addContainer("builderContainer3").addField("errorMessage", {
    label: void 0,
    value: meta.extra.errorMessage || meta.extra.message || "Good",
    component: import_form_and_table.ft.Text({
      colorful: true,
      properties: {
        type: meta.extra.errorMessage ? window.sap.ui.core.MessageType.Error : window.sap.ui.core.MessageType.Success
      }
    })
  }).addField("copyToDev", {
    label: void 0,
    value: "<<<<< Copy Meta To Dev >>>>>",
    component: import_form_and_table.ft.Button({
      onPress: (fn) => fn.onCopyToDev,
      properties: {
        enabled: process.env.REACT_APP_API_STAGE_NAME !== "dev"
      }
    })
  });
  builder.addTable("paths", { title: "Paths" }).addToolbarButton("addPath", {
    icon: "sap-icon://add",
    text: "Path \uCD94\uAC00",
    properties: {
      enabled: !isCreate
    },
    onPress: (fn) => fn.onAddPath
  }).addColumn("skid", {
    text: "ID",
    width: "5rem",
    component: import_form_and_table.ft.Text()
  }).addColumn("origin", {
    width: "15rem",
    text: "Path",
    component: import_form_and_table.ft.Input({
      onChange: (fn) => fn.onUpdatePath("origin")
    })
  }).addColumn("mode", {
    width: "5rem",
    text: "Mode",
    component: import_form_and_table.ft.Select({
      onChange: (fn) => fn.onUpdatePath("mode"),
      list: [""].concat(Object.keys(import_mods.MODES))
    })
  }).addColumn("title", {
    text: "Title",
    component: import_form_and_table.ft.Input({
      onChange: (fn) => fn.onUpdatePath("title")
    })
  }).addColumn("previewBtn", {
    width: "5rem",
    text: "",
    component: import_form_and_table.ft.Button({
      properties: {
        text: "Preview"
      },
      onPress: (fn) => fn.moveToPreview
    })
  }).addItems(meta.paths);
  builder.addTable("versions", { title: "Versions" }).addToolbarButton("createVersion", {
    value: "Create Version",
    properties: {
      enabled: !isCreate
    },
    icon: "sap-icon://add-document",
    onPress: (fn) => fn.toggleCreateVersionDialog(true)
  }).addColumn("lstvsn", {
    text: "ID",
    width: "10rem"
  }).addColumn("deployed", {
    width: "5rem",
    text: "Deployed",
    component: import_form_and_table.ft.CheckBox({
      properties: {
        enabled: false
      }
    })
  }).addColumn("preDeployed", {
    width: "5rem",
    text: "Pre-Deployed",
    component: import_form_and_table.ft.CheckBox({
      properties: {
        enabled: false
      }
    })
  }).addColumn("deployBtn", {
    width: "7rem",
    text: "",
    vertical: true,
    component: [
      import_form_and_table.ft.Button("deploy", {
        properties: {
          text: "Deploy",
          enabled: ["{= ", "!$", "{deployed}", " }"].join("")
        },
        confirmMessage: "Are you sure to deploy?",
        onPress: (fn) => fn.deployVersion
      }),
      import_form_and_table.ft.Button("preDeploy", {
        properties: {
          text: "Pre-Deploy",
          enabled: [
            "{= ",
            "!$",
            "{deployed}",
            " && ",
            "!$",
            "{disallowPreDeploy}",
            " }"
          ].join("")
        },
        onPress: (fn) => fn.toggleDeployDialog(true)
      })
    ]
  }).addColumn("description", {
    text: "Description"
  }).addItems(meta.versions);
  if ((0, import_lib_core.isTruthy)(forms)) {
    forms.forEach((curForm, curFormIdx) => {
      (0, import_modules.addMetaTable)(builder, curForm, curFormIdx, "forms");
    });
  }
  if ((0, import_lib_core.isTruthy)(tables)) {
    tables.forEach((curTable, curTableIdx) => {
      (0, import_modules.addMetaTable)(builder, curTable, curTableIdx, "tables");
    });
  }
  if ((0, import_lib_core.isTruthy)(headers)) {
    headers.forEach((curHeader, curHeaderIdx) => {
      (0, import_modules.addMetaTable)(builder, curHeader, curHeaderIdx, "headers");
    });
  }
  if ((0, import_lib_core.isTruthy)(dialogs)) {
    dialogs.forEach((curDialog, curDialogIdx) => {
      (0, import_modules.addMetaTable)(builder, curDialog, curDialogIdx, "dialogs");
    });
  }
  if ((0, import_lib_core.isTruthy)(codeeditors)) {
    codeeditors.forEach((curCode, curCodeIdx) => {
      (0, import_modules.addMetaTable)(builder, curCode, curCodeIdx, "codeeditors");
    });
  }
  if ((0, import_lib_core.isTruthy)(nodeeditors)) {
    nodeeditors.forEach((curNode, curNodeIdx) => {
      (0, import_modules.addMetaTable)(builder, curNode, curNodeIdx, "nodeeditors");
    });
  }
  if ((0, import_lib_core.isTruthy)(sections)) {
    sections.forEach((curSec, curSecIdx) => {
      (0, import_modules.addMetaTable)(builder, curSec, curSecIdx, "sections");
    });
  }
  if (dialog.isOpen) {
    const { metaObj: dialogObj, parentObj, lastIndex } = (0, import_modules.getTargetObj)(
      meta,
      dialog.target.keys
    );
    const { metaArray } = (0, import_modules.getTargetArray)(meta, dialog.target.keys);
    dialogBuilder.addForm("dialogForm").addContainer("dialogContainer").addField("dialogKey", {
      label: "Key",
      value: [dialogObj.key, dialogObj.ignore],
      component: [
        import_form_and_table.ft.Input("key", {
          properties: __spreadValues({}, ((_a = dialog.invalid) == null ? void 0 : _a.key) ? errorNotice : requiredNotice),
          onChange: (fn) => fn.updateRequiredKey("key")
        }),
        import_form_and_table.ft.CheckBox("ignore", {
          text: "ignore",
          onChange: (fn) => fn.updateRequiredKey("ignore")
        })
      ]
    });
    if (["buttons", "toolbars"].includes(dialog.target.type)) {
      dialogBuilder.addField("isAction", {
        label: "Is Action",
        value: dialogObj.isAction,
        component: import_form_and_table.ft.CheckBox({
          onChange: (fn) => fn.updateRequiredKey("isAction")
        })
      });
    }
    if (["tables"].includes(dialog.target.type)) {
      dialogBuilder.addField("isSearch", {
        label: "Add SearchField",
        value: dialogObj.isSearch,
        component: import_form_and_table.ft.CheckBox({
          onChange: (fn) => fn.updateRequiredKey("isSearch")
        })
      });
    }
    if (["fields"].includes(dialog.target.type)) {
      dialogBuilder.addField("label", {
        label: "Label",
        value: dialogObj.label,
        component: import_form_and_table.ft.Input({
          onChange: (fn) => fn.updateRequiredKey("label")
        })
      });
    }
    if (["buttons", "columns", "toolbars", "links"].includes(dialog.target.type)) {
      dialogBuilder.addField("text", {
        label: "Text",
        value: dialogObj.text,
        component: import_form_and_table.ft.Input({
          onChange: (fn) => fn.updateRequiredKey("text")
        })
      });
    }
    if ([
      "forms",
      "containers",
      "tables",
      "dialogs",
      "codeeditors",
      "nodeeditors",
      "sections"
    ].includes(dialog.target.type)) {
      dialogBuilder.addField("title", {
        label: "Title",
        value: dialogObj.title,
        component: import_form_and_table.ft.Input({
          onChange: (fn) => fn.updateRequiredKey("title")
        })
      });
    }
    dialogBuilder.addContainer("c2");
    if (["sections"].includes(dialog.target.type)) {
      dialogBuilder.addField("locateTargetKey", {
        label: "Locate Target Key",
        value: dialogObj.locateTargetKey,
        component: import_form_and_table.ft.ComboBox({
          list: (0, import_modules.getOtherContentKeys)(
            parentObj,
            dialogObj.key,
            dialog.target.type
          ),
          onChange: (fn) => fn.updateRequiredKey("locateTargetKey")
        })
      });
      dialogBuilder.addField("locateWhere", {
        label: "locateWhere",
        value: dialogObj.locateWhere,
        component: import_form_and_table.ft.ComboBox({
          list: ["Below", "Above"],
          onChange: (fn) => fn.updateRequiredKey("locateWhere")
        })
      });
    }
    if (["forms"].includes(dialog.target.type)) {
      dialogBuilder.addField("default", {
        label: "Default",
        value: dialogObj.default,
        component: import_form_and_table.ft.CheckBox({
          onChange: (fn) => fn.updateRequiredKey("default")
        })
      });
    }
    if ([
      "fields",
      "containers",
      "columns",
      "components",
      "buttons",
      "links"
    ].includes(dialog.target.type)) {
      dialogBuilder.addField("index", {
        label: "Index",
        value: lastIndex,
        component: import_form_and_table.ft.Input({
          properties: {
            width: "3rem",
            type: window.sap.m.InputType.Number
          },
          onChange: (fn) => fn.onUpdateContainerIndex({
            maxIndex: metaArray.length - 1,
            currentIndex: lastIndex
          })
        })
      });
      const { list: parentObjects, parentType } = (0, import_modules.getParentObjects)(
        meta,
        dialog.target.keys
      );
      dialogBuilder.addField("move", {
        label: "Move to",
        value: "",
        component: import_form_and_table.ft.Select({
          properties: {},
          combine: true,
          list: parentObjects.filter((each) => each.key !== parentObj.key).map((each) => ({ key: each.key, text: parentType })).concat([""]),
          onChange: (fn) => fn.onMoveObjectToAnotherParent
        })
      });
    }
    if (["forms", "tables"].includes(dialog.target.type) && dialog.target.keys[0] && dialog.target.keys[0].key === "dialogs") {
      dialogBuilder.addField("moveOutside", {
        label: "",
        value: "Move Outside",
        component: import_form_and_table.ft.Button({
          properties: {
            icon: "sap-icon://outdent",
            width: "9rem"
          },
          onPress: (fn) => fn.toggleMoveDialog(true)
        })
      });
    }
    if (["components"].includes(dialog.target.type)) {
      dialogBuilder.addField("type", {
        label: "Component",
        value: dialogObj.type,
        component: import_form_and_table.ft.ComboBox({
          list: import_modules.ComponentsList,
          onChange: (fn) => fn.updateRequiredKey("type")
        })
      });
    }
    if (["sections"].includes(dialog.target.type)) {
      dialogBuilder.addField("type", {
        label: "Section",
        value: dialogObj.type,
        component: import_form_and_table.ft.ComboBox({
          list: import_modules.SectionsList,
          onChange: (fn) => fn.updateRequiredKey("type")
        })
      });
    }
    if (["fields", "columns"].includes(dialog.target.type)) {
      dialogBuilder.addField("binding", {
        label: "Binding",
        value: dialogObj.binding,
        component: import_form_and_table.ft.Input({
          properties: {},
          onChange: (fn) => fn.updateRequiredKey("binding")
        })
      });
    }
    if (["dialogs"].includes(dialog.target.type)) {
      dialogBuilder.addField("metaId", {
        label: "Meta ID",
        value: dialogObj.metaId,
        component: import_form_and_table.ft.Input({
          valueHelpV2: {
            dialogId: "metaList",
            onRequest: (fn) => fn.queryAllMeta,
            onConfirm: (fn) => fn.selectMeta
          },
          onChange: (fn) => fn.updateRequiredKey("metaId")
        })
      });
      builder.addDialog("metaList", {
        properties: {
          title: "Meta List"
        },
        ftData: (0, import_form_and_table.formTable)().addTable("metaList", { usePagination: true, mode: "S" }).addToolbarSearch().addColumn("id", { text: "ID" }).addColumn("description", { text: "Description" }).addItems(meta.extra.metaList.filter((each) => each.id !== meta.id)).done()
      });
    }
    const modifiedOptions = (0, import_modules.getMixedOptions)(currentDialogOptions, {
      type: dialog.target.type,
      compType: currentDialog.type
    });
    const keyString = (0, import_modules.getCombinedKeyString)(dialog.target.keys, meta, {
      target: dialog.target,
      combineAllKeys: true
    });
    const typeList = [{ key: "", text: "" }];
    if (dialog.target.type === "buttons") {
      typeList.push({ key: "Emphasized", text: "Emphasized" });
      typeList.push({ key: "Ghost", text: "Ghost" });
    } else if (dialog.target.type === "codeeditors") {
      typeList.push({ key: "hjson", text: "hjson" });
      typeList.push({ key: "javascript", text: "javascript" });
      typeList.push({ key: "json", text: "json" });
      typeList.push({ key: "terminal", text: "terminal" });
      typeList.push({ key: "diff", text: "diff" });
      typeList.push({ key: "terraform", text: "terraform" });
      typeList.push({ key: "xml", text: "xml" });
    }
    dialogBuilder.addTable(["options", keyString].join("_"), {
      title: ""
    }).addToolbarButton("addPropsButton", {
      icon: "sap-icon://add",
      text: "option \uCD94\uAC00",
      onPress: (fn) => fn.addProperties
    }).addColumn(`key`, {
      text: "Key",
      width: "10rem",
      component: import_form_and_table.ft.ComboBox({
        list: modifiedOptions,
        onChange: (fn) => fn.updateKeyColumn
      })
    }).addColumn(`ignore`, {
      text: "ignore",
      width: "5rem",
      component: import_form_and_table.ft.CheckBox({
        onChange: (fn) => fn.updateIgnoreOption
      })
    }).addColumn(`type`, {
      text: "Type",
      width: "10rem",
      component: import_form_and_table.ft.Select({
        list: import_modules.TypeList,
        onChange: (fn) => fn.updateValueType
      })
    }).addColumn(`value`, {
      text: "Value",
      component: [
        import_form_and_table.ft.Input({
          properties: {
            width: "15rem",
            visible: [
              "{=",
              ["$", "{key}"].join(""),
              "!==",
              "'valueHelpV2-dialogId'",
              "&&",
              ["$", "{key}"].join(""),
              "!==",
              "'type'",
              "&&",
              ["$", "{key}"].join(""),
              "!==",
              "'conv'",
              "&&",
              ["$", "{type}"].join(""),
              "!==",
              "'Function'",
              "&&",
              ["$", "{type}"].join(""),
              "!==",
              "'Array'",
              "}"
            ].join("")
          },
          onChange: (fn) => fn.updateValueColumn
        }),
        import_form_and_table.ft.Button({
          properties: {
            icon: "sap-icon://edit",
            text: "edit",
            visible: ["{= $", "{type} === 'Function' }"].join(""),
            width: "15rem"
          },
          onPress: (fn) => fn.openCodeEditorDialog()
        }),
        import_form_and_table.ft.ComboBox("c", {
          multiple: true,
          list: tables.map((each) => {
            return { key: each.key, text: each.key };
          }),
          properties: {
            width: "15rem",
            visible: [
              "{= $",
              "{key} === 'table' && $",
              "{type} === 'Array' }"
            ].join(""),
            selectedKeys: {
              parts: [{ path: "value" }],
              formatter: (value) => {
                if (value !== Object && typeof value === "boolean") {
                  return String(value);
                }
                return value;
              }
            }
          },
          onChange: (fn) => fn.updateValueColumn
        }),
        import_form_and_table.ft.Select("s1", {
          list: typeList,
          properties: {
            width: "15rem",
            visible: ["{= $", "{key}", "===", "'type'", "}"].join(""),
            selectedKey: {
              parts: [{ path: "value" }],
              formatter: (value) => value
            }
          },
          onChange: (fn) => fn.updateValueColumn
        }),
        import_form_and_table.ft.Select("s2", {
          list: dialogs.reduce(
            (acc, cur) => {
              const each = { key: cur.key, text: cur.key };
              acc.push(each);
              return acc;
            },
            [{}]
          ),
          properties: {
            width: "15rem",
            visible: [
              "{= $",
              "{key}",
              "===",
              "'valueHelpV2-dialogId'",
              "}"
            ].join(""),
            selectedKey: {
              parts: [{ path: "value" }],
              formatter: (value) => value
            }
          },
          onChange: (fn) => fn.updateValueColumn
        }),
        import_form_and_table.ft.Select("s3", {
          list: Object.keys(import_lib_core.conv).reduce(
            (acc, each) => {
              acc.push({ key: each, text: each });
              return acc;
            },
            [{ key: "", text: "" }]
          ),
          properties: {
            width: "15rem",
            visible: ["{= $", "{key}", "===", "'conv'", "}"].join(""),
            selectedKey: {
              parts: [{ path: "value" }],
              formatter: (value) => value
            }
          },
          onChange: (fn) => fn.updateValueColumn
        })
      ]
    }).addItems(currentDialogOptions);
  }
  builder.addDialog("formDialog", {
    properties: {
      title: `${dialog.target.type} dialog`
    },
    isOpen: dialog.isOpen,
    onConfirm: (fn) => fn.confirmDialogUpdate,
    onCancel: (fn) => fn.cancelDialogUpdate,
    ftData: dialogBuilder.done()
  });
  if (dialog.isCreateVersionOpen) {
    createVersionBuilder.addForm("form").addContainer("c1").addField("description", {
      label: "Description",
      component: import_form_and_table.ft.Input({
        onChange: (fn) => fn.updateVersionValue("description")
      })
    });
  }
  builder.addDialog("versionDialog", {
    properties: {
      title: `Create Version`
    },
    fullSize: false,
    isOpen: dialog.isCreateVersionOpen,
    onConfirm: (fn) => fn.confirmCreateVersion,
    onCancel: (fn) => fn.toggleCreateVersionDialog(false),
    ftData: createVersionBuilder.done()
  });
  if (dialog.isMoveOpen) {
    moveBuilder.addForm("form").addContainer("c1");
    if (dialog.target.keys[0] && dialog.target.keys[0].key === "dialogs") {
      moveBuilder.addField("index", {
        label: "Index",
        component: import_form_and_table.ft.Input({
          properties: {
            width: "3rem",
            type: window.sap.m.InputType.Number
          },
          onChange: (fn) => fn.updateMoveValue("index")
        })
      });
    } else {
      moveBuilder.addField("dialogKey", {
        label: "Dialog Key",
        component: import_form_and_table.ft.Select({
          list: dialogs.map((dlg) => dlg.key).concat(""),
          onChange: (fn) => fn.updateMoveValue("dialogKey")
        })
      });
    }
  }
  builder.addDialog("moveDialog", {
    properties: {
      title: `Move`
    },
    fullSize: false,
    isOpen: dialog.isMoveOpen,
    onConfirm: (fn) => fn.confirmMove,
    onCancel: (fn) => fn.toggleMoveDialog(false),
    ftData: moveBuilder.done()
  });
  if (dialog.isExportOpen) {
    exportBuilder.addForm("exportForm").addContainer("c1").addField("globalFunctions", {
      label: "",
      value: [meta.extra.export.globalFunction, meta.extra.export.remain],
      component: [
        import_form_and_table.ft.CheckBox("global", {
          text: "Including Global Function",
          onChange: (fn) => fn.updateExportValue("globalFunction")
        }),
        import_form_and_table.ft.CheckBox("remain", {
          text: "Keep Origin Data",
          onChange: (fn) => fn.updateExportValue("remain")
        })
      ]
    });
  }
  builder.addDialog("exportDialog", {
    properties: {
      title: "Export"
    },
    fullSize: false,
    isOpen: dialog.isExportOpen,
    onConfirm: (fn) => fn.confirmExport,
    onCancel: (fn) => fn.toggleExportDialog(false),
    ftData: exportBuilder.done()
  });
  if (dialog.isDeployOpen) {
    deployBuilder.addForm("form").addContainer("c1").addField("version", {
      label: "Selected Version",
      value: [meta.extra.deploy.version, meta.extra.deploy.description].join(
        " "
      )
    }).addField("partner", {
      label: "Partner",
      value: meta.extra.deploy.partner,
      component: import_form_and_table.ft.Select({
        combine: true,
        list: [{ id: "", name: "" }].concat(
          meta.extra.deploy.partners.map((each) => ({
            key: each.id,
            text: each.name
          }))
        ),
        onChange: (fn) => fn.updateDeployValue("partner")
      })
    }).addField("system", {
      label: "System",
      value: meta.extra.deploy.system,
      component: import_form_and_table.ft.Select({
        combine: true,
        list: [{ id: "", name: "" }].concat(
          meta.extra.deploy.systems.map((each) => ({
            key: each.id,
            text: each.name
          }))
        ),
        onChange: (fn) => fn.updateDeployValue("system")
      })
    }).addField("add", {
      label: "",
      value: "Add",
      component: import_form_and_table.ft.Button({
        onPress: (fn) => fn.addSystemToSp
      })
    }).addField("deployedSystems", {
      label: "Deployed System",
      value: meta.extra.deploy.presys,
      component: import_form_and_table.ft.Input({
        multiple: true,
        onChange: (fn) => fn.updateDeployValue("presys")
      })
    });
  }
  builder.addDialog("deployDialog", {
    properties: {
      title: `Deploy Version`
    },
    fullSize: false,
    isOpen: dialog.isDeployOpen,
    onConfirm: (fn) => fn.confirmDeployVersion,
    onCancel: (fn) => fn.toggleDeployDialog(false),
    ftData: deployBuilder.done()
  });
  if (dialog.isCodeEditorOpen) {
    const funcBtns = [];
    const strParams = (0, import_modules.getFunctionHeader)(dialog, {
      addBracket: true,
      addContextKeys: true
    });
    if (strParams) {
      funcBtns.push({
        name: "state",
        value: window.sap.ui.base.ManagedObject.escapeSettingsValue(strParams),
        component: import_form_and_table.ft.Button()
      });
    }
    codeBuilder.addCode(`addCode`, {
      title: "Function Editor",
      value: functions[dialog.target.functionUid].content,
      buttons: funcBtns,
      properties: {
        change: (fn) => fn.updateOptionsFunc
      }
    });
    codeBuilder.addCode(`lintResult`, {
      title: "Lint Result",
      value: dialog.target.resultText,
      selected: !!dialog.target.resultText,
      properties: {
        type: "text",
        editable: false
      }
    });
    if (dialog.target.isAsync) {
      codeBuilder.addTable("vars", {
        usePagination: {
          itemsPerPage: 5
        }
      }).addColumn("id", {
        text: "Returning Data Path",
        width: "15rem"
      }).addColumn("attributes", {
        text: "Attributes"
      }).addItems(
        (0, import_mods.extractAttributes)(
          forms,
          tables,
          codeeditors,
          nodeeditors,
          sections
        ).concat(
          dialogs.reduce((acc, dlg) => {
            const row = {
              id: ["dialogs", dlg.key].join("."),
              attributes: ["isOpen"]
            };
            row.attributes = row.attributes.join(", ");
            acc.push(row);
            return acc.concat(
              (0, import_mods.extractAttributes)(
                dlg.forms,
                dlg.tables,
                dlg.codeeditors,
                dlg.nodeeditors,
                dlg.sections
              )
            );
          }, [])
        )
      );
    }
  }
  builder.addDialog("codeEditDialog", {
    properties: {
      title: `Function Editor`
    },
    isOpen: dialog.isCodeEditorOpen,
    onConfirm: (fn) => fn.confirmCodeEditorUpdate,
    onCancel: (fn) => fn.cancelCodeEditorUpdate,
    ftData: codeBuilder.done()
  });
  return /* @__PURE__ */ import_react.default.createElement(
    import_form_and_table.FormTable,
    __spreadProps(__spreadValues({}, props), {
      fn: controller,
      title: isCreate ? "Meta \uC0DD\uC131" : "Meta \uAD00\uB9AC",
      data: builder.done()
    })
  );
};
var builder_default = (0, import_toolkit.compose)(import_react_router_dom.withRouter)(Builder);
