var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useCallback, useEffect } from "react";
import { MenuItem } from "@ui5/webcomponents-react";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
const FtMenuItem = (props) => {
  const _a = props, {
    value,
    properties = {},
    handleSelectedIdx,
    event,
    setEvent,
    items = {},
    getIndex,
    selectedIdx,
    refineEventHookName
  } = _a, rest = __objRest(_a, [
    "value",
    "properties",
    "handleSelectedIdx",
    "event",
    "setEvent",
    "items",
    "getIndex",
    "selectedIdx",
    "refineEventHookName"
  ]);
  const indexLabel = props["data-idx"];
  const handleClick = useCallback(
    (event2) => {
      const onClick = properties.onClick || props.onClick;
      onClick(event2);
      handleSelectedIdx(null);
      setEvent(null);
    },
    [properties, handleSelectedIdx, setEvent]
  );
  useEffect(() => {
    if (selectedIdx === indexLabel && !!event) {
      handleClick(event);
    }
  }, [selectedIdx, event, handleClick]);
  return /* @__PURE__ */ React.createElement(MenuItem, __spreadValues(__spreadValues({ text: value }, rest), properties), Object.entries(items).map(([key, btn], index) => {
    const curIndex = getIndex(index, indexLabel);
    return /* @__PURE__ */ React.createElement(
      ComponentBuilder,
      __spreadValues({
        key,
        type: "MenuItem",
        "data-idx": curIndex,
        getIndex,
        selectedIdx,
        handleSelectedIdx,
        event,
        setEvent,
        refineEventHookName
      }, btn)
    );
  }));
};
export default FtMenuItem;
