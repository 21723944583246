var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var use_default_searcher_exports = {};
__export(use_default_searcher_exports, {
  default: () => use_default_searcher_default
});
module.exports = __toCommonJS(use_default_searcher_exports);
var import_use_pagination = __toESM(require("./use-pagination"));
var import_lib_core = require("@bsgp/lib-core");
function getTargetColumns(tableData) {
  const searchData = tableData.toolbar.content.find(
    ({ component }) => component.type === "SearchField"
  );
  if (searchData.targetColumns) {
    return searchData.targetColumns;
  }
  return tableData.columns.reduce((acc, { name, component }) => {
    acc.push(name);
    if ((0, import_lib_core.isArray)(component)) {
      component.forEach(({ name: name2 }) => acc.push(name2));
    }
    return acc;
  }, []);
}
function applySearchOnTable(oEvent, tableData) {
  const inputValue = oEvent.getParameter("query");
  const oSearch = oEvent.getSource();
  const oTable = oSearch.getParent().getParent();
  const targetColumns = getTargetColumns(tableData);
  const searchFilter = new window.sap.ui.model.Filter({
    filters: targetColumns.map(
      (name) => new window.sap.ui.model.Filter({
        path: name,
        test: (0, import_use_pagination.compareSearchInput)(inputValue)
      })
    ),
    and: false
  });
  if (!tableData.usePagination) {
    oTable.getBinding("items").filter(searchFilter);
    return;
  }
  let count = -1;
  const { itemsPerPage = 20 } = tableData.usePagination;
  const indexFilter = new window.sap.ui.model.Filter({
    path: "",
    test: () => {
      count += 1;
      return count >= 0 && count < itemsPerPage;
    }
  });
  oTable.getBinding("items").filter([searchFilter, indexFilter]);
  (0, import_use_pagination.default)(oTable, tableData).onSearch();
}
function getCustomDataByKey(comp, key) {
  return comp.getCustomData().find((item) => item.getKey() === key);
}
function handleSearchInput(oSearch, tableData) {
  const eList = oSearch.mEventRegistry.search || [];
  if (eList.length > 0) {
    const prevHandler = eList[0].fFunction;
    oSearch.detachSearch(prevHandler);
  }
  oSearch.attachSearch(void 0, (oEvent) => {
    const oSearchAfterInput = oEvent.getSource();
    const searchInput = oEvent.getParameter("query");
    getCustomDataByKey(oSearchAfterInput, "search").setValue(searchInput);
    applySearchOnTable(oEvent, tableData);
  });
}
var use_default_searcher_default = handleSearchInput;
