import { TableMode } from "@ui5/webcomponents-react";
import { getNormalizedString } from "@/lib/utils";
function getMode(each) {
  if (typeof each === "string") {
    return TableMode[each] || TableMode.None;
  }
  let mode;
  if ("mode" in each) {
    mode = each.mode;
  } else if ("properties" in each && "mode" in each.properties) {
    mode = each.properties.mode;
  }
  if (mode) {
    if ([
      TableMode.SingleSelect.toLowerCase(),
      "SingleSelectMaster",
      "s",
      "single"
    ].includes(mode.toLowerCase())) {
      mode = TableMode.SingleSelect;
    } else if ([TableMode.MultiSelect.toLowerCase(), "m", "multi", "multiple"].includes(
      mode.toLowerCase()
    )) {
      mode = TableMode.MultiSelect;
    }
  }
  return mode || TableMode.None;
}
function filterFn(rows, accessor, filterValue) {
  if (!filterValue) return rows;
  return rows.filter((row) => {
    const validRow = !!row.values ? row.values : row;
    const rowVal = getNormalizedString(validRow[accessor]);
    if (rowVal.includes(getNormalizedString(filterValue))) {
      return true;
    }
    return false;
  });
}
export { getMode, filterFn };
