var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var controller_exports = {};
__export(controller_exports, {
  addExtraObject: () => addExtraObject,
  addProperties: () => addProperties,
  addSystemToSp: () => addSystemToSp,
  cancelCodeEditorUpdate: () => cancelCodeEditorUpdate,
  cancelDialogUpdate: () => cancelDialogUpdate,
  confirmCodeEditorUpdate: () => confirmCodeEditorUpdate,
  confirmCreateVersion: () => confirmCreateVersion,
  confirmDeployVersion: () => confirmDeployVersion,
  confirmDialogUpdate: () => confirmDialogUpdate,
  confirmExport: () => confirmExport,
  confirmMove: () => confirmMove,
  deployVersion: () => deployVersion,
  getMetaData: () => getMetaData,
  moveToList: () => moveToList,
  moveToPreview: () => moveToPreview,
  moveToPreviewFromForm: () => moveToPreviewFromForm,
  onAddPath: () => onAddPath,
  onCopyToDev: () => onCopyToDev,
  onMoveObjectToAnotherParent: () => onMoveObjectToAnotherParent,
  onUpdateContainerIndex: () => onUpdateContainerIndex,
  onUpdatePath: () => onUpdatePath,
  onUpdateTableCell: () => onUpdateTableCell,
  openCodeEditorDialog: () => openCodeEditorDialog,
  openEditDialog2: () => openEditDialog2,
  openSubDialog: () => openSubDialog,
  postMetaData: () => postMetaData,
  queryAllMeta: () => queryAllMeta,
  selectMeta: () => selectMeta,
  toggleCreateVersionDialog: () => toggleCreateVersionDialog,
  toggleDeployDialog: () => toggleDeployDialog,
  toggleExportDialog: () => toggleExportDialog,
  toggleMoveDialog: () => toggleMoveDialog,
  updateDeployValue: () => updateDeployValue,
  updateExportValue: () => updateExportValue,
  updateIgnoreOption: () => updateIgnoreOption,
  updateKeyColumn: () => updateKeyColumn,
  updateMetaProperty: () => updateMetaProperty,
  updateMoveValue: () => updateMoveValue,
  updateOptionsFunc: () => updateOptionsFunc,
  updateRequiredKey: () => updateRequiredKey,
  updateValueColumn: () => updateValueColumn,
  updateValueType: () => updateValueType,
  updateVersionValue: () => updateVersionValue
});
module.exports = __toCommonJS(controller_exports);
var import_ui5_lib_rc = require("ui5-lib-rc");
var import_form_and_table = require("@bsgp/form-and-table");
var import_slice = __toESM(require("./slice"));
var constants = __toESM(require("./forms"));
var import_modules = require("./modules");
var import_modules2 = require("../renderer/modules");
var import_controller = require("../renderer/controller");
var import_lib_api = require("@bsgp/lib-api");
var import_lib_core = require("@bsgp/lib-core");
var import_ui5 = require("actions/ui5");
const builderActions = import_slice.default.actions;
const endPoint = "/g/lc_ui5";
const toggleDeployDialog = ({ getProps }) => (isOpen) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch, dispatch } = getProps();
  if (isOpen === true) {
    const { row } = import_form_and_table.functions.getValue(oEvent);
    yield dispatch(
      import_lib_api.directory.getPartners({
        afterSucceed: (data) => {
          _dispatch(builderActions._toggleDeployDialog({ isOpen }));
          _dispatch(
            builderActions._updateDeployValue({
              partners: data,
              version: row.lstvsn,
              description: row.description,
              presys: row.presys || []
            })
          );
        }
      })
    );
  } else {
    _dispatch(builderActions._toggleDeployDialog({ isOpen }));
  }
});
const updateDeployValue = ({ getProps }) => (key) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch, dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  if (key === "partner") {
    yield dispatch(
      import_lib_api.directory.getSystems(
        {
          afterSucceed: (data) => {
            _dispatch(builderActions._updateDeployValue({ systems: data }));
          }
        },
        { partnerID: value }
      )
    );
  }
  _dispatch(builderActions._updateDeployValue({ [key]: value }));
});
const toggleCreateVersionDialog = ({
  getProps
}) => (isOpen) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  _dispatch(builderActions._toggleCreateVersionDialog({ isOpen }));
});
const toggleMoveDialog = ({ getProps }) => (isOpen, options) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  _dispatch(builderActions._toggleMoveDialog({ isOpen, options }));
});
const toggleExportDialog = ({ getProps }) => (isOpen, options) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  _dispatch(builderActions._toggleExportDialog({ isOpen, options }));
});
const updateVersionValue = ({ getProps }) => (key) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(builderActions._updateVersionValue({ [key]: value }));
});
const updateMoveValue = ({ getProps }) => (key) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(builderActions._updateMoveValue({ [key]: value }));
});
const updateExportValue = ({ getProps }) => (key) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(builderActions._updateExportValue({ [key]: value }));
});
const confirmMove = ({ getProps }) => () => __async(void 0, null, function* () {
  const { dispatch, _dispatch, _state } = getProps();
  const targetKey = _state.meta.extra.dialog.target.keys[0];
  if (targetKey && targetKey.key === "dialogs") {
    const { index } = _state.meta.extra.move;
    _dispatch(builderActions._moveFromDialog({ index }));
    _dispatch(builderActions._closeDialog());
  } else {
    const { dialogKey } = _state.meta.extra.move;
    if (!dialogKey) {
      dispatch((0, import_ui5.addError)("\uC774\uB3D9\uD560 \uD0C0\uAC9F Dialog\uC758 Key\uB97C \uC120\uD0DD\uD574\uC8FC\uC138\uC694."));
      return false;
    }
    _dispatch(builderActions._moveToDialog({ dialogKey }));
  }
  _dispatch(builderActions._toggleMoveDialog({ isOpen: false }));
});
const confirmExport = ({ getProps }) => () => __async(void 0, null, function* () {
  const { _dispatch, _state, dispatch } = getProps();
  const { globalFunction } = _state.meta.extra.export;
  const { rootKey_s, indexValue } = _state.meta.extra.dialog.target;
  const newMeta = (0, import_lib_core.clone)(import_slice.initialState);
  const targetObj = _state.meta[rootKey_s][indexValue];
  const funcValues = [];
  (0, import_modules.extractFunctions)(targetObj, funcValues);
  const functions2 = funcValues.reduce((acc, value) => {
    acc[value] = _state.meta.functions[value];
    return acc;
  }, {});
  newMeta[rootKey_s].push(targetObj);
  newMeta.functions = __spreadValues(__spreadValues({}, newMeta.functions), functions2);
  newMeta.description = `export from ${_state.meta.id}`;
  if (globalFunction) {
    newMeta.functions.global = _state.meta.functions.global;
  }
  yield import_lib_api.apiHub.post(endPoint, newMeta, {
    afterSucceed: (data) => {
      dispatch((0, import_ui5.addError)(`export \uC131\uACF5, meta ID - ${data.id}`));
    }
  });
  _dispatch(builderActions._exportMeta({ funcValues }));
  _dispatch(builderActions._toggleExportDialog({ isOpen: false }));
});
const confirmCreateVersion = ({ getProps }) => () => __async(void 0, null, function* () {
  const { dispatch, _dispatch, _state } = getProps();
  if (!_state.meta.extra.version.description) {
    dispatch((0, import_ui5.addError)("\uBC84\uC804 \uC124\uBA85\uC744 \uC785\uB825\uD574\uC8FC\uC138\uC694."));
    return false;
  }
  yield import_lib_api.apiHub.put(
    endPoint,
    {
      createVersion: {
        metaId: _state.meta.id,
        description: _state.meta.extra.version.description
      }
    },
    {
      afterSucceed: (data) => {
        if (data.id === _state.meta.id) {
          dispatch((0, import_ui5.addError)({ type: "S", message: "\uBC84\uC804\uC774 \uC0DD\uC131\uB418\uC5C8\uC2B5\uB2C8\uB2E4" }));
          _dispatch(
            builderActions._toggleCreateVersionDialog({ isOpen: false })
          );
          _dispatch(builderActions._getMetaData(data));
        }
      }
    }
  );
});
const addSystemToSp = ({ getProps }) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch, _state } = getProps();
  const { partner, system, presys } = _state.meta.extra.deploy;
  if (partner && system) {
    _dispatch(
      builderActions._updateDeployValue({
        presys: [...presys, [system, partner].join("@")]
      })
    );
  }
});
const deployVersion = ({ getProps }) => (oEvent) => __async(void 0, null, function* () {
  const { dispatch, _dispatch, _state } = getProps();
  const { row } = import_form_and_table.functions.getValue(oEvent);
  yield import_lib_api.apiHub.put(
    endPoint,
    {
      deployVersion: {
        metaId: _state.meta.id,
        versionId: row.lstvsn
      }
    },
    {
      afterSucceed: (data) => {
        if (data.id === _state.meta.id) {
          dispatch(
            (0, import_ui5.addError)({
              type: "S",
              message: `\uBC84\uC804 ${row.lstvsn} \uBC30\uD3EC\uD558\uC600\uC2B5\uB2C8\uB2E4`
            })
          );
          _dispatch(builderActions._getMetaData(data));
        }
      }
    }
  );
});
const confirmDeployVersion = ({ getProps }) => (oEvent) => __async(void 0, null, function* () {
  const { dispatch, _dispatch, _state } = getProps();
  const { version, presys } = _state.meta.extra.deploy;
  yield import_lib_api.apiHub.put(
    endPoint,
    {
      preDeployVersion: {
        metaId: _state.meta.id,
        versionId: version,
        presys
      }
    },
    {
      afterSucceed: (data) => {
        if (data.id === _state.meta.id) {
          dispatch(
            (0, import_ui5.addError)({
              type: "S",
              message: `\uBC84\uC804 ${version} \uBC30\uD3EC\uD558\uC600\uC2B5\uB2C8\uB2E4`
            })
          );
          _dispatch(builderActions._getMetaData(data));
          _dispatch(builderActions._toggleDeployDialog({ isOpen: false }));
        }
      }
    }
  );
});
const postMetaData = ({ getProps }) => ({
  isCreate
}) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch, _state, history, path } = getProps();
  let errorCode;
  yield import_lib_api.apiHub.post(
    endPoint,
    import_slice.metaAttributes.reduce((acc, attr) => {
      acc[attr] = _state.meta[attr];
      return acc;
    }, {}),
    {
      afterSucceed: (data) => {
        if (isCreate) {
          history.replace(`${path}/builder/${data.id}`);
        }
        _dispatch(builderActions._getMetaData(data));
        _dispatch(
          builderActions._updateMetaProperty({
            extra: true,
            key: "errorMessage",
            value: ""
          })
        );
      },
      afterFailed: (err) => {
        _dispatch(
          builderActions._updateMetaProperty({
            extra: true,
            key: "errorMessage",
            value: err.body.errorMessage
          })
        );
        errorCode = "X";
      }
    }
  );
  return { errorCode };
});
const getMetaData = ({ getProps }) => ({ id, isCreate }) => () => {
  const { _dispatch } = getProps();
  import_lib_api.apiHub.get(
    endPoint,
    { id },
    {
      afterSucceed: (res) => {
        _dispatch(builderActions._getMetaData(res));
      },
      afterFailed: (data) => {
        moveToList({ getProps })();
      }
    }
  );
};
const onCopyToDev = ({ getProps }) => (oEvent) => {
  const { _state, _dispatch } = getProps();
  import_lib_api.apiHub.put(
    endPoint,
    {
      copyMetaToDev: {
        id: _state.meta.id
      }
    },
    {
      afterSucceed: (data) => {
        _dispatch(
          builderActions._updateMetaProperty({
            key: "message",
            value: data.message,
            extra: true
          })
        );
      }
    }
  );
};
const moveToPreviewFromForm = ({ getProps }) => (oEvent) => {
  const { history, path, _state, dispatch } = getProps();
  const { selectedPath } = _state.meta.extra;
  if (!selectedPath) {
    return dispatch((0, import_ui5.addError)("Path\uB97C \uC120\uD0DD\uD574\uC8FC\uC138\uC694"));
  }
  history.push(path + `/preview/${selectedPath.replace("/", "")}`);
};
const moveToPreview = ({ getProps }) => (oEvent) => {
  const { history, path, _state } = getProps();
  const { index } = import_form_and_table.functions.getValue(oEvent);
  const selectedPath = _state.meta.paths[index].origin;
  history.push(path + `/preview/${selectedPath.replace("/", "")}`);
};
const moveToList = ({ getProps }) => (oEvent) => {
  const { history, path } = getProps();
  history.push(path + `/list`);
};
const updateMetaProperty = ({ getProps }) => (key, extra) => (oEvent) => {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(builderActions._updateMetaProperty({ key, value, extra }));
};
const onUpdateTableCell = ({ getProps }) => (tabName, cellName) => (oEvent) => {
  const { _dispatch } = getProps();
  const { index, value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(
    builderActions._updateTableCell({ index, value, cellName, tabName })
  );
};
const onUpdatePath = ({ getProps }) => (cellName) => (oEvent) => {
  const { _dispatch, _state } = getProps();
  const { meta } = _state;
  const { index, value } = import_form_and_table.functions.getValue(oEvent);
  const prevPath = (0, import_lib_core.tryit)(() => meta.paths[index]) || {};
  const payload = { id: prevPath.skid, metaId: meta.id };
  if (cellName === "origin") {
    payload.path = value;
  } else {
    payload.path = prevPath.origin;
    payload[cellName] = value;
  }
  _dispatch(
    builderActions._updateTableCell({
      index,
      value,
      cellName,
      tabName: "paths"
    })
  );
  import_lib_api.apiHub.put(
    endPoint,
    {
      updatePath: payload
    },
    {
      afterSucceed: (data) => {
        _dispatch(
          builderActions._updateTableRow({
            index,
            row: data,
            tabName: "paths"
          })
        );
        if (cellName === "origin") {
          _dispatch(
            builderActions._updateMetaProperty({
              key: "selectedPath",
              value,
              extra: true
            })
          );
        }
      }
    }
  );
};
const onAddPath = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  _dispatch(builderActions._addPath());
};
const addExtraObject = ({ getProps }) => (params) => (oEvent) => {
  const { keys, subKey, sub2Index } = params || {};
  const { _dispatch } = getProps();
  const { row } = import_form_and_table.functions.getValue(oEvent);
  if (params.keys) {
    _dispatch(
      builderActions._addExtraObject({
        keys,
        obj: constants[subKey]
      })
    );
  } else {
    _dispatch(
      builderActions._addExtraObject({
        keys: [...row.keys, { key: row[`sub2Key_s_${sub2Index}`] }],
        obj: constants[row[`sub2Key_${sub2Index}`]],
        dummy: "from second level"
      })
    );
  }
};
const checkDialogInvalid = (state) => {
  var _a;
  const invalid = ((_a = state.extra.dialog) == null ? void 0 : _a.invalid) || {};
  const isInvalid = Object.values(invalid).includes(true);
  return isInvalid;
};
const confirmDialogUpdate = ({ getProps }) => (oEvent) => {
  const { _dispatch, _state } = getProps();
  const invalid = checkDialogInvalid(_state.meta);
  if (invalid) return false;
  _dispatch(builderActions._closeDialog());
  postMetaData({ getProps })({})();
};
const cancelDialogUpdate = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  _dispatch(builderActions._undoDialogUpdate());
  _dispatch(builderActions._closeDialog());
};
const cancelCodeEditorUpdate = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  _dispatch(builderActions._cancelCodeEditorUpdate());
};
const updateRequiredKey = ({ getProps }) => (requiredKey) => (oEvent) => {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(builderActions._updateRequiredKey({ requiredKey, value }));
  _dispatch(builderActions._updateDialogInvalid({ requiredKey, value }));
};
const onUpdateContainerIndex = ({ getProps }) => ({
  maxIndex,
  currentIndex
}) => (oEvent) => {
  const { _dispatch, dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  const newIndex = parseInt(value);
  if (newIndex > maxIndex) {
    return dispatch((0, import_ui5.addError)(`\uCD5C\uB300 Index\uAC12\uC740 ${maxIndex}\uC785\uB2C8\uB2E4`));
  }
  _dispatch(builderActions._moveObject({ newIndex }));
  _dispatch(builderActions._closeDialog());
};
const onMoveObjectToAnotherParent = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  import_ui5_lib_rc.messageBox.yesOrNo("\uC774\uB3D9\uD558\uACA0\uC2B5\uB2C8\uAE4C?", {
    yes: () => {
      _dispatch(
        builderActions._moveObjectToAnotherParent({
          newParentKey: value
        })
      );
      _dispatch(builderActions._closeDialog());
    }
  });
};
const updateKeyColumn = ({ getProps }) => (oEvent) => {
  const { _dispatch, dispatch } = getProps();
  const { row, value } = import_form_and_table.functions.getValue(oEvent);
  const oldKey = row.key;
  const newKey = value;
  const invalidKeys = ["key", "ignore", "binding", "index"];
  if (invalidKeys.includes(newKey)) {
    return dispatch(
      (0, import_ui5.addError)({ message: `${newKey}\uB97C option\uC73C\uB85C \uC120\uD0DD\uD560 \uC218 \uC5C6\uC2B5\uB2C8\uB2E4.` })
    );
  }
  _dispatch(
    builderActions._updateKeyColumn({
      oldKey,
      newKey
    })
  );
  if (newKey.startsWith("on") && row.value === "") {
    _dispatch(
      builderActions._updateValueType({
        newType: "Function",
        row: __spreadProps(__spreadValues({}, row), {
          key: newKey
        })
      })
    );
  }
};
const updateIgnoreOption = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  const { value, row } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(
    builderActions._updateIgnoreOption({
      value,
      key: row.key
    })
  );
};
const selectMeta = ({ getProps }) => ({ items }) => {
  const { _dispatch, dispatch } = getProps();
  if (items.length === 0) {
    return dispatch((0, import_ui5.addError)({ message: "\uC120\uD0DD\uD55C Meta\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4." }));
  } else {
    import_form_and_table.functions.confirmWithDialog(
      "Meta\uB97C \uC120\uD0DD\uD558\uBA74 \uD604\uC7AC Dialog\uC758 \uD558\uC704 \uC694\uC18C\uB4E4\uC774 \uBAA8\uB450 \uC81C\uAC70\uB429\uB2C8\uB2E4. \uC9C4\uD589\uD558\uC2DC\uACA0\uC2B5\uB2C8\uAE4C?",
      () => {
        _dispatch(
          builderActions._updateRequiredKey({
            requiredKey: "metaId",
            value: items[0].id
          })
        );
      }
    );
  }
};
const queryAllMeta = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  import_lib_api.apiHub.get(
    endPoint,
    { id: "*" },
    {
      afterSucceed: (res) => {
        _dispatch(
          builderActions._updateMetaProperty({
            extra: true,
            key: "metaList",
            value: res.list
          })
        );
      }
    }
  );
};
const updateValueColumn = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  const nowKey = import_form_and_table.functions.getValue(oEvent).row.key;
  const newValue = import_form_and_table.functions.getValue(oEvent).value;
  _dispatch(builderActions._updateValueColumn({ nowKey, newValue }));
};
const openEditDialog2 = ({ getProps }) => ({
  rootKey_s,
  indexValue
}) => (oEvent) => {
  const { _dispatch } = getProps();
  _dispatch(
    builderActions._openEditDialog2({
      type: rootKey_s,
      keys: [{ key: rootKey_s, index: indexValue }]
    })
  );
};
const openSubDialog = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  const { subKey_s, keys } = import_form_and_table.functions.getValue(oEvent).row;
  _dispatch(builderActions._openEditDialog2({ keys, type: subKey_s }));
};
const addProperties = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  _dispatch(builderActions._addProperties());
};
const updateValueType = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  const { value, row } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(
    builderActions._updateValueType({
      newType: value,
      row
    })
  );
};
const openCodeEditorDialog = ({
  getProps
}) => (presetValue) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch } = getProps();
  const { row } = import_form_and_table.functions.getValue(oEvent);
  const { value, key } = row || { value: presetValue, key: "" };
  if (presetValue === "global") {
    _dispatch(
      builderActions._openCodeEditorDialog({
        uid: value,
        isAsync: false,
        contextKeys: []
      })
    );
    return;
  }
  const contextKeys = (0, import_modules2.executeFunction)(getProps(), { returnContextKeys: true });
  const moreContextKeys = yield (0, import_controller.getMetaFuncContent)({
    getProps,
    returnContextKeys: true
  })({ type: key })(oEvent);
  const more2ContextKeys = key === "value" ? (0, import_modules2.getValue)([], {
    props: getProps(),
    returnContextKeys: true
  }) : [];
  const more3ContextKeys = key === "items" ? (0, import_modules2.getItems)({}, { props: getProps(), returnContextKeys: true }) : [];
  const finalContextKeys = [
    ...contextKeys,
    ...moreContextKeys,
    ...more2ContextKeys,
    ...more3ContextKeys
  ];
  if ([
    "valueHelpV2-onConfirm",
    "valueHelpV2-onRequest",
    "onChange",
    "onSelect",
    "onPress",
    "onConfirm",
    "onCancel",
    "onRequest",
    "onScan",
    "onError",
    "onSubmit",
    "onSort",
    "onNodeDoubleClick"
  ].includes(key) || row === void 0) {
    _dispatch(
      builderActions._openCodeEditorDialog({
        uid: value,
        isAsync: true,
        contextKeys: finalContextKeys
      })
    );
  } else {
    _dispatch(
      builderActions._openCodeEditorDialog({
        uid: value,
        isAsync: false,
        contextKeys: finalContextKeys
      })
    );
  }
});
const updateOptionsFunc = ({ getProps }) => (oEvent) => {
  const { _dispatch } = getProps();
  const { value } = import_form_and_table.functions.getValue(oEvent);
  _dispatch(builderActions._updateOptionsFunc({ newValue: value }));
};
const confirmCodeEditorUpdate = ({ getProps }) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch, _state, dispatch } = getProps();
  const sourcecode = _state.meta.functions[_state.meta.extra.dialog.target.functionUid].content;
  const result = yield import_lib_api.apiHub.lintSourceCode({
    code: sourcecode
  });
  const { output, resultText } = result.data.cbData;
  if (output) {
    _dispatch(
      builderActions._updateOptionsFunc({ newValue: output, resultText })
    );
  }
  if (resultText) {
    dispatch(
      (0, import_ui5.addError)({
        message: "Lint Errors occurred",
        description: resultText
      })
    );
  } else {
    import_ui5_lib_rc.messageBox.yesOrNo("\uC800\uC7A5\uD558\uACA0\uC2B5\uB2C8\uAE4C?", {
      yes: () => {
        postMetaData({ getProps })({})();
        _dispatch(builderActions._confirmCodeorUpdate());
      }
    });
  }
  return false;
});
