var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import {
  extractArgs,
  isBound,
  removeUndefinedKeys
} from "@bsgp/form-and-table";
function getComponentName({ elName, fieldComponent }) {
  return [elName, fieldComponent.name, fieldComponent.type].filter(Boolean).join("-");
}
function isDataBinding(text) {
  if (!text || typeof text !== "string") return false;
  const simpleKeyMatch = text.match(/^\{(\w+)\}$/);
  if (simpleKeyMatch) return true;
  const templateLiteralMatch = text.match(/^\{=(.*\$\{(\w+)\}.*)\}$/);
  if (templateLiteralMatch) return true;
  return false;
}
function getValueFromDataBindingFormat(text, targetData) {
  if (!text || typeof text !== "string") return text;
  const trimmedText = text.replace(/\s/g, "");
  const simpleKeyMatch = trimmedText.match(/^\{(\w+)\}$/);
  if (simpleKeyMatch) {
    const key = simpleKeyMatch[1];
    return targetData ? targetData[key] : key;
  }
  const templateLiteralMatch = trimmedText.match(/^\{=(.*\$\{(\w+)\}.*)\}$/);
  if (templateLiteralMatch) {
    const expressionText = templateLiteralMatch[1];
    const key = templateLiteralMatch[2];
    const data = targetData ? targetData[key] : key;
    const changedExpression = expressionText.replace(/\$\{.*\}/g, "param");
    const expression = new Function("param", `return ${changedExpression}`);
    return expression(data);
  }
  return targetData ? targetData[text] : text;
}
function removeDataBinding(options) {
  if (!options) return {};
  const newOptions = {};
  for (const [key, value] of Object.entries(options)) {
    newOptions[key] = getValueFromDataBindingFormat(value);
  }
  return newOptions;
}
function getNormalizedString(value) {
  if (!value) return "";
  let stringValue = value;
  if (typeof value !== "string") {
    if (typeof value === "number") {
      stringValue = value.toString();
    } else {
      return "";
    }
  }
  return stringValue.toLowerCase().trim();
}
function handleFilter({ targetArray, filteringValue }) {
  return targetArray.filter((row) => {
    return Object.values(row).some((rowValue) => {
      const comparingVal = getNormalizedString(filteringValue);
      if (!comparingVal) return true;
      const targetVal = getNormalizedString(rowValue);
      if (!targetVal) return false;
      return targetVal.includes(comparingVal);
    });
  });
}
function getStyleCodeFromProperties({ properties, props }) {
  return removeUndefinedKeys(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, properties.style || {}), props.style || {}), properties.visible === false ? { display: "none" } : {}), properties.hAlign ? { textAlign: properties.hAlign.toLowerCase() } : {}), properties.width ? { width: properties.width } : {}), properties.height ? { height: properties.height } : {}), properties.textAlign ? { textAlign: properties.textAlign } : {}));
}
function removeInvalidKeys(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    if (!key) return acc;
    if (obj[key] !== void 0) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
export {
  extractArgs,
  isBound,
  getComponentName,
  getValueFromDataBindingFormat,
  removeDataBinding,
  getNormalizedString,
  handleFilter,
  getStyleCodeFromProperties,
  isDataBinding,
  removeInvalidKeys
};
