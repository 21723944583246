var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import FormBuilder from "./FormBuilder";
import BaseIterator from "../common/BaseIterator";
const FormIterator = (_a) => {
  var _b = _a, {
    renderData,
    wrapForms
  } = _b, options = __objRest(_b, [
    "renderData",
    "wrapForms"
  ]);
  const doWrapForms = renderData.length > 1 && wrapForms;
  const itemNameList = renderData.map((formObject, index) => {
    return formObject.namedForm ? formObject.name : `form-${index}`;
  });
  return /* @__PURE__ */ React.createElement(
    BaseIterator,
    {
      items: renderData,
      itemNameList,
      wrapItems: doWrapForms,
      tabContainerProps: {
        className: "ft-transition-for-hide",
        style: { flex: 0 },
        "data-ui5-compact-size": true
      }
    },
    (_a2, key) => {
      var _b2 = _a2, { onSelect } = _b2, rest = __objRest(_b2, ["onSelect"]);
      return /* @__PURE__ */ React.createElement(FormBuilder, __spreadProps(__spreadValues({ formObject: rest, title: "" }, options), { key }));
    }
  );
};
export default FormIterator;
