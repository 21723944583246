var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useEffect, memo } from "react";
import { convertController } from "@bsgp/lib-hoc/src/modules";
import store from "@bsgp/lib-api/store";
import { titleRef } from "title";
import { setPropsRef } from "@/ref";
import { createUseStyles } from "react-jss";
import MainContentBuilder from "./builders/common/MainContentBuilder";
import {
  FlexBoxAlignItems,
  FlexBoxJustifyContent
} from "@ui5/webcomponents-react";
import VBox from "@/components/layouts/VBox";
import { clsx } from "clsx";
import { constructData } from "@/lib/constructors";
import HistoryBuilder from "@/builders/historyLink/Builder";
import FooterBuilder from "./builders/footer/FooterBuilder";
import { DialogContextProvider } from "./contexts/dialogContext";
import DialogV2BuildIterator from "./builders/dialogV2/DialogV2BuildIterator";
const useStyles = createUseStyles({
  main: {
    height: "100%",
    boxSizing: "border-box",
    "& *": {
      boxSizing: "border-box"
    },
    "& .react-flow *": {
      boxSizing: "initial"
    }
  }
});
const FormAndTableBuilder = (props) => {
  console.log("\u{1F680} ~ props:", props);
  const { data, fn, title } = props;
  const { center = false, hCenter = false, vCenter = false } = data;
  const classes = useStyles();
  setPropsRef({
    props,
    fn: convertController(fn, store.getState(), props, () => props)
  });
  useEffect(() => {
    document.title = title || "BSG Support";
    titleRef.current.textContent = title || "BSG Support";
  }, [title]);
  const newData = constructData(data);
  return /* @__PURE__ */ React.createElement("main", { className: classes.main }, newData.historyLinks && /* @__PURE__ */ React.createElement(
    HistoryBuilder,
    {
      renderData: newData.historyLinks,
      history: props.history,
      title: props.title
    }
  ), newData.footer && /* @__PURE__ */ React.createElement(
    FooterBuilder,
    {
      renderData: newData.footer,
      showToggleBtn: !!(data.form && data.table),
      stickyFooter: data.stickyFooter
    }
  ), /* @__PURE__ */ React.createElement(
    VBox,
    __spreadProps(__spreadValues(__spreadValues({
      name: "wrapper"
    }, hCenter !== false && {
      alignItems: FlexBoxAlignItems.Center
    }), (center || vCenter) && {
      justifyContent: FlexBoxJustifyContent.Center
    }), {
      fitContainer: true,
      className: clsx({
        "ft-height-fill-available": !!data.codeeditor,
        "ft-height-fit-content": !data.codeeditor
      })
    }),
    /* @__PURE__ */ React.createElement(
      VBox,
      {
        name: "innerWrapper",
        fitContainer: true,
        className: "max-height-100p"
      },
      /* @__PURE__ */ React.createElement(MainContentBuilder, __spreadProps(__spreadValues({}, props), { data: newData }))
    )
  ), newData.dialogV2 && /* @__PURE__ */ React.createElement(DialogContextProvider, null, /* @__PURE__ */ React.createElement(DialogV2BuildIterator, { renderData: newData.dialogV2 })));
};
export default memo(FormAndTableBuilder);
