import React from "react";
import { Text, VBox } from "@/components";
import DynamicTableBuilder from "./DynamicTableBuilder";
import Pagination from "@/components/tables/Pagination";
import ToolbarBuilder from "./ToolbarBuilder";
import { TableContextProvider } from "@/contexts/tableContext";
const TableBuilder = (props) => {
  const { columns, name: tableName, items } = props;
  const showFooter = columns.some((col) => col.showSum === true);
  return /* @__PURE__ */ React.createElement(VBox, { name: `vertical-box-${tableName}` }, /* @__PURE__ */ React.createElement(TableContextProvider, null, /* @__PURE__ */ React.createElement(
    DynamicTableBuilder,
    {
      data: props,
      tableName,
      items,
      header: /* @__PURE__ */ React.createElement(ToolbarBuilder, { tableName, tableInfo: props }),
      footer: showFooter && columns.map((col) => /* @__PURE__ */ React.createElement(Text, { value: col.footerText }))
    }
  ), /* @__PURE__ */ React.createElement(Pagination, { usePagination: props.usePagination })));
};
export default TableBuilder;
