var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Form } from "@/components";
import clsx from "clsx";
import FormGroupBuilder from "./FormGroupBuilder";
import { createUseStyles } from "react-jss";
function FormBuilder(_a) {
  var _b = _a, {
    formObject,
    title
  } = _b, options = __objRest(_b, [
    "formObject",
    "title"
  ]);
  const formName = formObject.name;
  const classes = useStyles();
  const sizeV2 = formObject.sizeV2 === true;
  const layout = calculateFormLayout(formObject);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    Form,
    __spreadValues(__spreadValues(__spreadValues(__spreadValues({
      name: formName,
      key: formName,
      title,
      className: clsx([...getStyleClassNames(formObject), classes.form]),
      style: {
        flex: 0,
        padding: "1rem 0.75rem",
        width: sizeV2 || formObject.containers.length > 1 ? "100%" : "auto"
      }
    }, layout), options), formObject.properties || {}), formObject),
    /* @__PURE__ */ React.createElement(
      FormGroupBuilder,
      {
        formObject,
        formName,
        sizeV2
      }
    )
  ));
}
const calculateFormLayout = (formObject) => {
  const defaultLabelSpan = formObject.labelSpanM || 2;
  if (formObject.sizeV2) {
    return {
      columnsL: 3,
      labelSpanL: 4,
      columnsXL: 4
    };
  }
  if (formObject.containers.length === 1) {
    return {
      columnsS: 1,
      columnsM: 1,
      columnsL: 1,
      columnsXL: 1,
      labelSpanS: defaultLabelSpan,
      labelSpanM: defaultLabelSpan,
      labelSpanL: defaultLabelSpan,
      labelSpanXL: defaultLabelSpan
    };
  }
  return {};
};
const getStyleClassNames = (formObject) => {
  const classList = [
    "ft-transition-for-hide",
    "ft-label-word-break",
    "fields-relative-position",
    "ft-form-elements-overflow-visible"
  ];
  if (formObject.compactSize === true) {
    classList.push("sapUiSizeCompact");
  }
  if (formObject.tightContainers === true) {
    classList.push("container-fit-content");
  }
  return classList;
};
export default FormBuilder;
const useStyles = createUseStyles({
  form: {
    "& .sapMText": {
      lineHeight: "1rem",
      paddingTop: "0.5rem",
      marginTop: 0,
      minHeight: "1rem",
      paddingBottom: "0.5rem"
    }
  }
});
