var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var form_and_table_exports = {};
__export(form_and_table_exports, {
  FormTable: () => FormTable,
  constructFooterData: () => constructFooterData,
  constructFormData: () => constructFormData,
  constructNodeEditorData: () => constructNodeEditorData,
  constructTableData: () => constructTableData,
  convertNumber: () => convertNumber,
  escapeValue: () => escapeValue,
  extractArgs: () => extractArgs,
  formTable: () => import_formTable.formTable,
  ft: () => import_ft.ft,
  functions: () => functions,
  getNewName: () => getNewName,
  getNewValue: () => getNewValue,
  isBound: () => isBound,
  refI18n: () => refI18n,
  removeUndefinedKeys: () => removeUndefinedKeys,
  useI18nBundle: () => import_webcomponents_react_base.useI18nBundle
});
module.exports = __toCommonJS(form_and_table_exports);
var import_react = __toESM(require("react"));
var import_toolkit = require("@reduxjs/toolkit");
var import_lib_hoc = require("@bsgp/lib-hoc");
var ui5 = __toESM(require("./index.ui5"));
var common = __toESM(require("./common"));
var import_formTable = require("./formTable");
var import_ft = require("./ft");
var functions = __toESM(require("./lib/functions"));
var fieldComponent = __toESM(require("./fieldComponent"));
var import_webcomponents_react_base = require("@ui5/webcomponents-react-base");
const {
  getNewValue,
  constructFooterData,
  constructFormData,
  constructNodeEditorData,
  getNewName,
  constructTableData
} = common;
const { removeUndefinedKeys, isBound, extractArgs } = functions;
const { convertNumber, escapeValue } = fieldComponent;
const refI18n = import_react.default.createRef();
refI18n.current = { getText: (key) => key };
function Comp() {
  return true;
}
Comp.displayName = "FormTable";
const FormTable = (0, import_toolkit.compose)((0, import_lib_hoc.render5)(ui5))(Comp);
