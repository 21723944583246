var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import NodeEditor from "@/components/editors/NodeEditor";
import { VBox } from "@/components";
import { useInitializeNodeEditor } from "@/hooks/nodeEditor";
import ToolbarBuilder from "./ToolbarBuilder";
import { useRefineEventForNodeEditor } from "@/hooks/refineProps";
function NodeEditorBuilder(props) {
  useInitializeNodeEditor(props);
  const refinedProps = useRefineEventForNodeEditor(
    __spreadValues({}, props),
    props
  );
  return /* @__PURE__ */ React.createElement(VBox, { style: { flex: 1, height: "100%" } }, /* @__PURE__ */ React.createElement(ToolbarBuilder, __spreadValues({}, refinedProps)), /* @__PURE__ */ React.createElement(NodeEditor, __spreadValues({ style: { width: "100%", height: "100%" } }, refinedProps)));
}
export default NodeEditorBuilder;
