var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React, { useRef, useEffect } from "react";
import { Input } from "@ui5/webcomponents-react";
import { isFalsy, defined } from "@bsgp/lib-core";
import "@ui5/webcomponents/dist/features/InputElementsFormSupport.js";
import "@ui5/webcomponents/dist/features/InputSuggestions.js";
import MultiInput from "./MultiInput";
import { convertNumber, escapeValue } from "@bsgp/form-and-table";
import { useValueHelpV2 } from "@/hooks/dialog";
import clsx from "clsx";
const FtInput = (props) => {
  const {
    style,
    properties = {},
    multiple,
    isAmount,
    isQuantity,
    unit,
    value
  } = props;
  const _a = properties, {
    change: onChange,
    focus,
    valueHelpV2,
    valueHelpOnly,
    disabled
  } = _a, rest = __objRest(_a, [
    "change",
    "focus",
    "valueHelpV2",
    "valueHelpOnly",
    "disabled"
  ]);
  const { icon, valueHelpProps, valueHelpClass } = useValueHelpV2(props);
  const isNumber = isAmount || isQuantity;
  const inputEl = useRef(null);
  const disabledStyleClass = "disabled";
  useEffect(() => {
    isFalsy(focus) || inputEl.current.focus();
  }, [focus]);
  if (multiple) {
    return /* @__PURE__ */ React.createElement(MultiInput, __spreadValues({}, props));
  }
  if (properties.mask) {
  }
  return /* @__PURE__ */ React.createElement(
    Input,
    __spreadValues(__spreadValues(__spreadProps(__spreadValues({
      icon
    }, props), {
      "data-type": "Input",
      ref: inputEl,
      className: clsx(disabled === true && disabledStyleClass, valueHelpClass),
      style: __spreadValues({
        textAlign: isNumber ? "right" : "start",
        width: "100%"
      }, style),
      title: defined(props.tooltip, value, ""),
      onInput: (event) => {
        let newValue = escapeValue(props.value);
        if (isNumber) {
          newValue = convertNumber(newValue, unit, {
            isAmount,
            isQuantity
          });
        }
        return defined(props.onInput, () => {
        })(event, {
          value: newValue
        });
      },
      value,
      onKeyDown: (event) => {
        if (event.key === "Enter") {
          props.onSubmit && props.onSubmit(event);
        }
      },
      valueStateMessage: /* @__PURE__ */ React.createElement(React.Fragment, null, properties.valueStateMessage || "")
    }), valueHelpProps), rest),
    props.children
  );
};
FtInput.displayName = "FtInput";
export default FtInput;
