import React from "react";
import ReactPagination from "react-paginate";
import { useTableContext } from "@/contexts/tableContext";
import { useSetPage } from "@/hooks/table";
function Pagination(props) {
  const { usePagination: paginationInfo } = props;
  const { filteredData, setPage, page } = useTableContext();
  useSetPage(paginationInfo);
  if (!paginationInfo) {
    return null;
  }
  const { itemsPerPage, edge } = paginationInfo;
  return itemsPerPage < filteredData.length && itemsPerPage > 0 && filteredData.length > 0 && /* @__PURE__ */ React.createElement(
    ReactPagination,
    {
      pageCount: Math.ceil(filteredData.length / itemsPerPage),
      pageRangeDisplayed: edge,
      marginPagesDisplayed: 2,
      onPageChange: (data) => {
        setPage(data.selected);
      },
      containerClassName: "pagination",
      subContainerClassName: "pages pagination",
      activeClassName: "active",
      forcePage: page
    }
  );
}
export default Pagination;
