var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import ComponentBuilder from "@/builders/common/ComponentBuilder";
const ColumnBuilder = (props) => {
  var _a;
  const { data, tableName } = props;
  const { properties } = data;
  return /* @__PURE__ */ React.createElement(
    ComponentBuilder,
    __spreadValues({
      slot: "columns",
      name: `${tableName}_columns`,
      type: "TableColumn"
    }, data),
    /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          textAlign: ((_a = properties.hAlign) == null ? void 0 : _a.toLowerCase()) || "left"
        }
      },
      data.text
    )
  );
};
export default ColumnBuilder;
