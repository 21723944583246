var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { CheckBox } from "@ui5/webcomponents-react";
import { defined } from "@bsgp/lib-core";
function FtCheckBox(props) {
  const _a = props, { properties = {}, texts = {} } = _a, rest = __objRest(_a, ["properties", "texts"]);
  const value = defined(
    properties.checked,
    properties.selected,
    props.value,
    false
  );
  const handleSelect = properties.select || properties.onSelect || properties.onChange || props.onChange;
  return /* @__PURE__ */ React.createElement(
    CheckBox,
    __spreadProps(__spreadValues(__spreadValues(__spreadValues({}, rest), properties), handleSelect && {
      onChange: (event) => {
        event.preventDefault();
        return handleSelect(event);
      }
    }), {
      checked: value,
      text: defined(properties.text, texts[value], "")
    })
  );
}
export default React.memo(FtCheckBox);
