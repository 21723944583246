import React, { createContext, useCallback, useState } from "react";
import { isTruthy } from "@bsgp/lib-core";
import { Position } from "reactflow";
const initialState = {
  nodeInfo: [],
  edgeInfo: [],
  rawInfo: null,
  initializeNodeEditor: () => {
  }
};
export const NodeEditorContext = createContext(
  initialState
);
export const NodeEditorContextProvider = ({ children }) => {
  const [nodeInfo, setNodeInfo] = useState([]);
  const [edgeInfo, setEdgeInfo] = useState([]);
  const [rawInfo, setRawInfo] = useState(null);
  const initializeNodeEditor = useCallback(
    (props) => {
      const { value } = props;
      if (!value || !isTruthy(value)) return;
      const { node, edge } = getNodeEditorInfo(value);
      setRawInfo(value);
      setNodeInfo(node);
      setEdgeInfo(edge);
    },
    [setNodeInfo, setEdgeInfo, setRawInfo]
  );
  return /* @__PURE__ */ React.createElement(
    NodeEditorContext.Provider,
    {
      value: {
        nodeInfo,
        edgeInfo,
        rawInfo,
        initializeNodeEditor
      }
    },
    children
  );
};
const getNodeEditorInfo = (json) => {
  const edge = [];
  const node = Object.entries(json).map(([key, value], index) => {
    const reservedKeys = ["begin", "end", "id"];
    if (reservedKeys.includes(key)) return null;
    const { next, position, name, id } = value;
    const [xVal, yVal] = position;
    if (next) {
      edge.push({ id: `${key}-${next}`, source: key, target: next });
    }
    return {
      type: name === "Input" ? "input" : name === "Output" ? "output" : "custom",
      id: key,
      position: { x: Math.floor(xVal), y: Math.floor(yVal) },
      data: { label: key, id, name },
      sourcePosition: Position.Right,
      targetPosition: Position.Left
    };
  }).filter(Boolean);
  return {
    node,
    edge
  };
};
