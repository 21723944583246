import React from "react";
import { defined } from "@bsgp/lib-core";
import { FormItem, HBox } from "@/components";
import { isArray } from "@bsgp/lib-core";
import { FlexBoxAlignItems, FlexBoxWrap } from "@ui5/webcomponents-react";
import FormComponentIterator from "./FormComponentIterator";
import LabelBuilder from "./LabelBuilder";
function FormItemBuilder({
  element,
  containerName,
  sizeV2
}) {
  const elName = [containerName, element.name].join("-");
  const hasMultiComponents = isArray(element.field.component);
  const inHBox = defined(element.field.inHBox, hasMultiComponents);
  let noWrap = false;
  if (sizeV2 === true) {
    noWrap = true;
    if (element.field.noWrap === false) {
      noWrap = false;
    }
  } else if (element.field.noWrap !== false) {
    noWrap = true;
  }
  const getIterator = () => {
    return /* @__PURE__ */ React.createElement(
      FormComponentIterator,
      {
        hasMultiComponents,
        element,
        elName
      }
    );
  };
  return /* @__PURE__ */ React.createElement(
    FormItem,
    {
      name: elName,
      visible: defined(element.field.visible, true),
      label: /* @__PURE__ */ React.createElement(
        LabelBuilder,
        {
          hasMultiComponents,
          element,
          elName
        }
      )
    },
    inHBox ? /* @__PURE__ */ React.createElement(
      HBox,
      {
        name: elName,
        wrap: noWrap ? FlexBoxWrap.NoWrap : FlexBoxWrap.Wrap,
        className: "tiny-margin-items",
        vAlign: FlexBoxAlignItems.Center,
        style: { gap: "0.5rem", width: "100%" }
      },
      getIterator()
    ) : getIterator()
  );
}
export default FormItemBuilder;
