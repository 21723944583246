import React from "react";
import ListBuilder from "./ListBuilder";
import AnalyticalTableBuilder from "./AnalyticalTableBuilder";
import TableComponentBuilder from "./TableComponentBuilder";
import { useFetchTable } from "@/hooks/table";
function DynamicTableBuilder(props) {
  const { tableName, data, header, footer } = props;
  useFetchTable({ tableName, data });
  if (data.isObjects) {
    return /* @__PURE__ */ React.createElement(ListBuilder, null);
  }
  if (data.isTreeTable || data.useAnalyticalTable) {
    return /* @__PURE__ */ React.createElement(AnalyticalTableBuilder, { header, footer });
  }
  return /* @__PURE__ */ React.createElement(TableComponentBuilder, { header, footer });
}
export default DynamicTableBuilder;
