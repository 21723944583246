var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { MultiComboBox, MultiComboBoxItem } from "@ui5/webcomponents-react";
import { isArray } from "@bsgp/lib-core";
function FtMultiComboBox(props) {
  const { items, properties = {}, value } = props;
  const values = isArray(value) ? value : [value];
  const options = items.list.map(
    (option) => __spreadProps(__spreadValues({}, option), {
      selected: values.find((value2) => value2 === option.key) === void 0 ? false : true
    })
  );
  return /* @__PURE__ */ React.createElement(MultiComboBox, __spreadValues({ allowCustomValues: true }, properties), options.map((opt) => {
    return /* @__PURE__ */ React.createElement(MultiComboBoxItem, __spreadValues({}, opt));
  }));
}
export default FtMultiComboBox;
