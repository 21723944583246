import React from "react";
import MenuButton from "@/components/MenuButton";
const FONT_SIZE_OPTIONS = [
  ["10px", "10px"],
  ["12px", "12px"],
  ["14px", "14px"],
  ["16px", "16px"],
  ["18px", "18px"],
  ["20px", "20px"],
  ["22px", "22px"],
  ["24px", "24px"],
  ["26px", "26px"],
  ["28px", "28px"],
  ["32px", "32px"],
  ["64px", "64px"]
];
const FontSizeController = ({
  fontSize,
  setFontSize,
  className,
  applyStyle
}) => {
  return /* @__PURE__ */ React.createElement(
    MenuButton,
    {
      className,
      value: fontSize,
      items: FONT_SIZE_OPTIONS.reduce((acc, [key, value]) => {
        acc[key] = {
          text: value,
          "data-cy": `font-size-${key}`
        };
        return acc;
      }, {}),
      onClick: (event) => {
        const selectedSize = event.detail.text;
        applyStyle(selectedSize, "font-size");
        setFontSize(selectedSize);
      },
      refineEventHookName: "none",
      "data-cy": "rte-toolbar-font-size"
    }
  );
};
export default FontSizeController;
